import { useRecordContext } from "react-admin";
import { parseDateToDateAndTimeString } from "../../utils/util";

const DateTimeField = (props) => {
  const record = useRecordContext();

  return (
    <>
      {parseDateToDateAndTimeString(new Date(record[props.source]))}
    </>
  )
};

export default DateTimeField;
