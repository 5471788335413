import {
  SCHEDULE_STATUS
} from '../components/Constants';

export const parseScheduleDataForView = (data) => {
  let response = {};
  const {
    clientId,
    requestParams,
    reportType,
    frequency,
    firstPeriodStartDate,
    scheduleReminders,
    previousPeriodStartDate,
    previousPeriodEndDate,
    nextPeriodStartDate,
    nextPeriodEndDate,
    active,
  } = data;
  const { userData, reportParams, clientReference } = JSON.parse(requestParams);
  response.id = data.reportRequestScheduleId;
  response.clientId = clientId;
  response.contactName = userData.accountName;
  response.emailAddress = userData.emailAddress;
  response.businessName = reportParams ? reportParams.businessName : '';
  response.descriptionOfMatter = reportParams ? reportParams.descriptionOfMatter : '';
  response.clientReference = clientReference;
  response.reportType = reportType;
  response.frequency = frequency;
  response.startDateOfFirstReport = firstPeriodStartDate;
  response.sendEmailReminders = scheduleReminders;
  response.dateRangeOfLastReport =
    previousPeriodStartDate && previousPeriodEndDate ? previousPeriodStartDate + ' to ' + previousPeriodEndDate : '-';
  response.dateRangeOfNextReport =
    nextPeriodStartDate && nextPeriodEndDate ? nextPeriodStartDate + ' to ' + nextPeriodEndDate : '-';
  response.status = active ? SCHEDULE_STATUS.ACTIVE : SCHEDULE_STATUS.INACTIVE;
  return response;
};

export const parseScheduleData = (data) => {
  //change as required by the component
  let parsedResponse = {};
  const { requestParams } = data;
  const { userData, reportParams, clientReference } = JSON.parse(requestParams);
  parsedResponse.id = data.reportRequestScheduleId;
  parsedResponse.contactName = userData.accountName;
  parsedResponse.businessName = reportParams ? reportParams.businessName : '';
  parsedResponse.descriptionOfMatter = reportParams ? reportParams.descriptionOfMatter : '';
  parsedResponse.clientReference = clientReference;
  parsedResponse.emailAddress = userData.emailAddress;
  parsedResponse.reportType = data.reportType;
  parsedResponse.frequency = data.frequency;
  parsedResponse.startDate = data.firstPeriodStartDate;
  parsedResponse.reminders = data.scheduleReminders;
  parsedResponse.status = data.active ? SCHEDULE_STATUS.ACTIVE : SCHEDULE_STATUS.INACTIVE;
  return parsedResponse;
};