import {useLocation} from 'react-router-dom'
import {isAdminUser} from '../DataProvider'
import {extractClientIdFromLocation, validateRiskInsightForm} from '../../mappers/ClientRiskInsight'
import {Create, SimpleForm} from 'react-admin'
import {RiskInsightsActions} from './RiskInsightsActions'
import {ActionTitles, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import {listRoot,simpleForm} from '../Styles'
import CustomToolbar from '../common/FormToolbar'
import React from 'react'
import {RiskInsightEditGrid} from './RiskInsightsEdit'

export const RiskInsightsCreate = () => {
  const location = useLocation();
  const clientId = isAdminUser() && extractClientIdFromLocation(location?.pathname);
  return(
    <Create
      actions={<RiskInsightsActions title={ActionTitles.CREATE_CLIENT_RISK_INSIGHTS} />}
      sx={listRoot}
      renderButtonAndSearch={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <CustomToolbar
            hideButton1={true}
            buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
            buttontitle2={ActionTitles.CREATE_CLIENT_RISK_INSIGHTS}
            basePath={RESOURCES_BASEPATH.RISK_INSIGHTS}
            resource={RESOURCES.RISK_INSIGHTS}
          />
        }
        validate={validateRiskInsightForm}
        mode="onSubmit"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        <RiskInsightEditGrid clientId={clientId}/>
      </SimpleForm>
    </Create>
  );
}