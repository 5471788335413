import React from "react";
import {
  CreateButton,
  EditButton,
  useListContext,
  useListFilterContext,
} from 'react-admin'
import isEmpty from "lodash/isEmpty"
import { Grid } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { isAdminUser } from "../DataProvider";
import { ActionTitles, RESOURCES } from '../Constants'
import { parseForAction, parseForAdminAction } from "../../mappers/ClientDefault";
import { pageTitle, topActionButtonBaseStyle, topActionButtonsRow } from '../Styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { StatusMessageAlerts } from "../common/AlertComponent";

export const ClientDefaultsActions = (props) => {
  const isAdmin = isAdminUser();  
  const filterContext = useListFilterContext();
  const { total } = useListContext();
  
  const isRecordNotPresent = total === 0;  
  const isClientSelected = filterContext?.filterValues?.clientId;

  return (
    <Grid container>
      { !isAdmin && <StatusMessageAlerts /> }
      <Grid item container xs={12} justifyContent={'space-between'}>
        <Grid sx={pageTitle}>{props.title}</Grid>
        {props.showCreateEditButton === true && (
          <Grid sx={topActionButtonsRow}>
            {
              isAdmin ?
                isClientSelected ?
                  isRecordNotPresent ?
                    <CreateButtonForActions
                      isAdmin={isAdmin}
                      clientId={isClientSelected}
                      basePath={props.basePath}
                    /> :
                    <EditButtonForActions
                      isAdmin={isAdmin}
                      clientId={isClientSelected}
                      basePath={props.basePath}
                    /> :
                  <></> :
                isRecordNotPresent ?
                  <CreateButtonForActions
                    isAdmin={isAdmin}
                    basePath={props.basePath}
                  /> :
                  <EditButtonForActions
                    isAdmin={isAdmin}
                    basePath={props.basePath}
                  />
            }
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12}>
        {props.filters}
      </Grid>
    </Grid>
  );
}

const CreateButtonForActions = (props) => {
  return (
    <CreateButton
      sx={{...topActionButtonBaseStyle()}}
      size="small"
      to={!isEmpty(props.clientId) ? `${props.basePath}/create/${props.clientId}` : `${props.basePath}/create`}
      label={ActionTitles.CREATE}
      icon={<AddCircleOutlineIcon />}
    />
  );
}

const EditButtonForActions = (props) => {
  return (
    <EditButton
      sx={{...topActionButtonBaseStyle()}}
      size="small"
      icon={<EditOutlinedIcon />}
      label={ActionTitles.UPDATE}
      resource={RESOURCES.CLIENT_DEFAULTS}
      record={props.isAdmin ? parseForAdminAction(props.clientId) : parseForAction()}
      {...props}
    />
  );
}