import PropTypes from 'prop-types';
import ImageEye from '@mui/icons-material/RemoveRedEye';
import { 
  Button,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { RESOURCES } from '../Constants';

const HideButton = ({
    hide = true,
    basePath = '',
    icon = defaultIcon,
    label = "Hide",    
    scrollToTop = true,
    resourceName,
    ...rest
}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const onSuccess = () => {
      notify(`${resourceName} ${hide ? "Hidden" : "Unhidden"}`, { type: 'info', autoHideDuration: 1000 });
      refresh();
    };

    const onFailure = () => {
      notify(`Failed to ${hide ? "hide" : "unhide"} ${resourceName}`, { type: 'info', autoHideDuration: 1000 });
      refresh();
    };

    const handleHide = (event) => {
      // useful to prevent click bubbling in a datagrid with rowClick
      event.stopPropagation();

      dataProvider
          .hide(RESOURCES.REPORTS, { id: record.id, hide: hide })
          .then(onSuccess)
          .catch(onFailure);
    };

    return (
        <Button
            label={label}
            onClick={(event) => handleHide(event)}
            {...rest}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <ImageEye />;

HideButton.propTypes = {
    hide: PropTypes.bool,
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
    resourceName: PropTypes.string,
};

export default HideButton;
