import * as React from "react";
import {
  List,
  ShowButton,
  EditButton,
  FunctionField,
  TextField,
  useNotify,
  useRefresh,
  BooleanField,
  Button,
  DatagridConfigurable, useListContext, CreateButton,
} from 'react-admin'
import { ActionTitles, ENABLED_FEATURES_ENUM, RESOURCES, RESOURCES_BASEPATH } from '../Constants'
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { trueCircleIcon, falseCircleIcon } from "../common/StyledIcons";
import ListActions from "../common/ListActions";
import CustomTooltip from "../../utils/CustomTooltip";
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import { AvatarField } from "../common/AvatarField";
import { isClientAdminUser, isAdminUser, isFeatureEnabled } from "../DataProvider";
import { CustomPagination } from "../common/CustomPaginationComponent";
import { cellContentCentered, dataGrid, iconAction, listRoot, topActionButtonBaseStyle, whiteSpace, width } from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import { CustomDataGridHeader, onToggleDataGridHeader } from '../common/CustomDataGridHeader'
import { useState } from 'react'
import { UsersFilter } from './UsersFilters'
import { Box, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { UserSchedulesTransferDialog } from "./UserSchedulesTransferDialog";

export const Users = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const [deleteUserId, setDeleteUserId] = React.useState();
  const isClientAdmin = isClientAdminUser();
  const isAdmin = isAdminUser();
  const [disableDelete, setDisableDelete] = React.useState(false);
  let dataGridsLS = JSON.parse(localStorage.getItem("dataGrids"))
  if (!dataGridsLS)
    dataGridsLS = { "users": true }
  const [dataGrids, setDataGrids] = useState(dataGridsLS ? dataGridsLS : {});

  const handleDialogOpen = () => {
    setOpen(true);
  }

  const copyToClipboard = (textToCopy) => {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    document.body.appendChild(textArea);
    textArea.select();

    var successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    if (!successful) {
      notify('Failed to copy', { type: 'info', autoHideDuration: 1000 });
    } else {
      notify('Text copied to clipboard', { type: 'info', autoHideDuration: 1000 });
    }
  }

  const onDeleteSuccess = () => {
    setDisableDelete(false);
    notify('User Deleted', { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const onDeleteFailure = () => {
    setDisableDelete(false);
    notify("Unable to delete a user", { type: 'warning', autoHideDuration: 1000 });
    refresh();
  };

  return (
    <>
      {
        isClientAdmin &&
        <UserSchedulesTransferDialog
          open={open}
          setOpen={setOpen}
          deleteUserId={deleteUserId}
        />
      }
      <List
        sx={listRoot}
        empty={!isAdmin ? <UsersEmpty basePath={RESOURCES.USERS} {...props} /> : false}
        actions={
          <ListActions
            title={ActionTitles.USERS}
            showCreateButton={true}
            showColumnsButton={true}
            basePath={RESOURCES_BASEPATH.USERS}
            filters={<UsersFilter />}
          />
        }
        filterDefaultValues={{ searchKey: "fullName" }}
        sort={{ field: "lastModified", order: "DESC" }}
        exporter={false}
        pagination={<CustomPagination />}
      >
        <DatagridConfigurable
          header={
            <CustomDataGridHeader
              fixedColumn={dataGrids['users']}
              onToggle={() => onToggleDataGridHeader('users', dataGrids, setDataGrids)}
            />
          }
          bulkActionButtons={false}
          sx={
            defaultsDeep(
              cloneDeep(dataGrid.base),
              cloneDeep(dataGrid.last3ColsActions),
              cloneDeep(dataGrid.largeHeight),
              dataGrids['users'] ? cloneDeep(dataGrid.firstColSticky) : {},
            )
          }
        >
          <FunctionField
            label="ID"
            source="id"
            render={(record) => (
              <span
                title="Click to copy text"
                onClick={() => copyToClipboard(record.id)}
              >
                {record.id}
              </span>
            )}
            sortable={false}
          />
          <AvatarField source="avatar" label={false} />
          <TextField source="firstName" label="FIRST NAME" />
          <TextField source="lastName" label="LAST NAME" />
          <TextField source="email" label="EMAIL" />
          <BooleanField
            source="emailReportNotifications"
            label="EMAIL REPORT NOTIFICATION"
            sx={cellContentCentered}
            TrueIcon={trueCircleIcon}
            FalseIcon={falseCircleIcon}
            sortable={false}
          />
          {(isAdmin || isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT)) &&
            <BooleanField
              source="emailStatementConversionNotifications"
              label="EMAIL STATEMENT CONVERSION NOTIFICATION"
              sx={cellContentCentered}
              TrueIcon={trueCircleIcon}
              FalseIcon={falseCircleIcon}
              sortable={false}
            />
          }
          <TextField
            source="created"
            label="CREATED"
            sx={whiteSpace}
          />
          <TextField
            source="lastModified"
            label="LAST MODIFIED"
            sx={whiteSpace}
          />
          <BooleanField
            source="multiFAConfigured"
            label="2FA"
            sx={cellContentCentered}
            TrueIcon={trueCircleIcon}
            FalseIcon={falseCircleIcon}
            sortable={false}
          />
          <FunctionField
            sx={cellContentCentered}
            source="enforceForMultiFAConfiguration"
            label="ENFORCE 2FA"
            sortable={false}
            render={(record) => (
              record["multiFAConfigured"] ?
                "--" :
                <BooleanField
                  source="enforceForMultiFAConfiguration"
                  label="ENFORCE 2FA"
                  sx={cellContentCentered}
                  TrueIcon={trueCircleIcon}
                  FalseIcon={falseCircleIcon}
                  sortable={false}
                />
            )}
          />
          <FunctionField
            sx={cellContentCentered}
            source="View"
            label={false}
            sortable={false}
            render={(record) => (
              <CustomTooltip
                title="View"
                placement="top"
              >
                <ShowButton
                  icon={<RemoveRedEyeOutlinedIcon />}
                  sx={iconAction}
                  size="medium"
                  label={false}
                  basePath={RESOURCES_BASEPATH.USERS}
                  record={record}
                />
              </CustomTooltip>
            )}
          />
          <FunctionField
            sx={cellContentCentered}
            source="Edit"
            label={false}
            sortable={false}
            render={(record) => (
              <CustomTooltip
                title="Edit"
                placement="top"
              >
                <EditButton
                  icon={<EditOutlinedIcon />}
                  sx={iconAction}
                  size="medium"
                  label={false}
                  basePath={RESOURCES_BASEPATH.USERS}
                  record={record}
                />
              </CustomTooltip>
            )}
          />

          {isAdmin &&
            <FunctionField
              sx={cellContentCentered}
              source="Delete"
              label={false}
              sortable={false}
              render={(record) => (
                <CustomTooltip
                  title="Delete"
                  placement="top"
                >
                  <CustomDeleteWithConfirmButton
                    icon={<DeleteOutlinedIcon />}
                    sx={iconAction}
                    size="medium"
                    label={false}
                    record={record}
                    recordName="User"
                    onSuccess={onDeleteSuccess}
                    onFailure={onDeleteFailure}
                    disableDelete={disableDelete}
                    setDisableDelete={setDisableDelete}
                    mutationMode="pessimistic"
                  />
                </CustomTooltip>
              )}
            />}

          {isClientAdmin &&
            <FunctionField
              sx={cellContentCentered}
              source="Delete"
              label={false}
              sortable={false}
              render={(record) => (
                <CustomTooltip title="Delete" placement="top">
                  <Button
                    sx={iconAction}
                    size="medium"
                    onClick={() => {
                      setDeleteUserId(record.id);
                      handleDialogOpen();
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                </CustomTooltip>
              )}
            />
          }

        </DatagridConfigurable>
      </List>
    </>
  );
}

const UsersEmpty = () => {
  const { basePath } = useListContext();

  return (
    <Box textAlign="center" sx={{ ...width.fluid.w100, paddingTop: "50px" }}>
      <Typography variant="h5" paragraph>
        No Users present for Client
      </Typography>
      <CreateButton
        sx={{ ...topActionButtonBaseStyle() }}
        basePath={basePath}
        icon={<AddCircleOutlineIcon />}
        size="small"
        label={ActionTitles.CREATE_USER}
      />
    </Box>
  );
};

/* -- dont remove this commented code, might be required in future for optimizing the user section
export const UserList = (props) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getClientNameList('admin/client/names')
        .then(({ data }) => {
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            setError(error);
            setLoading(false);
        })
}, []);

  if (loading) return <Loading />;
  if (error) return <Error />;
  console.log(data);
  var partnerChoices = [];
  partnerChoices = data;
  return (
    <MyList
      actions={
        <ListActions
          title={ActionTitles.USERS}
          buttontitle={ActionTitles.CREATE_USER}
          renderButtonAndSearch={true}
          basePath="/users"
          filters={<UsersFilter />}
        />
      }
      choiceList={partnerChoices}
      {...props}
    >
      <Datagrid>...</Datagrid>
    </MyList>
  );
};

const MyList = ({ children, ...props }) => {
  const [value, setValue] = React.useState(props.choiceList[0].id)
  return (
    <ListBase {...props}>
      <h1>{props.title}</h1>
      <ListToolbar filters={props.filters} actions={props.actions} />
      <Card>
        <SimpleForm toolbar={null}>
          <AutocompleteInput
            source="value"
            name="client" //check this while integration
            variant="outlined"
            size="small"
            label={ActionTitles.CORPORATE_PARTNER}
            choices={props.choiceList}
            onChange={(event) => setValue(event)}
          />
        </SimpleForm>
        <CustomGridData partnerSelected={value} />
      </Card>
    </ListBase>
  );
};

const CustomGridData = (props) => {
  console.log(props.partnerSelected);
  const [page, setPage] = useState(1);
    const perPage = 10;
    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'admin/client',
        payload: {
            pagination: { page, perPage },
            sort: { field: 'id', order: 'ASC' },
            filter: {},
        }
    });

    if (loading) {
        return <Loading />
    }
    if (error) {
        return <Error/>
    }
    
    return (
      <ListContextProvider value={{
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        total,
        page,
        perPage,
        setPage,
        currentSort: { field: 'id', order: 'ASC' },
        basePath: "/posts",
        resource: 'posts',
        selectedIds: []
      }}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
        <Pagination />
      </ListContextProvider>
    );
}
*/