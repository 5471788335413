import { TEMPLATES_ASSIGNMENT }  from "../components/Constants"

export function parseTemplateChoices(data) {                
  data.forEach(element => {              
    element["id"] = element["templateId"];        
    element["name"] = element["templateName"];
          
    delete element["templateId"];                  
    delete element["templateName"];                        
    delete element["templateData"];                              
  });
  return data;
}

export function parseTemplateListToObject(data) {
  return data.map((element) =>{
    const obj = {};
    obj.templateId = element;
    return obj;
  });
}

export function parseTemplateObjectToList(data) {
  return data.map( element => element.templateId);                
}

export function parseTemplatesAssignmentForCreate(json) {
  const data = {}
  data[TEMPLATES_ASSIGNMENT.MAP] = json;
  data[TEMPLATES_ASSIGNMENT.UNMAP] = []; 
  return JSON.stringify(data);
}

export function parseTemplatesForAssignment(preSelectedValues, postSelectedValues) {  
  const templatesToUnMap = parseTemplateListToObject(preSelectedValues.filter(templateId => !postSelectedValues.includes(templateId)));   //The logic to prepare two separate lists based on which templates are to be mapped to the client and which templates are to be unmapped.
  const templatesToMap = parseTemplateListToObject(postSelectedValues.filter(templateId => !preSelectedValues.includes(templateId)));
  
  const data = {};
  data[TEMPLATES_ASSIGNMENT.MAP] = templatesToMap;
  data[TEMPLATES_ASSIGNMENT.UNMAP] = templatesToUnMap;
  
  return JSON.stringify(data);
}

export function parseMappedTemplates(data) {
  var result = [];
  data.forEach((item) => {
    result.push({
      templateId: item.templateId,
      templateName: item.templateName,
    });
  });
  return result;
};