import {STATUS_MESSAGE_COMPONENTS} from "../components/Constants";

export const parseDataStatusMessageShow = (data) => {
  const parsedRequest = {
    id: data.id,
    name: data.name,
    components: data.components.map((component => STATUS_MESSAGE_COMPONENTS[component])).join(', '),
    message: data.message,
    effectiveFrom: data.effectiveFrom,
    effectiveTo: data.effectiveTo,
    type: data.type,
    deleted: data.deleted,
  };

  return parsedRequest;
};

export const parseDataStatusMessageCreate = (data) => {
  const components = []
  if (data.statusMessageCorporatePortal) components.push('CORPORATE_PORTAL')
  if (data.statusMessageUserJourney) components.push('USER_JOURNEY')
  const parsedRequest = {
    name: data.name,
    components,
    message: data.message,
    effectiveFrom: new Date(data.effectiveFrom).toISOString(),
    effectiveTo: new Date(data.effectiveTo).toISOString(),
    type: data.type,
    deleted: data.deleted,
  };

  return JSON.stringify(parsedRequest);
};

export const parseDataStatusMessageUpdate = (data) => {
  const components = []
  if (data.statusMessageCorporatePortal) components.push('CORPORATE_PORTAL')
  if (data.statusMessageUserJourney) components.push('USER_JOURNEY')
  const parsedRequest = {
    id: data.id,
    name: data.name,
    components,
    message: data.message,
    effectiveFrom: new Date(data.effectiveFrom).toISOString(),
    effectiveTo: new Date(data.effectiveTo).toISOString(),
    type: data.type,
    deleted: data.deleted,
  };
  return JSON.stringify(parsedRequest);
};

export const parseLabelColor = (data) => {
  return (data === 'danger') ? 'error' : data
}