import * as React from "react";
import {
  SimpleForm,
  Create,
} from "react-admin";
import ListActions from "../common/ListActions";
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import CustomToolbar from "../common/FormToolbar";
import { contentCenter } from '../Styles'
import {CorporatePartnerCreateEditGrid} from './CorporatePartnerEdit'

export const CorporatePartnerCreate = () => {
  return (
    <Create
      actions={
        <ListActions
          title={ActionTitles.CREATE_CORPORATE_PARTNER}
          renderButtonAndSearch={false}
        />
      }
      sx={contentCenter}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
            buttontitle2={ActionTitles.CREATE}
            basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
            resource={RESOURCES.CORPORATE_PARTNERS}
            record
          />
        }
        mode="onBlur"
        reValidateMode="onBlur"
      >
        <CorporatePartnerCreateEditGrid />
      </SimpleForm>
    </Create>
  );
};
