import { Grid } from "@mui/material";
import {imageField} from '../Styles'

export const CustomImageField = ({ record, source }) => {
  return (
    <Grid container>
      <Grid xs={12}>
        {record["icon"] !== "" && (
          <img
            src={`data:image/png;base64,${record["icon"]}`}
            alt=""
            style={{...imageField}}
          />
        )}
      </Grid>
    </Grid>
  );
};
