import React, {useState} from "react";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { httpClient } from '../DataProvider';
import {float,padding0,padding2,padding3,textColor,topActionButtonBaseStyle, cursor} from '../Styles'
import {Grid} from '@mui/material'
import {ActionTitles} from '../Constants'
import {Button} from 'react-admin'
import HourglassDisabledOutlinedIcon from '@mui/icons-material/HourglassDisabledOutlined';
import Tooltip from '@mui/material/Tooltip'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function DisableReminder(props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleConfirm = () => {
    const { clientRequestId } = props;

    const apiEndpoint = `${apiUrl}/api/report-request/${clientRequestId}/cancel-scheduled-reminders`
    httpClient(apiEndpoint, {
      method: 'POST'
    })
    .then(() => {
      setOpen(false);
      window.location.reload();
    });
  };
  const handleDialogClose = () => setOpen(false);
  return (
    <React.Fragment>
      {props.scheduledReminders ?
        <Tooltip title="Disable reminders" placement="top">
          <CheckCircleIcon
            onClick={handleClick}
            sx={{ ...textColor.green, ...float.left, ...cursor.pointer }}
          />
        </Tooltip> :
        <CancelIcon sx={{ ...textColor.red, ...float.left }} />
      }
      <Dialog open={open} onClose={handleDialogClose} fullWidth={true}>
        <DialogContent>
          <p>Disable reminders for <strong>{props.emailAddress}</strong>?</p>
          <p>To restart reminders, resend automated email.</p>
        </DialogContent>
        <DialogActions sx={{...padding0.top, ...padding2.bottom, ...padding3.x}}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handleDialogClose}
                sx={{...topActionButtonBaseStyle("gray","#fff","#eaeaea","#b2afaf","#9B9999")}}
                size="small"
                variant="outlined"
                label={ActionTitles.CANCEL}
              >
                <CancelIcon/>
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirm}
                sx={{...topActionButtonBaseStyle()}}
                size="small"
                label="Disable"
              >
                <HourglassDisabledOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DisableReminder;