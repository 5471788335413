import * as React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Box from '@mui/material/Box';
import {Logout} from 'ra-ui-materialui'
import {appbar} from '../../Styles'
import Link from '@mui/material/Link'
import {resetStorageAndLogout} from '../../../utils/LoginUtils'

export const TopBar = () => (
  <AppBar
    color="default"
    userMenu={
      <UserMenu>
        <Logout
          onClick={resetStorageAndLogout}
        />
      </UserMenu>
    }
  >
    <Link href="/" sx={appbar.logo.link}>
      <img src="Logo.png" alt="Armalytix Logo" style={{...appbar.logo.img}} />
    </Link>
    <Box flex="1"/>
  </AppBar>
);