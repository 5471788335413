import React from "react";
import { isAdminUser } from "../DataProvider";
import { ClientDefaultsActions } from "./ClientDefaultsActions";
import {
  Create,
  SimpleForm,
} from 'react-admin'
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import CustomToolbar from "../common/FormToolbar";
import { useLocation } from 'react-router-dom';
import { extractClientIdFromLocation } from "../../mappers/ClientRiskInsight";
import {contentCenter,simpleForm} from '../Styles'
import { ClientDefaultsCreateEditGrid } from './ClientDefaultsCreateEditGrid'

export const ClientDefaultsCreate = (props) => {
  const location = useLocation();
  const clientId = isAdminUser() && extractClientIdFromLocation(location?.pathname);

  return (
    <Create
      sx={contentCenter}
      actions={<ClientDefaultsActions title={ActionTitles.CREATE_CLIENT_DEFAULTS} />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <CustomToolbar
            hideButton1={true}
            buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
            buttontitle2={ActionTitles.CREATE_CLIENT_DEFAULTS}
            basePath={RESOURCES_BASEPATH.CLIENT_DEFAULTS}
            resource={RESOURCES.CLIENT_DEFAULTS}
          />
        }
        mode="onBlur"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        <ClientDefaultsCreateEditGrid clientId={clientId} />
      </SimpleForm>
    </Create>
  );
}