import {deepCopy} from "./util";

export const mapReductionTransactions = (rows) => {
  return rows.map(item => {
    return {
      key: item.key,
      transactionId: item.transactionId,
      txnCode: item.txnCode,
      description: item.description,
      bookingDateTime: item.bookingDateTime,
      amount: item.amount,
      balanceAmount: item.balanceAmount,
    }
  })
}

export const isDataUnchanged = (rows, originalData, sortASCDirection) => {
  const copyRows = deepCopy(rows)
  const reversedRows = reverseArray(copyRows)
  const updatedData = mapReductionTransactions(sortASCDirection ? reversedRows : rows)
  return JSON.stringify(originalData) === JSON.stringify(updatedData)
}

export const initKeyAndCalculateValidateAmounts = (data, sortASCDirection) => {
  let dataCopy = deepCopy(data)

  let calculatedBalance = dataCopy[0].balanceAmount
  let newData = dataCopy.map((item, index) => {
    item.key = sortASCDirection ? dataCopy.length - index : index + 1

    if ((index !== 0) && ((dataCopy[index - 1].balanceAmount !== null) && (dataCopy[index - 1].balanceAmount !== undefined))) {
      if (sortASCDirection) {
        calculatedBalance = parseFloat(dataCopy[index - 1].balanceAmount) + parseFloat(dataCopy[index].amount)
      } else {
        if ((dataCopy[index - 1].amount !== null) && (dataCopy[index - 1].amount !== undefined))
          calculatedBalance = parseFloat(dataCopy[index - 1].balanceAmount) - parseFloat(dataCopy[index - 1].amount)
      }
    }

    item.amount =
      ((item.amount !== null && item.amount !== undefined))
        ? parseFloat(item.amount).toFixed(2)
        : null
    item.balanceAmount =
      ((item.balanceAmount !== null && item.balanceAmount !== undefined))
        ? parseFloat(item.balanceAmount).toFixed(2)
        : null

    item.calculatedBalance =
      ((calculatedBalance !== null) && (calculatedBalance !== undefined))
        ? parseFloat(calculatedBalance).toFixed(2)
        : null

    item.balanceAmountVsCalculatedBalanceError = (item.calculatedBalance !== item.balanceAmount)

    return item
  })

  return newData
}

export function formatNumberToString (floatNumber) {
  const LOCALE = 'en-GB'
  return new Intl.NumberFormat(LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(floatNumber)
}

export const reverseArray = (array) => {
  const copyArray = deepCopy(array)
  let left = null;
  let right = null;
  let length = copyArray.length;
  for (left = 0; left < length / 2; left += 1)
  {
    right = length - 1 - left;
    let temporary = copyArray[left];
    copyArray[left] = copyArray[right];
    copyArray[right] = temporary;
  }
  return copyArray;
}