import * as React from "react";
import {Layout} from 'react-admin'
import CustomMenu from "./Menu";
import {TopBar} from './TopBar'
import {CustomSidebar} from './Sidebar'
import {layout} from '../../Styles'

const CustomLayout = (props) => (
  <Layout
    {...props}
    appBar={TopBar}
    menu={CustomMenu}
    sidebar={CustomSidebar}
    sx={layout}
  />
);

export default CustomLayout;
