import React, { useEffect, useState } from "react";
import { isEmpty, Loading, useDataProvider, useNotify } from "react-admin";
import { getDateComponent, parseDateForExport } from "../../utils/util";
import { RESOURCES } from "../Constants";
import { useFormContext } from 'react-hook-form';
import { filterFieldBasedOnName } from "../../utils/CreateReportUtils";
import { ReportTypeForSecondReportGrid } from "./ReportTypeForSecondReportGrid";
import { TimeRangeForSecondReportGrid } from "./TimeRangeForSecondReportGrid";

export const CreateElementsSecondaryReportGrid = (props) => {
  const [loading, setLoading] = useState(true);
  const form = useFormContext();
  const dataprovider = useDataProvider();
  const notify = useNotify();

  const firstLevelFields = props.fields.filter(field => isEmpty(field.dependentOn)).sort((a, b) => a.position - b.position);

  const reportLengthField = filterFieldBasedOnName("reportLength", props.fields);
  const startDateField = filterFieldBasedOnName("startDate", props.fields);
  const endDateField = filterFieldBasedOnName("endDate", props.fields);
  const midDateField = filterFieldBasedOnName("midDate", props.fields);
  const dateRangeField = filterFieldBasedOnName("dateRange", props.fields);

  useEffect(() => {
    dataprovider
      .getOne(RESOURCES.REPORTS, { id: props.originalClientRequestId })
      .then(({ data }) => {
        form.setValue("originalClientRequestId", data.id);
        form.setValue("contactName", data.contactName);
        form.setValue("emailAddress", data.emailAddress);
        form.setValue("reportStartDate", data.userStartDateParsed ?? data.startDateParsed);
        form.setValue("reportEndDate", data.endDateParsed ?? getDateComponent(parseDateForExport(data.lastModified)));
        form.setValue("clientReference", data.clientReference);
        form.setValue("descriptionOfMatter", data.descriptionOfMatter);
        form.setValue("businessName", data.businessName);
        setLoading(false);
      })
      .catch(() => {
        notify('Something went wrong while fetching request', "error", { autoHideDuration: 1000 });
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.originalClientRequestId, props.selectedTemplateId]);

  let components = [];

  if(loading) return <Loading />;

  firstLevelFields.forEach((field) => {
    if (field.type === 'Dropdown') {
      const item = (
        <ReportTypeForSecondReportGrid
          field={field}
          selectedTemplateId={props.selectedTemplateId}
        />
      );
      components.push(item);
    }
    else if (field.type === 'RadioButton') {
      const item = (
        <TimeRangeForSecondReportGrid
          field={field}
          selectedTemplateId={props.selectedTemplateId}
          reportLengthField={reportLengthField}
          startDateField={startDateField}
          endDateField={endDateField}
          midDateField={midDateField}
          dateRangeField={dateRangeField}
        />
      );
      components.push(item);
    }
  });

  return components;
};
