import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  NumberInput,SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useDataProvider,
  useNotify,
  Button as RaButton
} from 'react-admin'
import {RiskInsightsActions} from './RiskInsightsActions'
import {ActionTitles, RESOURCES, RESOURCES_BASEPATH, RISK_INSIGHTS_COMPARISON_OPTIONS} from '../Constants'
import {
  accordionActionButton,
  contentCenter,
  display,
  iconAction,
  removeNumberArrowsStyle,simpleForm,
  simpleFormIterator,
  simpleFormIteratorAdd,
  width
} from '../Styles'
import CustomToolbar from '../common/FormToolbar'
import React from 'react'
import {isAdminUser} from '../DataProvider'
import {Button, Grid} from '@mui/material'
import {TooltipDescription} from '../common/TooltipDescription'
import CustomTooltip from '../../utils/CustomTooltip'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Divider from '@mui/material/Divider'
import { validateRiskInsightForm } from '../../mappers/ClientRiskInsight'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export const RiskInsightEdit = () => {
  return (
    <Edit
      actions={<RiskInsightsActions title={ActionTitles.CLIENT_RISK_INSIGHTS_UPDATE} />}
      mutationMode="pessimistic"
      sx={contentCenter}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
            buttontitle2={ActionTitles.UPDATE}
            isEdit={true}
            basePath={RESOURCES_BASEPATH.RISK_INSIGHTS}
            resource={RESOURCES.RISK_INSIGHTS}
          />
        }
        validate={validateRiskInsightForm}
        mode="onSubmit"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        <RiskInsightEditGrid />
      </SimpleForm>
    </Edit>
  );
}

export const RiskInsightEditGrid = ( props ) => {

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [riskInsightParameters, setRiskInsightParameters] = React.useState([]);
  const isAdmin = isAdminUser();


  React.useEffect(() => {
    fetchRiskinsightsParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRiskinsightsParameters = () => {
    dataProvider
      .getAllRiskInsightParameters()
      .then(({data}) => {
        data.sort( (a, b) => a["name"].localeCompare(b["name"]))
        setRiskInsightParameters(data);
      }).catch((error) => {
      notify('Something went wrong while fetching risk insights parameters.', { type: 'warning', autoHideDuration: 1000 });
    })
  }

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        {isAdmin &&
          <Grid sx={display.none}>
            <TextInput
              source="id"
              label={false}
              defaultValue={props.clientId}
            />
          </Grid>
        }
        <ArrayInput source="riskInsightData" label={false}>
          <SimpleFormIterator
            disableClear
            sx={{...simpleFormIterator, ...simpleFormIteratorAdd}}
            removeButton={              
              <CustomTooltip title="Remove" placement="top">
                <Button
                  sx={iconAction}
                  size="medium"
                >
                  <DeleteOutlinedIcon/>
                </Button>
              </CustomTooltip>              
            }
            addButton={
              <RaButton
                variant="contained"
                sx={{...accordionActionButton, marginLeft: "0 !important" }}
                label="Add"
                size="small"
              >
                <AddCircleOutlineIcon />
              </RaButton>
            }
          >
            <FormDataConsumer>
              {({ getSource }) => {
                return (
                  <Grid container>
                    <Grid item xs={12} md={5}>
                      <SelectInput
                        source={getSource("parameter")}
                        label="Parameter"
                        choices={riskInsightParameters}
                        helperText={false}
                        variant="outlined"
                        size="small"
                        sx={{...width.fluid.w100, paddingRight : "10px" }}
                        optionText={<TooltipDescription />}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <SelectInput
                        source={getSource("comparison")}
                        label="Comparison"
                        choices={RISK_INSIGHTS_COMPARISON_OPTIONS}
                        helperText={false}
                        variant="outlined"
                        size="small"
                        sx={{...width.fluid.w100, paddingRight : "10px" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <NumberInput
                        source={getSource("value")}
                        label ="Value"
                        helperText={false}
                        variant="outlined"
                        size="small"
                        onWheel={(e) => {
                          e.target.blur();
                        }}
                        sx={{...removeNumberArrowsStyle, ...width.fluid.w100, paddingRight : "10px" }}
                      />
                    </Grid>
                  </Grid>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <Divider light />
      </Grid>
    </Grid>
  );
}