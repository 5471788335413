import * as React from 'react';
import Button from '@mui/material/Button';
import History from '@mui/icons-material/History';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import { useAuthenticated, useTranslate } from 'ra-core';
import {Grid} from '@mui/material'
import {margin2,textAlign,textColor,topActionButtonBaseStyle} from '../Styles'

export const Error404 = () => {
  const translate = useTranslate();
  useAuthenticated();

  return (
    <Grid container flexGrow={0.85}>
      <Grid
        item
        xs={12}
        container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={textColor.secondary}
      >
        <SearchOffIcon sx={{width: "9em", height: "9em", marginLeft: "1em" }} />
        <h1>{translate('ra.page.not_found')}</h1>
        <Grid sx={{ ...margin2.bottom, ...textAlign.center }}>{translate('ra.message.not_found')}.</Grid>
        <Button
          variant="contained"
          startIcon={<History />}
          onClick={goBack}
          size="small"
          sx={topActionButtonBaseStyle()}
        >
          Go Back
        </Button>
      </Grid>
    </Grid>
  );
};

function goBack() {
  window.history.go(-1);
}
