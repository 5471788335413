import {Filter,SelectInput,TextInput} from 'react-admin'
import {SEARCH_BY_FIELDS_CLIENTS} from '../Constants'
import * as React from 'react'

export const CorporatePartnersFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      source="searchValue"
      size="small"
      variant="outlined"
      alwaysOn
      resettable
    />
    <SelectInput
      label="Search By"
      source="searchKey"
      choices={SEARCH_BY_FIELDS_CLIENTS}
      size="small"
      variant="outlined"
      isRequired
      alwaysOn
    />
  </Filter>
);