import { Tooltip } from "@mui/material";
import React from "react";
import { REPORT_STATUSES,REPORT_STATUS_INFO } from "../Constants";
import {headerTooltip} from '../Styles'

const ReportStatusLabel = (props) => {
  return(
  <Tooltip
    disableInteractive
    placement="right" 
    title={
      <table style={headerTooltip}>
        <tbody>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.NEW}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.NEW]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.VIEWED}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.VIEWED]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.ACCEPTED}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.ACCEPTED]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.PROCESSING}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.PROCESSING]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.DRAFT}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.DRAFT]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.AVAILABLE}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.AVAILABLE]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.REJECTED}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.REJECTED]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.FAILED}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.FAILED]}</td>
          </tr>
          <tr>
            <td style={headerTooltip}>{REPORT_STATUSES.DECLINED}</td>
            <td style={headerTooltip}>{REPORT_STATUS_INFO[REPORT_STATUSES.DECLINED]}</td>
          </tr>
        </tbody>
      </table>  
    }>
    <div>{props.title}</div>
  </Tooltip>);
}

export default ReportStatusLabel;