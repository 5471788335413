import React, { useState } from "react";
import SendEmailDialog from './SendEmailDialog';
import {baseButtonStyle} from '../Styles'
import {Button} from '@mui/material'

function SendAutomatedEmailDialog(props) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  }
  const handleDialogClose = () => setOpen(false);
  const handleSuccess = () => {
    setOpen(false);
    window.location.reload();
  }
  return (
    <React.Fragment>
      <Button
        size="medium"
        onClick={handleClick}
        disabled={props.disabled}
        sx={{...baseButtonStyle()}}
      >
        Send Automated Email
      </Button>
      {open && (
        <SendEmailDialog
          handleDialogClose={handleDialogClose}
          clientRequestId={props.clientRequestId}
          handleSuccess={handleSuccess}
        />
      )}
    </React.Fragment>
  );
};
export default SendAutomatedEmailDialog;
