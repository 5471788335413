import {Edit, SimpleForm} from 'react-admin'
import ListActions from '../common/ListActions'
import {ActionTitles, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import CustomToolbar from '../common/FormToolbar'
import * as React from 'react'
import {contentCenter,simpleForm} from '../Styles'
import {SettingsEditGrid} from '../settings/SettingsEdit'

export const UserEdit = () => {
  return (
    <Edit
      actions={
        <ListActions
          title={ActionTitles.USER_UPDATE}
          showViewButton={true}
          showDeleteButton={true}
          basePath={RESOURCES_BASEPATH.USERS}
          resource={RESOURCES.USERS}
          recordName={ActionTitles.USER}
        />
      }
      mutationMode="pessimistic"
      sx={contentCenter}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
            buttontitle2={ActionTitles.UPDATE}
            isEdit={true}
            basePath={RESOURCES_BASEPATH.USERS}
            resource={RESOURCES.USERS}
          />
        }
        mode="onBlur"
        reValidateMode="onBlur"
        noValidate
        sx={simpleForm}
      >
        <SettingsEditGrid showEmailEditField={true} isUserResource={true} />
      </SimpleForm>
    </Edit>
  );
}