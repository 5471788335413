import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import { positiveAmount } from '../../utils/util';
import { NumberInput } from 'react-admin';
import React from 'react';
import { canFieldBeHidden, showSurveyATP } from '../../utils/CreateReportUtils';
import { useWatch, useFormContext } from 'react-hook-form';
import {
  requiredValidation,
} from "../../utils/util";
import {padding2,textColor,width} from '../Styles'

const SurveyATPDynamicField = (props) => {

  const values = useWatch();
  const surveyAmountField = props.surveyAmountField;
  const form = useFormContext();
  
  React.useEffect(() => {
    if (
      showSurveyATP(values)
    ) {
      // cleaning form on case of template and report change
      form.setValue("surveyAmountToProve", surveyAmountField?.defaultValue);
    } else {
      form.resetField("surveyAmountToProve");
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values.reportType, props.selectedTemplateId, surveyAmountField]);

  return(
    showSurveyATP(values) && (
      <React.Fragment>
        <Grid sx={{
          ...(surveyAmountField && canFieldBeHidden(
            surveyAmountField?.isHidden,
            surveyAmountField?.required,
            surveyAmountField?.defaultValue
          ) ?{ display: 'none' } : {})
        }}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                {surveyAmountField ? surveyAmountField.displayName : 'Survey Amount to Prove'}
                {surveyAmountField?.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <NumberInput
                  defaultValue={surveyAmountField?.defaultValue}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  source="surveyAmountToProve"
                  label={
                    surveyAmountField?.placeholder
                      ? surveyAmountField.placeholder
                      : ( surveyAmountField?.required ? 'Survey Amount to Prove' : 'Survey Amount to Prove (Optional)' )
                  }
                  variant="outlined"
                  size="small"
                  sx={{ ...width.fluid.w75, marginBottom: "-15px" }}
                  onKeyPress={(e) => {
                    //number input dont allow charcaters generally, 'e' was allowed to store big intergers, hence disabling that manually
                    if (e.key === 'e') {
                      e.preventDefault();
                    }
                  }}
                  min={0}
                  validate={surveyAmountField?.required ? [requiredValidation, positiveAmount] : positiveAmount}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
        </Grid>
      </React.Fragment>
    )
  );
};

export default SurveyATPDynamicField;