import { fetchUtils, HttpError } from "react-admin";
import { stringify } from "query-string";
import {
  RESOURCES,
  RESOURCES_META_DATA,
  AdminRolesArray,
  FREQUENCY,
  REPORT_TYPES,
  UserRoles,
  CREATE_USER,
  INSIGHTS_PERMISSION_TYPE,
  ENABLED_FEATURES_ENUM,
  LOCAL_STORAGE_KEYS,
  SUB_RESOURCES,
} from "../components/Constants";
import {
  getParsedFieldForSort,
  prepareFilterString,
  DATA_PARSERS,
  parseClientNamesData,
  parseMappedUsersData,
} from '../mappers/MapperUtil';
import {
  convertFileToBase64,
  parseDataForCreate,
  parseCorporatePartnerDataForView,
  parseForUpdate,
  addSplashScreenContent
} from '../mappers/CorporatePartner';
import {
  parseScheduleDataForView
} from '../mappers/Schedule';
import {
  parseSettingsForView,
  parseSettingsForUpdate,
  parseSettingsForPasswordUpdate,
  parseSettingsForList
} from '../mappers/Settings';
import {
  parseUserData,
  parseDataForUserCreate,
  parseUserPasswordForUpdate,
  parseMappedUsersChoices
} from '../mappers/Users';
import {
  parseCreateReportRequestBodyData,
  parseOneReportRequestData,
  parsePatchReportRequesBodyData,
  parsePatchSurveyParams
} from '../mappers/Report';
import {
  parseForClientContentDto,
  parseDefaultContentData,
  parseContentDataForView,
  parseDataForContentCreate,
  parseForContentUpdate
} from '../mappers/DefaultContent'
import { checkTokenExpiryAndRefresh } from "../utils/LoginUtils";
import {
  parseTemplatesAssignmentForCreate,
  parseMappedTemplates,
  parseTemplateChoices,
  parseTemplatesForAssignment
} from '../mappers/Template';
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull"
import isEmpty from "lodash/isEmpty"
import { 
  parseMappedSurveysbyReportType, 
  parseSurveyChoices, 
  parseSurveysForAssignment,
  parseSurveysForCreateAssignment
} from "../mappers/Survey";
import { 
  parseComparisonChoices,
  parseDatatoShowCRI,
  parseDatatoSaveCRI, 
  parseRiskInsightDataForList,
  parseDatatoSaveCRIForAdmin,
  parseDatatoShowCRIForAdmin
} from "../mappers/ClientRiskInsight";
import { avoidApiCallForEmptyValues, createErrorObject, getYesterday } from "../utils/util";
import { 
  parseClientDefaultsDataForList, 
  parseDatatoSaveClientDefaults, 
  parseDatatoSaveClientDefaultsForAdmin, 
  parseDatatoShowClientDefaults, 
  } from "../mappers/ClientDefault";
import {
  parseDataStatusMessageCreate,
  parseDataStatusMessageShow,
  parseDataStatusMessageUpdate
} from "../mappers/StatusMessages";
import {
  parseBankData,
  parseCsvUploadResults,
  parseDatatoShowAccounts,
  parseMatterAccountDataForList,
  parseMatterDataForCreate,
  parseMatterDataForShow,
  parseMatterDataForUpdate,
  parsePdfUploadResults,
  prepareQueryParams,
  parseBankStatementJobImportStatus
} from "../mappers/Matter";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const httpClient = async (url, options = {}) => {
  await checkTokenExpiryAndRefresh();
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const authorization = JSON.parse(
    JSON.stringify(localStorage.getItem("auth"))
  );
  options.headers.set("Authorization", authorization);
  return fetchUtils.fetchJson(url, options);
};

export const fileHttpClient = (url) => {
  const authorization = JSON.parse(
    JSON.stringify(localStorage.getItem("auth"))
  );
  const options = {
    headers: {
      Authorization: authorization,
    },
  }
  return fetch(url, options).then(res => res.arrayBuffer());
};

export const isAdminUser = () => {
  const loggedInUserRoles = localStorage.getItem("userRoles").split(",");
  if (loggedInUserRoles.some((item) => AdminRolesArray.includes(item)))
    return true;
  else return false;
};

export const isClientAdminUser = () => {
  const loggedInUserRoles = localStorage.getItem("userRoles").split(",");
  return loggedInUserRoles.includes(UserRoles.USER_CLIENT_ADMIN);
}

export const isTransactionEditor = () => {
  const loggedInUserRoles = localStorage.getItem("userRoles").split(",");
  return loggedInUserRoles.includes(UserRoles.USER_TXN_EDITOR);
}

export const isReportSchedulingEnabled = () => {
  return localStorage.getItem("reportScheduling") != null
    ? localStorage.getItem("reportScheduling") === "true"
      ? true
      : false
    : false;
};

export const isRiskInsightsEnabled = () => {
  if(isNull(localStorage.getItem("insightsPermission")) || isEmpty(localStorage.getItem("insightsPermission"))){
    return false
  }
  const insightsPermissions = localStorage.getItem("insightsPermission").split(",");
  return insightsPermissions.includes(INSIGHTS_PERMISSION_TYPE.RISK_INSIGHTS)
};

export const isExtendedDataRangeEnabled = () => {
  if (isNull(localStorage.getItem("enabledFeatures")) || isEmpty(localStorage.getItem("enabledFeatures"))) {
    return false
  }
  const enabledFeatures = localStorage.getItem("enabledFeatures").split(",");
  return enabledFeatures.includes(ENABLED_FEATURES_ENUM.EXTENDED_DATA_RANGE);
};

export const isFeatureEnabled = (feature) => { //The ENABLED_FEATURES_ENUM enum should be passed when this method is used
  if (isNull(localStorage.getItem("enabledFeatures")) || isEmpty(localStorage.getItem("enabledFeatures"))) {
    return false
  }
  const enabledFeatures = localStorage.getItem("enabledFeatures").split(",");
  return enabledFeatures.includes(feature);
};

export const isSchedulingSupported = (reportType, frequency) => {
  return (
    REPORT_TYPES.TRANSACTION_DOWNLOAD === reportType &&
    isReportSchedulingEnabled() === true &&
    (!frequency || frequency !== FREQUENCY.ONCE)
  );
};

export const isClientSuspended = () => {
  return !isEmpty(localStorage.getItem(LOCAL_STORAGE_KEYS.CLIENT_SUSPENDED))
  ? localStorage.getItem(LOCAL_STORAGE_KEYS.CLIENT_SUSPENDED) === "true"
    ? true
    : false
  : false;
};

export const showFileRoute = () => {
	const isBankStatementUploadAllowed = isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT);
	const isCSVUploadAllowed = isFeatureEnabled(ENABLED_FEATURES_ENUM.IMPORT_CSV);
	return isBankStatementUploadAllowed || isCSVUploadAllowed;
}

const dataProvider = {
  getList: (resource, params) => {
    let query
    const isAdmin = isAdminUser();
    if (params) {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let queryField = getParsedFieldForSort(field, resource);
      query = {
        skip: page - 1,
        limit: perPage,
        sort: (queryField !== undefined ? queryField : "lastModified") + "," + order,
        filter: prepareFilterString(resource, params.filter),
      };
    }

    let apiEndpoint =
      RESOURCES_META_DATA[resource].getAllEndpoint ||
      RESOURCES_META_DATA[resource].apiEndpoint;

    let url = `${apiUrl}/${apiEndpoint}?${stringify(query)}`;
    if (resource === RESOURCES.STATUS_MESSAGES) {
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.map((listItem) => parseDataStatusMessageShow(listItem)),
          total: json.data.length,
        };
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors && err.body.errors.length ? err.body.errors[0].description : err.body.error,
            err.body.errors && err.body.errors.length ? err.body.errors[0].code : err.body.status,
            err.body.errors && err.body.errors.length ? err.body.errors[0].description : err.body.error,
          )
        );
      });
    } else if (resource === RESOURCES.CORPORATE_PARTNERS) {
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.content.map((listItem) =>
            DATA_PARSERS[resource](listItem)
          ),
          total: json.data.pagingInfo.totalElements,
        };
      });
    } else if (resource === RESOURCES.REPORTS) {
      //dont send a backend call for admin user until a corporate partner is selected
      if (isAdminUser() && params.filter.clientId === undefined) {
        return avoidApiCallForEmptyValues();
      }

      if (isAdminUser()) {
        //for admin user list
        apiEndpoint = "admin/report-request";
        url = `${apiUrl}/${apiEndpoint}/client/${
          params.filter.clientId
        }?${stringify(query)}`;
      }

      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.content.map((listItem) =>
            DATA_PARSERS[resource](listItem)
          ),
          total: json.data.pagingInfo.totalElements,
        };
      });
    } else if (resource === RESOURCES.SCHEDULES) {
      //dont send a backend call for admin user until a corporate partner is selected
      if (isAdminUser() && params.filter.clientId === undefined) {
        return avoidApiCallForEmptyValues();  
      }

      if (isAdminUser()) {
        //for admin user list
        apiEndpoint = "admin/report-request/schedule";
        url = `${apiUrl}/${apiEndpoint}/client/${
          params.filter.clientId
        }?${stringify(query)}`;
      }

      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.content.map((listItem) =>
            DATA_PARSERS[resource](listItem)
          ),
          total: json.data.pagingInfo.totalElements,
        };
      });
    } else if (resource === RESOURCES.SETTINGS) {
      return httpClient(url).then(({ headers, json }) => {
        var result = [];
        result.push(parseSettingsForList(json.data));
        return {
          data: result,
          total: parseInt(1),
        };
      });
    } else if (resource === RESOURCES.USERS) {

      if (isClientAdminUser()) {        
        apiEndpoint = RESOURCES_META_DATA[resource].clientAdminApiEndpoint;                
        url = `${apiUrl}/${apiEndpoint}?${stringify(query)}`;
      }

      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.content
          .filter((listItem) => listItem?.userId !== localStorage.getItem('loggedInUserId'))            
          .map((listItem) => parseUserData(listItem)),
          total: json.data.pagingInfo.totalElements,
        };
      });
    } else if (resource === RESOURCES.DEFAULT_CONTENT) {
      return httpClient(url).then(({ headers, json }) => {
        return {
          data: json.data.map((listItem) => parseDefaultContentData(listItem)),
          total: json.data.length,
        };
      });
    } else if (resource === RESOURCES.SURVEYS) {
      if (isAdminUser() && params.filter.clientId === undefined) {
        return avoidApiCallForEmptyValues();
      }

      if (isAdminUser()) {
        apiEndpoint = "client-survey";
        url = `${apiUrl}/${apiEndpoint}?clientId=${params.filter.clientId}`;
      }

      return httpClient(url).then(({ headers, json }) => {
        const data = json.data.map(survey => ({
          ...survey,
          id: survey.surveyId,
          name: survey.surveyDisplayName
        }))

        return {
          data,
          total: json.data.length,
        };
      });
    } else if (resource === RESOURCES.RISK_INSIGHTS) {
      //dont send a backend call for admin user until a corporate partner is selected
      if (isAdmin && params.filter.clientId === undefined) {
        return avoidApiCallForEmptyValues();
      }

      if (isAdmin) {                
        url = `${apiUrl}/admin/risk-insights?clientId=${params.filter.clientId}`;
      }
      
      return httpClient(url)
      .then(({json}) => {
        if (json.errors.length !== 0) {
          const errorCode = json.errors[0].code;
          if(errorCode === 404) {
            if(isAdmin){
              return {
                data : [],
                total: -1,    //Returning -1 to not display empty component on sys-admin page.            
              }
            }
            return {
              data : [],
              total: 0,                
            }
          }
        } else {      
          return {
            data : parseRiskInsightDataForList(json.data.riskInsightData, isAdmin),
            total: json.data.riskInsightData.length === 0 ? null : json.data.riskInsightData.length,            
          }
        }        
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors[0].description,
            err.body.errors[0].code,
            err.body.errors[0].description
          )
        );
      });
    } else if (resource === RESOURCES.CLIENT_DEFAULTS) {
      
      if (isAdmin && params.filter.clientId === undefined) {
        return avoidApiCallForEmptyValues();
      }

      url = `${apiUrl}/${apiEndpoint}/${isAdmin ? params.filter.clientId : localStorage.getItem("clientId")}`;

      return httpClient(url)
        .then(({ json }) => {
          return {
            data: parseClientDefaultsDataForList(json.data.clientDefaultList),
            total: json.data.clientDefaultList.length,
          };
        }).catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              err.body.errors[0].code,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === RESOURCES.MATTERS) {
      if (isAdminUser()) {
        if(params.filter.clientId === undefined)
          return avoidApiCallForEmptyValues();
        url = `${apiUrl}/admin/client/${params.filter.clientId}/${apiEndpoint}?${stringify(query)}`;
      }
      else 
        url = `${apiUrl}/${RESOURCES_META_DATA[resource].apiEndpoint}?${stringify(query)}`;

      return httpClient(url + "&summary=true")
        .then(({ json }) => {
          return {
            data: json.data.content.map((listItem) => DATA_PARSERS[resource](listItem)),
            total: json.data.pagingInfo.totalElements
          };
        }).catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              err.body.errors[0].code,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === SUB_RESOURCES.TRANSACTIONS) {
      let url = `${apiUrl}/matter/${params.matterId}/account/${params.accountId}/${apiEndpoint}?${stringify(query)}`;

      if (isAdminUser()) {
        if(params.clientId === undefined)
          return avoidApiCallForEmptyValues();
        url = `${apiUrl}/admin/client/${params.clientId}/matter/${params.matterId}/account/${params.accountId}/${apiEndpoint}?${stringify(query)}`;
      }

      return httpClient(url)
        .then(({ json }) => {
          return { content: json.data };
        })
        .catch((err) => {
          return { error : err };
        });
    }
  },

  // to make getOne call each row should have a primary key
  getOne: (resource, params) => {
    let apiEndpoint =
      RESOURCES_META_DATA[resource].getOneEndpoint ||
      RESOURCES_META_DATA[resource].apiEndpoint;
    if (resource === RESOURCES.STATUS_MESSAGES) {
      if (isAdminUser()) {
        let apiEndpoint = "admin/status-message";
        return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`).then(
            ({ json }) => ({
              data: parseDataStatusMessageShow(json.data),
            })
        );
      }
    } else if (resource === RESOURCES.CORPORATE_PARTNERS) {
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`)
        .then(({ json }) => ({
          data: parseCorporatePartnerDataForView(json.data),
        }))
        .then((data) => {
          return httpClient(
            `${apiUrl}/${apiEndpoint}/${params.id}/content`
          ).then(({ json }) => ({
            data: addSplashScreenContent(data, json),
          }));
        });
    } else if (resource === RESOURCES.REPORTS) {
      if (isAdminUser()) {
        let apiEndpoint = "admin/report-request";
        return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`).then(
          ({ json }) => ({
            data: parseOneReportRequestData(json.data),
          })
        );
      }

      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`).then(
        ({ json }) => ({
          data: parseOneReportRequestData(json.data),
        })
      );
    } else if (
      resource === RESOURCES.USERS ||
      resource === RESOURCES.SETTINGS
    ) {
      if (isClientAdminUser() && resource === RESOURCES.USERS) {
        apiEndpoint = RESOURCES_META_DATA[resource].clientAdminApiEndpoint;        
      }
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`).then(
        ({ json }) => ({
          data: parseSettingsForView(json.data),
        })
      );
    } else if (resource === RESOURCES.DEFAULT_CONTENT) {
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`).then(
        ({ json }) => {
          return {
            data: parseContentDataForView(json.data),
          };
        }
      );
    } else if (resource === RESOURCES.SCHEDULES) {
      if (isAdminUser()) {
        apiEndpoint = "admin/report-request/schedule/detail";
      }
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`).then(
        ({ json }) => ({
          data: parseScheduleDataForView(json.data),
        })
      );
    } else if (resource === RESOURCES.RISK_INSIGHTS) {
      if (isAdminUser()) {                
        apiEndpoint = `admin/risk-insights?clientId=${params.id}`;
        return httpClient(`${apiUrl}/${apiEndpoint}`)
        .then(({ json }) => ({
          data : parseDatatoShowCRIForAdmin(json.data),
        }));
      }
      return httpClient(`${apiUrl}/${apiEndpoint}`)
        .then(({ json }) => ({
          data : parseDatatoShowCRI(json.data),
        }));
    } else if (resource === RESOURCES.CLIENT_DEFAULTS) {

      if (isAdminUser() && params.id === undefined) {
        return avoidApiCallForEmptyValues();
      }

      let url = `${apiUrl}/${apiEndpoint}/${isAdminUser() ? params.id : localStorage.getItem("clientId")}`;

      return httpClient(url)
        .then(({ json }) => {
          if (json.errors.length !== 0) {
            throw createErrorObject(json.errors[0].code, json.errors[0].description);
          }
          return ({ data: parseDatatoShowClientDefaults(json.data) });
        }).catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              err.body.errors[0].code,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === SUB_RESOURCES.ACCOUNTS) {
      let url = `${apiUrl}/matter/${params.meta.matterId}/${apiEndpoint}/${params.id}`;

      if (isAdminUser()) {
        if(params.meta.clientId === undefined)
          return avoidApiCallForEmptyValues();
        url = `${apiUrl}/admin/client/${params.meta.clientId}/matter/${params.meta.matterId}/${apiEndpoint}/${params.id}`;
      }

      return httpClient(url)
      .then(({ json }) => {
        return { data: parseDatatoShowAccounts(json.data) };
      });
    } else if (resource === RESOURCES.MATTERS) {
      
      let url = `${apiUrl}/${apiEndpoint}/${params.id}`;

      if (isAdminUser()) {
        url = `${apiUrl}/admin/${params.id}`;
      }

      return httpClient(url)
        .then(({ json }) => {
          if (json.errors.length !== 0) {
            throw createErrorObject(json.errors[0].code, json.errors[0].description);
          }
          return ({ data: parseMatterDataForShow(json.data) });
        }).catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              err.body.errors[0].code,
              err.body.errors[0].description
            )
          );
        });
    }
  },

  update: async (resource, params) => {
    let apiEndpoint = RESOURCES_META_DATA[resource].apiEndpoint;
    if (resource === RESOURCES.STATUS_MESSAGES) {
      let apiEndpoint = "admin/status-message";
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`, {
        method: "PUT",
        body: parseDataStatusMessageUpdate(params.data),
      }).then(({ json }) => ({ data: params.data }));
    } else if (resource === RESOURCES.CORPORATE_PARTNERS) {
      await convertFileToBase64(params.data.icon);
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`, {
        method: "PUT",
        body: parseForUpdate(params.data),
      })
        .then(({ json }) => {
          //making a second call to save landingScreenContent if something has been entered by user.
          if (params.data.landingScreenContent != null) {
            return httpClient(
              `${apiUrl}/${apiEndpoint}/${json.data.clientId}/content/SPLASH_SCREEN`,
              {
                method: "PUT",
                body: parseForClientContentDto(
                  json.data,
                  params.data.landingScreenContent
                ),
              }
            );
          } else {
            var res = {};
            res.json = json;
            return Promise.resolve(res);
          }
        })
        .then(({ json }) => ({
          data: parseCorporatePartnerDataForView(json.data),
        }))
        .catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              500,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === RESOURCES.DEFAULT_CONTENT) {      
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.data.id}`, {
        method: "PUT",
        body: parseForContentUpdate(params.data),
      }).then(({ json }) => ({ data: parseContentDataForView(json.data) }));
    } else if (resource === RESOURCES.SETTINGS) {
      if (
        isEmpty(params.data.currentPassword) ||
        isEmpty(params.data.newPassword)
      ) {        
        //updating only firstname/lastname
        return httpClient(`${apiUrl}/${apiEndpoint}`, {
          method: "PATCH",
          body: parseSettingsForUpdate(params.data),
        }).then(({ json }) => ({
          data: {
            ...params.data,
            id: json.data.userId,
            passwordUpdated: false,
          },
        }));
      } else {
        //updating password as well
        return httpClient(`${apiUrl}/${apiEndpoint}`, {
          method: "PATCH",
          body: parseSettingsForUpdate(params.data),
        }).then(({ json }) =>
          httpClient(`${apiUrl}/${apiEndpoint}/update-password`, {
            method: "PATCH",
            body: parseSettingsForPasswordUpdate(params.data),
          }).then((resp) => {
            if (JSON.parse(resp.body).errors.length === 0) {
              alert("Password Update Successfull !!");
              return Promise.resolve({
                data: {
                  ...JSON.parse(resp.body),
                  id: params.data.userId,
                  passwordUpdated: true,
                },
              });
            } else {
              alert(
                "Password update failed as current password entered was incorrect !!"
              );
              return Promise.reject({
                data: {
                  error: "Password Update Failed",
                  id: params.data.userId,
                },
              });
            }
          })
        );
      }
    } else if (resource === RESOURCES.USERS) {
      
      if (isClientAdminUser() && resource === RESOURCES.USERS) {
        apiEndpoint = RESOURCES_META_DATA[resource].clientAdminApiEndpoint;                
      }
  
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`, {
        method: "PUT",
        body: parseSettingsForUpdate(params.data),
      }).then(({ json }) => {
        if (params.data.currentPassword && params.data.newPassword) {
          return httpClient(
            `${apiUrl}/${apiEndpoint}/${params.id}/change-password`,
            {
              method: "POST",
              body: parseUserPasswordForUpdate(params.data),
            }
          ).then((resp) => {
            if (JSON.parse(resp.body).errors.length === 0) {
              return { data: parseSettingsForView(resp.json.data) };
            } else {
              return Promise.reject(
                new HttpError(
                  "Password Update Failed",
                  500,
                  "Password Update Failed"
                )
              );
            }
          });
        } else {
          if (json.errors.length === 0) {
            return { data: parseSettingsForView(json.data) };
          } else {
            return Promise.reject(
              new HttpError("User Update Failed", 500, "User Update Failed")
            );
          }
        }
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors[0].description,
            500,
            err.body.errors[0].description
          )
        );
      });
    } else if (resource === RESOURCES.RISK_INSIGHTS) {

      if((isAdminUser())){
        return httpClient(`${apiUrl}/admin/${apiEndpoint}`, {
          method: "PUT",
          body: parseDatatoSaveCRIForAdmin(params.data),
        }).then(({ json }) => {
          return { 
            data: parseDatatoShowCRIForAdmin(json.data),
          };
        });
      }

      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "PUT",
        body: parseDatatoSaveCRI(params.data),
      }).then(({ json }) => {
        return { 
          data: parseDatatoShowCRI(json.data ),
        };
      });
    } else if (resource === RESOURCES.CLIENT_DEFAULTS) {      
      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "PUT",
        body: isAdminUser() ? parseDatatoSaveClientDefaultsForAdmin(params.data) : parseDatatoSaveClientDefaults(params.data),
      }).then(({ json }) => {
        return {
          data: parseDatatoShowClientDefaults(json.data),
        };
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors[0].description,
            err.body.errors[0].code,
            err.body.errors[0].description
          )
        );
      });
    } else if (resource === RESOURCES.MATTERS) {

      let url = `${apiUrl}/${apiEndpoint}/${params.id}`;

      return httpClient(url, {
        method: "PUT",
        body: parseMatterDataForUpdate(params.data),
      }).then(({ json }) => {
        return {
          data: parseMatterDataForShow(json.data),
        };
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors[0].description,
            err.body.errors[0].code,
            err.body.errors[0].description
          )
        );
      });
    }
  },

  create: async (resource, params) => {
    const isAdmin = isAdminUser();
    let apiEndpoint = RESOURCES_META_DATA[resource].apiEndpoint;

    if (resource === RESOURCES.STATUS_MESSAGES) {
      apiEndpoint = "admin/status-message";
      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "POST",
        body: parseDataStatusMessageCreate(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.data.id },
      }));
    } else if (resource === RESOURCES.CORPORATE_PARTNERS) {
      await convertFileToBase64(params.data.icon);
      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "POST",
        body: parseDataForCreate(params.data),
      })
        .then(({ json }) => {
          //making a second call to save landingScreenContent, Templates, Surveys if something has been entered by user.
          if(!isUndefined(params.data.templates)) {
            httpClient(
              `${apiUrl}/template/client/${json.data.clientId}`,
              {
                method: "PUT",
                body: parseTemplatesAssignmentForCreate(
                  params.data.templates
                ),
              }
            );
          }
          if(!isUndefined(params.data.surveys)) {
            httpClient(
              `${apiUrl}/client-survey/client/${json.data.clientId}`,
              {
                method: "PUT",
                body: parseSurveysForCreateAssignment(
                  params.data.surveyReportType,
                  params.data.surveys,
                  params.data.allSurvey
                ),
              }
            );
          }
          if (params.data.landingScreenContent !== undefined) {
            return httpClient(
              `${apiUrl}/${apiEndpoint}/${json.data.clientId}/content/SPLASH_SCREEN`,
              {
                method: "PUT",
                body: parseForClientContentDto(
                  json.data,
                  params.data.landingScreenContent
                ),
              }
            );
          } else {
            var res = {};
            res.json = json;
            return Promise.resolve(res);
          }
        })
        .then(({ json }) => ({
          data: { ...params.data, id: json.data.clientId },
        }))
        .catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              500,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === RESOURCES.USERS) {      
      const query = {
        redirectUrl: window.location.protocol + "//" + window.location.host,
      };

      if (isClientAdminUser()) {
        apiEndpoint = RESOURCES_META_DATA[resource].clientAdminApiEndpoint;
      }

      let url = `${apiUrl}/${apiEndpoint}?${stringify(query)}`;

      return httpClient(url, {
        method: "POST",
        body: parseDataForUserCreate(params.data),
      })
        .then(({ json }) => {
          if( json.errors.length !== 0){
            const errorCode = json.errors[0].code;
            let errorDescription = json.errors[0].description;

            if(isClientAdminUser() && errorCode === CREATE_USER.SAME_NAME_OR_EMAIL_ERROR_CODE){
              errorDescription = CREATE_USER.SAME_NAME_OR_EMAIL_ERROR_MESSAGE;
            }

            const error = {
              body : {
                errors : [{
                  code : errorCode,
                  description : errorDescription,
                }]
              }
            };
            throw error;
          }else{
            return { data : parseSettingsForView(json.data)};
          }
        })
        .catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              500,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === RESOURCES.DEFAULT_CONTENT) {
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.data.contentType}`, {
        method: "POST",
        body: parseDataForContentCreate(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === RESOURCES.REPORTS) {
      let requestBody = parseCreateReportRequestBodyData(params.data);
      const parsedRequestBody = JSON.parse(requestBody);

      if (parsedRequestBody.matterId) {
        // classify transactions
        await httpClient(
          `${apiUrl}/${RESOURCES_META_DATA[RESOURCES.MATTERS].apiEndpoint}/${parsedRequestBody.matterId}/classify/`,
          { method: "POST" }
        ).then(({ json }) => {
          return ({ data: json.data });
        }).catch((error) => {
          return ({ error: error });
        });
      }

      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "POST",
        body: requestBody,
      }).then(({ json }) => {
        if( json.errors.length !== 0 ){
          throw createErrorObject(json.errors[0].code, json.errors[0].description);
        }else{
          return {
            data: {
              reportRequestScheduleId: json.data,
              parsedRequestBody,
              id: parsedRequestBody.clientRequestId,
            },
          };
        }
      })
        .catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              500,
              err.body.errors[0].description
            )
          );
        });
    } else if (resource === RESOURCES.RISK_INSIGHTS) {
      if(isAdmin){
        apiEndpoint = "admin/" + apiEndpoint;
      }
      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "POST",
        body: isAdmin ? parseDatatoSaveCRIForAdmin(params.data) : parseDatatoSaveCRI(params.data),
      }).then(({ json }) => {
        return { 
          data: parseDatatoShowCRI(json.data),
        };
      });
    } else if (resource === RESOURCES.CLIENT_DEFAULTS) {
      return httpClient(`${apiUrl}/${apiEndpoint}`, {
        method: "PUT",
        body: isAdmin ? parseDatatoSaveClientDefaultsForAdmin(params.data) : parseDatatoSaveClientDefaults(params.data),
      }).then(({ json }) => {
        return {
          data: parseDatatoShowClientDefaults(json.data),
        };
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors[0].description,
            err.body.errors[0].code,
            err.body.errors[0].description
          )
        );
      });
    } else if (resource === RESOURCES.MATTERS) {
      
      let url = `${apiUrl}/${apiEndpoint}`;

      return httpClient(url, {
        method: "POST",
        body: parseMatterDataForCreate(params.data),
      }).then(({ json }) => {
        return { 
          data: parseMatterDataForShow(json.data) 
        };
      }).catch((err) => {
        return Promise.reject(
          new HttpError(
            err.body.errors[0].description,
            err.body.errors[0].code,
            err.body.errors[0].description
          )
        );
      });
    }
  },

  delete: (resource, params) => {
    let apiEndpoint = RESOURCES_META_DATA[resource].apiEndpoint;

    if(resource === RESOURCES.USERS){

      let url = `${apiUrl}/${apiEndpoint}/${params.id}`

      if (isClientAdminUser()) {
        apiEndpoint = RESOURCES_META_DATA[resource].clientAdminApiEndpoint;
        if(!isUndefined(params.transferUserId)){
          url = `${apiUrl}/${apiEndpoint}/${params.id}?transferUserId=${params.transferUserId}`      
        }else{
          url = `${apiUrl}/${apiEndpoint}/${params.id}`      
        }                
      }

      return httpClient(url, {
        method: "DELETE",
      })
      .then(({ json }) => ({ data: { id: params.id } }))
      .catch((err) => {
        const error = {
          id : params.id,
          code : err.body.errors[0].code,
          description : err.body.errors[0].description,
        }
        throw error;      
      });
    }
    else if(resource === SUB_RESOURCES.ACCOUNTS) {
      let matterApiEndpoint = RESOURCES_META_DATA[RESOURCES.MATTERS].apiEndpoint;
      let url = `${apiUrl}/${matterApiEndpoint}/${params.previousData.matterId}/${apiEndpoint}/${params.id}`;

      return httpClient(url, {
        method: "DELETE",
      }).then(({ json }) => ({ data: { id: params.id } }))
      .catch((err) => {
        const error = {
          id : params.id,
          code : err.body.errors[0].code,
          description : err.body.errors[0].description,
        }
        throw error;      
      });
    }
    else{
      return httpClient(`${apiUrl}/${apiEndpoint}/${params.id}`, {
        method: "DELETE",
      }).then(({ json }) => ({ data: { id: params.id } }))
      .catch((err) => {
        const error = {
          id : params.id,
          code : err.body.errors[0].code,
          description : err.body.errors[0].description,
        }
        throw error;      
      });
    }  
  },

  deleteSaveFetchTransactions: (params) => {
    const { meta, saveData, deleteData } = params
    const { matterId, accountId } = meta
    const { transactions } = saveData
    const { transactionIds } = deleteData
    const url = `${apiUrl}/matter/${matterId}/account/${accountId}/transactions`;
    const saveFetchTransactions = (params) => {
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ transactions }),
      })
        .then((data) => {
          //making a second fetch transactions in order for the front end to receive the inserted rows their DB transactionIds and the rest of their properties
          return dataProvider.getAllTransactions({ matterId, accountId })
        })
        .then(({ content }) => content)
        .catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              500,
              err.body.errors[0].description
            )
          );
        });
    }

    if (transactionIds.length) {
      return httpClient(url, {
        method: "DELETE",
        body: JSON.stringify({ transactionIds }),
      })
        .then((data) => saveFetchTransactions(params))
        .catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              500,
              err.body.errors[0].description
            )
          )
        });
    } else {
      return saveFetchTransactions(params)
    }
  },

  getManyReference: (resource, params) => {
    if(resource === SUB_RESOURCES.ACCOUNTS) {
      let matterApiEndpoint = RESOURCES_META_DATA[RESOURCES.MATTERS].apiEndpoint;
      let getAllEndpoint = RESOURCES_META_DATA[resource].getAllEndpoint;
      let apiEndpoint = RESOURCES_META_DATA[resource].apiEndpoint;
      let url = `${apiUrl}/${matterApiEndpoint}/${params.id}/${apiEndpoint}`;
      
      if (isAdminUser()) {
        url = `${apiUrl}/admin/${params.id}/${getAllEndpoint}`;
      }

      return httpClient(url)
        .then(({ json }) => {
          return {
            data: parseMatterAccountDataForList(json.data, params.id),
            total: json.data.length,
          };
        }).catch((err) => {
          return Promise.reject(
            new HttpError(
              err.body.errors[0].description,
              err.body.errors[0].code,
              err.body.errors[0].description
            )
          );
        });
    }
  },

  getClientNameList: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "GET",
    }).then(({ json }) => ({ data: parseClientNamesData(json.data) })),

  getMappedUsersSummary : (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "GET",
    }).then(({ json }) => ({ data: parseMappedUsersData(json.data) })),

  hide: (resource, params) => {
    const apiEndpoint = RESOURCES_META_DATA[resource].apiEndpoint;
    return httpClient(
      `${apiUrl}/${apiEndpoint}/${params.id}/${
        params.hide ? "hide" : "unhide"
      }`,
      {
        method: "PUT",
      }
    ).then(({ json }) => ({ data: { id: params.id } }));
  },
  atpAmendment: (resource, params) => {
    const apiEndpoint = RESOURCES_META_DATA[resource].apiEndpoint;
    return httpClient(
      `${apiUrl}/${apiEndpoint}/${params.id}`, 
      {
        method: "PATCH",
        body: params.isForSurvey ? parsePatchSurveyParams(params.surveyParam) : parsePatchReportRequesBodyData(params),
      }
    ).then(({ json }) => ({ data: { id : params.id } }))
    .catch((err) => {
      return Promise.reject(
        new HttpError(
          err.body.errors[0].description,
          500,
          err.body.errors[0].description
        )
      );
    });
  },
  getAllTemplates: () => {
    const apiEndpoint = `${apiUrl}/template`;
    return httpClient(
      apiEndpoint, {
        method: "GET",
      })
      .then(({ json }) => ({ data: parseTemplateChoices(json.data) }))
      .catch(({ json }) => ({ error : json }));
  },
  getAllTransactions: ({clientId, matterId, accountId}) => {
    let url= `${apiUrl}/matter/${matterId}/account/${accountId}/transactions`;
    
    if (isAdminUser()) {
      if(clientId === undefined)
        return avoidApiCallForEmptyValues();
      url = `${apiUrl}/admin/client/${clientId}/matter/${matterId}/account/${accountId}/transactions`;
    }

    return httpClient(
      url, { method: "GET" })
      .then(({ json }) => ({ content: json.data }))
      .catch(({ json }) => ({ error : json }));
  },
  getMappedTemplates: (params) => {
    const apiEndpoint = `${apiUrl}/template/client/${params.id}`;
    return httpClient(
      apiEndpoint, {
        method: "GET",        
      })
      .then(({ json }) => ({ data: parseMappedTemplates(json.data) }))
      .catch(({ json }) => ({ error : json }));   
  },
  unMapTemplate: (params) => {
    const apiEndpoint = `${apiUrl}/template/${params.templateId}/client/${params.id}`;
    return httpClient(
      apiEndpoint, {
        method: "DELETE",
    })
    .then(({ json }) => ({ data: { id: params.id } }));
  },
  updateAssignmentOfTemplates: (params) => {
    const apiEndpoint = `${apiUrl}/template/client/${params.id}`;
    return httpClient(
      apiEndpoint, {
        method: "PUT",
        body: parseTemplatesForAssignment(params.preSelectedValues,params.postSelectedValues)
    })
    .then(({ json }) => ({ data: parseMappedTemplates(json.data) }))
    .catch(({ json }) => ({ error : json }));
  },
  getAllSurveys: () => {
    const apiEndpoint = `${apiUrl}/survey`;
    return httpClient(
      apiEndpoint, {
        method: "GET",
      })
      .then(({json}) => ({ data: parseSurveyChoices(json.data) }))
      .catch(({json}) => ({ error : json }));
  },
  getMappedSurveys: (params) => {
    const apiEndpoint = `${apiUrl}/client-survey?clientId=${params.id}`;
    return httpClient(
      apiEndpoint, {
        method: "GET",
      })
      .then(({ json }) => ({ data: parseMappedSurveysbyReportType(json.data)}))
      .catch(({ json }) => ({ error : json }));
  },
  unMapSurvey: (params) => {
    const apiEndpoint = `${apiUrl}/client-survey/${params.id}`;
    return httpClient(
      apiEndpoint, {
        method: "DELETE",
      })
      .then(({ json }) => ({ 
        data: { 
          id: params.id,           
        }
      }))
      .catch(({ json }) => ({ error : json }));
  },
  updateAssignmentOfSurveys: (params) => {
    const apiEndpoint = `${apiUrl}/client-survey/client/${params.clientId}`;
    return httpClient(
      apiEndpoint, {
        method: "PUT",
        body: parseSurveysForAssignment(params.reportType, params.mappedSurveys, params.postSelectedSurveys, params.allSurvey)
    })
    .then(({ json }) => ({ data: parseMappedSurveysbyReportType(json.data) }))
    .catch(({ json }) => ({ error : json }));
  },  
  getAllMappedUsers: () => {
    const apiEndpoint = `${apiUrl}/client/mapped-users`;
    return httpClient(
      apiEndpoint, {
        method: "GET",
      })
      .then(({json}) => ({ data: parseMappedUsersChoices(json.data) }))
      .catch(({json}) => ({ error : json }));
  },
  getMappedUsersbyClientId: (params) => {
    const apiEndpoint = `${apiUrl}/admin/client/${params.clientId}/user/detail`;
    return httpClient(
      apiEndpoint, {
        method: "GET",
      })
      .then(({json}) => ({ data: parseMappedUsersData(json.data) }))
      .catch(({json}) => ({ error : json }));
  },
  getAllRiskInsightParameters: () => {
    const apiEndpoint = `${apiUrl}/risk-insights/parameters`;
    return httpClient(
      apiEndpoint, {
        method: "GET",
      })
      .then(({json}) => ({ data: parseComparisonChoices(json.data) }))
      .catch(({json}) => ({ error : json }));
  },
  getAllClientDefaultParameters: () => {
    const apiEndpoint = `${apiUrl}/admin/client-default/parameters`;

    return httpClient(
        apiEndpoint, {
        method: "GET",
      })
      .then(({ json }) => {
        return ({ data: json.data });
      })
      .catch((error) => {
        return ({ error : error });
      });
  }, 
  
  uploadFiles: (params) => {      
    
    const formData = new FormData();
    formData.append('files', params.file.rawFile);
    const apiEndpoint = `${apiUrl}/matter/${params.matterId}/import-csv`;        

    return httpClient(apiEndpoint, 
      {
        method: "PUT",
        body: formData,          
      })
      .then(({ json }) => {        
        return parseCsvUploadResults(params.id, json);
      })
      .catch((err) => {
        const error = {
          id : params.id,
          code : err.body.errors[0].code,
          description : err.body.errors[0].description,
        }
        throw error;
      });    
  },

  uploadPdfFiles: (params) => {    
    
    const formData = new FormData();
    for (const file of params?.file) {
      formData.append('files', file.rawFile, file.name);
    }
    const apiEndpoint = `${apiUrl}/matter/${params.matterId}/bank-statement-job?${prepareQueryParams(params)}`;        

    return httpClient(apiEndpoint, 
      {
        method: "POST",
        body: formData,          
      })
      .then(({ json }) => {              
        return parsePdfUploadResults(json.data);
      })
      .catch((err) => {
        const error = {
          id : params.id,
          code : err.body.errors[0].code,
          description : err.body.errors[0].description,
        }
        throw error;
      });    
  },

  getAllBanks: (params) => {
    const apiEndpoint = `${apiUrl}/bank?${stringify(params)}`;

    return httpClient(
      apiEndpoint,
      { method: "GET" }
    ).then(({ json }) => {
      return ({ data: parseBankData(json.data) });
    }).catch((error) => {
      return ({ error: error });
    });
  },

  getBankStatementJobStatus: (params) => {

    const createdAfter = getYesterday();
    const apiEndpoint = `${apiUrl}/matter/${params.id}/bank-statement-job?forCurrentUser=${true}&createdAfter=${createdAfter}`;

    return httpClient(
        apiEndpoint, {
        method: "GET",
      })
      .then(({ json }) => {
        return ({ data: parseBankStatementJobImportStatus(json.data) });
      })
      .catch((error) => {
        return ({ error : error });
      });    
  }
};

export default dataProvider;
