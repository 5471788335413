import React from "react";
import {
  ArrayInput,
  FormDataConsumer,
  Loading,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useDataProvider,
  useNotify,
  Button as RaButton
} from 'react-admin'
import { isAdminUser } from '../DataProvider'
import { Button, Grid } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { positiveValue } from '../../utils/util'
import CustomTooltip from '../../utils/CustomTooltip'
import {
  accordionActionButton,
  display,
  iconAction,
  padding1,
  removeNumberArrowsStyle,
  simpleFormIterator,
  simpleFormIteratorAdd,
  width
} from '../Styles'
import Divider from '@mui/material/Divider'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export const ClientDefaultsCreateEditGrid = (props) => {
  const [loading, setLoading] = React.useState(true);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [clientDefaultParameters, setClientDefaultParameters] = React.useState([]);
  const isAdmin = isAdminUser();

  React.useEffect(() => {
    fetchClientDefaultParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientDefaultParams = () => {
    dataProvider
      .getAllClientDefaultParameters()
      .then(params => {
        let parameters = [];
        params.data.forEach(param => {
          parameters.push({
            id: param.parameterKey,
            name: param.parameterDisplayName,
          });
        });
        setClientDefaultParameters(parameters);
        setLoading(false);
      })
      .catch((error) => {
        notify('Something went wrong while fetching client default parameters.', "warning", { autoHideDuration: 1000 });
      });
  };

  const uniqeParameterValidation = (value, allValues) => {
    const selectedValues = allValues.clientDefaultList.filter(item => item && item.parameterKey).map(item => item.parameterKey);
    const valueCount = selectedValues.filter(val => val === value).length;
    if (valueCount > 1) {
      return 'Each parameter must be unique';
    }
  };

  return (
    <>
      {loading && <Loading />}
      {!loading &&
        (
          <Grid container flexGrow={1}>
            <Grid item xs={12}>
              {isAdmin &&
                <Grid sx={display.none}>
                  <TextInput
                    source="id"
                    label={false}
                    defaultValue={props.clientId}
                  />
                </Grid>
              }
              <ArrayInput source="clientDefaultList" label={false}>
                <SimpleFormIterator
                  sx={{...simpleFormIterator, ...simpleFormIteratorAdd}}
                  disableClear
                  disableReordering
                  removeButton={
                    <CustomTooltip title="Remove" placement="top">
                      <Button sx={iconAction} size="medium">
                        <DeleteOutlinedIcon />
                      </Button>
                    </CustomTooltip>
                  }
                  addButton={
                    <RaButton
                      variant="contained"
                      sx={{...accordionActionButton, marginLeft: "0 !important" }}
                      label="Add"
                      size="small"
                    >
                      <AddCircleOutlineIcon />
                    </RaButton>
                  }
                >
                  <FormDataConsumer>
                    {({ getSource }) => {
                      return (
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <SelectInput
                              source={getSource("parameterKey")}
                              label="Parameter"
                              choices={clientDefaultParameters}
                              helperText={false}
                              variant="outlined"
                              size="small"
                              sx={{...width.fluid.w100, ...padding1.right }}
                              validate={[required("Please select parameter"), uniqeParameterValidation]}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <NumberInput
                              source={getSource("value")}
                              label="Value"
                              helperText={false}
                              variant="outlined"
                              size="small"
                              sx={{ ...removeNumberArrowsStyle, ...width.fluid.w100, ...padding1.right }}
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              validate={[required("Please enter value"), positiveValue]}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
              <Divider light />
            </Grid>
          </Grid>
        )
      }
    </>
  );
}