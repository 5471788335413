import * as React from "react";
import { Grid } from "@mui/material";
import {
  CreateButton,
  EditButton,
  useListContext,
  useListFilterContext,
} from 'react-admin'
import { parseForAction, parseForAdminAction } from "../../mappers/ClientRiskInsight";
import { ActionTitles, RESOURCES } from "../Constants";
import { isAdminUser } from "../DataProvider";
import {pageTitle, topActionButtonBaseStyle, topActionButtonsRow} from '../Styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { StatusMessageAlerts } from "../common/AlertComponent";

export const RiskInsightsActions = (props) => {
  const isAdmin = isAdminUser();
  const filterContext = useListFilterContext();
  const { total } = useListContext();
  
  const isRecordNotPresent =  total === -1;
  const isClientSelected = filterContext?.filterValues?.clientId;

  return (
    <Grid container>
      { !isAdmin && <StatusMessageAlerts /> }
      <Grid item container xs={12} justifyContent={'space-between'}>
        <Grid sx={pageTitle}>{props.title}</Grid>
        {props.showCreateEditButton === true && (
          <Grid sx={topActionButtonsRow}>
            {
              isAdmin ?
                isClientSelected ?
                  isRecordNotPresent ?
                    <CreateButtonForActions
                      clientId={filterContext?.filterValues?.clientId}
                      {...props}
                    /> :
                    <EditButtonForActions
                      isAdmin={isAdmin}
                      clientId={filterContext?.filterValues?.clientId}
                      {...props}
                    /> :
                  <></> :
                <EditButtonForActions
                  isAdmin={isAdmin}
                  {...props}
                />
            }
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12}>
        <div>{props.filters}</div>
      </Grid>
    </Grid>
  );
}

// very similar to CreateButtonForActions from clientDefaultsActions.jsx
const CreateButtonForActions = (props) => {
  return (
    <CreateButton
      sx={{...topActionButtonBaseStyle()}}
      size="small"
      to={`${props.basePath}/create/${props.clientId}`}
      label={ActionTitles.CREATE}
      icon={<AddCircleOutlineIcon />}
    />
  );
}

// duplicate of EditButtonForActions from clientDefaultsActions.jsx
const EditButtonForActions = (props) => {
  return (
    <EditButton
      sx={{...topActionButtonBaseStyle()}}
      size="small"
      icon={<EditOutlinedIcon />}
      label={ActionTitles.UPDATE}
      resource={RESOURCES.RISK_INSIGHTS}
      record={props.isAdmin ? parseForAdminAction(props.clientId) : parseForAction()}
      {...props}
    />
  );
}