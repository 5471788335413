import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import { positiveAmount } from '../../utils/util';
import { SelectInput, NumberInput } from 'react-admin';
import React from 'react';
import { SUPPORTED_CURRENCIES, CurrencyChoices } from '../Constants';
import {
  areDependentValidationsSatisfied,
  showAmountCurrencyAndCurrencyToProve,
} from '../../utils/CreateReportUtils';
import { createCurrencyChoicesDynamically } from "../../utils/util";
import { isAmountToProveFieldRequired, isAmountToProveMandatory, canFieldBeHidden } from '../../utils/CreateReportUtils';
import {
  requiredValidation,
} from "../../utils/util";
import { useWatch, useFormContext } from 'react-hook-form';
import {padding2,removeNumberArrowsStyle,textColor,width} from '../Styles'

export const CurrencyAndAmountDynamicFields = (props) => {
  const values = useWatch();
  const amountCurrencyField = props.currencyField;
  const amountField = props.amountField;
  const form = useFormContext();

  React.useEffect(() => {
    if(showAmountCurrencyAndCurrencyToProve(values.reportType)){
      form.setValue("amountCurrency", amountCurrencyField?.defaultValue);
      form.setValue("amountToProve", amountField?.defaultValue);
    } else {
      form.resetField("amountCurrency");
      form.resetField("amountToProve");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values.reportType, props.selectedTemplateId, amountCurrencyField?.defaultValue, amountField?.defaultValue])

  if (
    amountCurrencyField &&
    amountField &&
    amountCurrencyField.dependentOn &&
    amountField.dependentOn &&
    !areDependentValidationsSatisfied(amountField.dependentOn, values) &&
    !areDependentValidationsSatisfied(amountCurrencyField.dependentOn, values)
  )
    return null;

  const currencyChoices =
    amountCurrencyField && amountCurrencyField.possibleValues
      ? createCurrencyChoicesDynamically(amountCurrencyField.possibleValues)
      : CurrencyChoices;

  const isAmountToProveRequired = (isAmountToProveMandatory(values) ? isAmountToProveMandatory(values) : false)
  return (
    showAmountCurrencyAndCurrencyToProve(values.reportType) && (
      <React.Fragment>
        <Grid sx={{
          ...(amountCurrencyField && canFieldBeHidden(
            amountCurrencyField.isHidden,
            amountCurrencyField.required,
            amountCurrencyField.defaultValue
          ) ? { display: "none" } : {} )
        }}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                {amountCurrencyField ? amountCurrencyField.displayName : 'Amount Currency'}
                {amountCurrencyField?.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
              </Grid>
              <Grid item xs={12} md={props.isMatterShow ? 8 : 6} lg={props.isMatterShow ? 8 : 5}>
                <SelectInput
                  source="amountCurrency"
                  label={amountCurrencyField ? amountCurrencyField.placeholder : 'Amount Currency'}
                  variant="outlined"
                  size="small"
                  defaultValue={amountCurrencyField ? amountCurrencyField.defaultValue : SUPPORTED_CURRENCIES.POUND}
                  choices={currencyChoices}
                  validate={amountCurrencyField?.required && requiredValidation}
                  isRequired
                  sx={{ ...width.fluid.w75, marginBottom: "-15px" }}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
        </Grid>
        {!props.isMatterShow && isAmountToProveFieldRequired(values) && (
          <React.Fragment>
            <Grid sx={{
              ...(amountField && canFieldBeHidden(
                amountField.isHidden,
                amountField.required,
                amountField.defaultValue
              ) ? { display: "none" } : {})
            }}
            >
              <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={4} sx={textColor.gray}>
                    {amountField ? amountField.displayName : 'Amount to Prove'}
                    {isAmountToProveMandatory(values) && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
                  </Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <NumberInput
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      source="amountToProve"
                      label={
                        amountField && amountField.placeholder ?
                          amountField.placeholder :
                          `Amount to Prove${isAmountToProveMandatory(values) ? '' : ' (Optional)'}`
                      }
                      variant="outlined"
                      size="small"
                      onKeyPress={(e) => {
                        //number input dont allow charcaters generally, 'e' was allowed to store big intergers, hence disabling that manually
                        if (e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                      min={0}
                      validate={[isAmountToProveRequired && requiredValidation, positiveAmount]}
                      sx={{ ...removeNumberArrowsStyle, ...width.fluid.w75, marginBottom: "-15px" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  );
};

export default CurrencyAndAmountDynamicFields;