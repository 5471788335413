import { Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import { colorField } from '../Styles'

const ColorField = (props) => {
  const record = useRecordContext();

  return (
  <Grid container flexShrink={0} flexWrap={"nowrap"}>
    <Grid>
      <Grid style={{ ...colorField.displayStyle.pad, backgroundColor: record[props.source] }} />
    </Grid>
    <Grid>
      {record[props.source]}
    </Grid>
  </Grid>
)};

export default ColorField;
