import * as React from "react";
import {
  ArrayField,
  ChipField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  isEmpty,
  useRecordContext,
} from 'react-admin'
import ListActions from "../common/ListActions";
import ColorField from '../common/ColorField'
import { CustomImageField } from '../common/CustomImageField'
import {
  ActionTitles,
  CLIENT_STATUS_NAME,
  ENABLED_FEATURES,REPORT_BRANDING_TYPES_DISPLAY_NAMES,RESOURCES,RESOURCES_BASEPATH,
  SURVEY_SUPPORT_TYPES_DISPLAY_NAMES
} from '../Constants'
import {
  float,
  textColor,
  contentCenter,
  padding0,
  padding2
} from '../Styles'
import {Grid} from '@mui/material'
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import CustomUrlField from '../../utils/CustomUrlField'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import {BooleanFieldShowComponent} from '../common/BooleanComponents'
import ReactHtmlParser from 'react-html-parser'
import TemplateAccordian from './TemplateAccordian'
import SurveyAccordion from './SurveyAccordion'

export const CorporatePartnerShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.CORPORATE_PARTNER_DETAILS}
        showEditButton={true}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
        resource={RESOURCES.CORPORATE_PARTNERS}
        recordName={ActionTitles.CORPORATE_PARTNER}
      />
    }
    renderButtonAndSearch={false}
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <CorporatePartnerShowGrid />
    </SimpleShowLayout>
  </Show>
);

const CorporatePartnerShowGrid = () => {
  const record = useRecordContext();
  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Name</Grid>
              <Grid xs={8}>{record['clientName']}</Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Primary User</Grid>
              <Grid xs={8}>
                <strong style={{...textColor.primary}}>{record['email']}</strong>
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Notes</Grid>
              <Grid xs={8}>{record['notes']}</Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Logo</Grid>
              <Grid xs={8}>
                <CustomImageField record={record} />
                {record['icon'] && (
                  <strong>
                    <a
                      style={{...textColor.primary}}
                      download="logo"
                      href={`data:image/png;base64,${record['icon']}`}
                    >
                      Download
                    </a>
                  </strong>
                )}
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Logo URL</Grid>
              <Grid xs={8}>
                <CustomUrlField
                  source={record['logoUrl']}
                  value={record['logoUrl']}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Primary Colour</Grid>
              <Grid xs={8}>
                <ColorField source="primaryColour" label="PRIMARY COLOUR" />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Secondary Colour</Grid>
              <Grid xs={8}>
                <ColorField source="secondaryColour" label="SECONDARY COLOUR" />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Tertiary Colour</Grid>
              <Grid xs={8}>
                <ColorField source="tertiaryColour" label="TERTIARY COLOUR" />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Report Branding</Grid>
              <Grid xs={8}>
                {REPORT_BRANDING_TYPES_DISPLAY_NAMES[record['reportBranding']]}
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Report Scheduling</Grid>
              <Grid xs={8}>
                {record['reportScheduling'] ?
                  (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                  (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Survey Support Type</Grid>
              <Grid xs={8}>{SURVEY_SUPPORT_TYPES_DISPLAY_NAMES[record['surveySupportType']]}</Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Income Insights Permission</Grid>
              <Grid xs={8}>
                {record['incomeInsightsPermission'] ?
                  (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                  (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Risk Insights Permisson</Grid>
              <Grid xs={8}>
                {record['riskInsightsPermission'] ?
                  (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                  (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          {ENABLED_FEATURES.map((entry) => (
            <BooleanFieldShowComponent
              name={entry.name}
              title={entry.title}
              record={record}
            />
          ))}
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Client Status</Grid>
              <Grid xs={8}>
                {isEmpty(record.clientStatus)
                    ? CLIENT_STATUS_NAME.ACTIVE
                    : CLIENT_STATUS_NAME[record.clientStatus]}
              </Grid>
            </Grid>
          </ListItem>        
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Reply To Mask Email</Grid>
              <Grid xs={8}>
                {record['replyToEmail']}
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Segment</Grid>
              <Grid xs={8}>{record['segment']}</Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Landing Screen Content</Grid>
              <Grid xs={8}>{ReactHtmlParser(record['landingScreenContent'])}</Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>
                Email Reminder Intervals (In days)
              </Grid>
              <Grid xs={8}>
                <ArrayField source="customReminderIntervals">
                  <SingleFieldList linkType={false}>
                      <ChipField source="interval" />
                  </SingleFieldList>
                </ArrayField>
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <BooleanFieldShowComponent
            name="finalReminderNotification"
            title="Final Reminder Notification"
            record={record}
          />
          <TemplateAccordian clientId={record["clientId"]} isShow={true}/>
          <Divider light />
          <SurveyAccordion clientId={record["clientId"]} isShow={true}/>
        </List>
      </Grid>
    </Grid>
  );
};