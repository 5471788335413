import {useState} from "react";
import { SaveButton } from "ra-ui-materialui";
import { getCreationMessage } from "../../utils/util";
import { useNotify, useRefresh, useRedirect } from "react-admin";
import SendEmailDialog from '../common/SendEmailDialog';

import React from "react";
import { ActionTitles } from "../Constants";
import {baseButtonStyle} from '../Styles'

const CreateAndSendEmail = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [open, setOpen] = useState(false);
  const [clientRequestId, setClientRequestId] = useState(null);

  const onReportSuccessWithoutRedirect = ( data ) => {
    setOpen(true);
    setClientRequestId(data.id);
  };

  const handleDialogClose = () => {
    setOpen(false);
    const message = getCreationMessage(props.resource, props.isEdit);
    notify(message, { type: 'info', autoHideDuration: 1000 });
    redirect(`${props.basePath}/${clientRequestId}/show`);
    refresh();
  };

  const onFailure = (error) => {
    notify(error.message, { type: 'warning', autoHideDuration: 1000 });
  }

  return (
    <React.Fragment>
        <SaveButton
          type="button"
          icon={<div />}
          mutationOptions={{ onSuccess: onReportSuccessWithoutRedirect, onError: onFailure }}
          label={ActionTitles.CREATE_AND_SEND_EMAIL}
          sx={{...baseButtonStyle()}}
          size="medium"
          disabled={props.isScheduled}
          key={`${props.isValid}1`}
        />
        {open && (
          <SendEmailDialog
            handleDialogClose={handleDialogClose}
            clientRequestId={clientRequestId}
            handleSuccess={handleDialogClose}
          />
        )}
    </React.Fragment>
  );
};

export default CreateAndSendEmail;
