import { isEmpty } from "ra-core";

export function parseActiveStatusMessages(data) {
  if(!isEmpty(data)){
    return data.map((message) => {  
      if(message.type === 'danger'){
        message.type = 'error';
      }      

      delete message['displayName'];
      delete message['effectiveFrom'];
      delete message['effectiveTo'];
      delete message['components'];
      delete message['deleted'];    

      return message;
  })
}
}