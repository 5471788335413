//In order to make Client Risk Insight compatible with react admin components, we explicitly parse ids for every network call.
import { 
  filterNullValuesFromList,
  isFalsy, 
} from '../utils/util';
import isEmpty from "lodash/isEmpty";

export function parseDatatoShowCRI(data) {
  
  data.id = localStorage.getItem('loggedInUserId');
  data.riskInsightData = filterNullValuesFromList(data['riskInsightData']);

  delete data['riskInsightId'];
  delete data['userId'];
  delete data['clientId'];
  delete data['version'];
  delete data['created'];
  delete data['lastModified'];
  return data;
}

export function parseDatatoShowCRIForAdmin(data) {
  
  data.id = data['clientId'];
  data.riskInsightData = filterNullValuesFromList(data['riskInsightData']);

  delete data['riskInsightId'];
  delete data['userId'];
  delete data['clientId'];
  delete data['version'];
  delete data['created'];
  delete data['lastModified'];
  return data;
}

export function parseDatatoSaveCRI(data) {
  const riskInsightData = data?.riskInsightData;
  !isEmpty(riskInsightData) && riskInsightData.map(element => element.weight = 1); //default value of weight
  
  const parsedRequest = {
    riskInsightData : riskInsightData,
  }
  return JSON.stringify(parsedRequest);
}

export function parseDatatoSaveCRIForAdmin(data) {
  const riskInsightData = data?.riskInsightData;
  !isEmpty(riskInsightData) && riskInsightData.map(element => element.weight = 1); //default value of weight
  
  const parsedRequest = {
    clientId : data.id,
    riskInsightData : riskInsightData,
  }
  return JSON.stringify(parsedRequest);
}

export function parseComparisonChoices(data) {
  return data.map((element) => {
    element.id = element.name;
    element.name = element.displayName;
    
    delete element['displayName'];
    
    return element
  })
}

export function parseRiskInsightDataForList(data, isAdmin) {
  data = filterNullValuesFromList(data);
  data.map((element, index) => {
    element.id = index;
    return element;
  });
  if(!isAdmin && isEmpty(data)){//sending static data for empty risk insights list to avoid showing empty component.
    return [{ id : 1 }];
  }
  return data; 
}

export function parseForAction() {
  let obj = {};
  obj.id = localStorage.getItem('loggedInUserId');
  return obj;
}

export function parseForAdminAction(clientId) {
  let obj = {};
  obj.id = clientId;
  return obj;
}

export function extractClientIdFromLocation(location) {
    const splitList = location.split("/");
    return splitList[3];    
}

export function validateRiskInsightForm(values) {
  //Custom schema to validate the form.
  let riskInsightData = [];
  values?.riskInsightData.forEach((value) => {
    const errorObject = {};
    if (isFalsy(value.parameter)) {
      errorObject["parameter"] = "Please select parameter";
    }
    if (isFalsy(value.comparison)) {
      errorObject["comparison"] = "Please select comparison";
    }
    if (isFalsy(value.value)) {
      errorObject["value"] = "Please enter value";
    }
    riskInsightData.push(errorObject);
  });
  const errors = { riskInsightData };
  return errors;
}