import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import CustomDialog from "../../utils/CustomDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { dataGrid, ellipsis, headerTooltip, hoverCursorPointer, textColor } from "../Styles";
import merge from 'lodash/merge';
import { BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS, BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS_TITLE, FILE_TYPE } from "../Constants";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from "moment-timezone";

export const FileStatus = (props) => {
	const { files } = props;
	const [open, setOpen] = useState(false);
	const [error, setError] = useState();

	const handleDialogClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const tableRowStyles = merge(
		dataGrid.base["& .RaDatagrid-rowCell"],
		{ backgroundColor: "unset" },
	)
	
	return (
		<>
			<Typography paragraph={true} sx={textColor.gray}>
				Uploaded files
			</Typography>
			<Grid>
				<TableContainer xs={12} component={Paper} sx={dataGrid.base["& .RaDatagrid-tableWrapper"]}>
					<Table sx={dataGrid.base["& .RaDatagrid-table"]}>
						<TableHead>
							<TableRow>
								<TableCell sx={dataGrid.base["& .RaDatagrid-headerCell"]}>NAME</TableCell>
								<TableCell sx={dataGrid.base["& .RaDatagrid-headerCell"]}>STATUS</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{files
							?.sort((a,b) => {
								const dateA = moment(a.created, "YYYY-MM-DD HH:mm:ss");
								const dateB = moment(b.created, "YYYY-MM-DD HH:mm:ss");
								return dateB - dateA;
							})
							?.map((item, index) => (
								<TableRow 
									key={item.id}
									hover={true}
									onClick={() => {
										if(Array.isArray(item.status) && !isEmpty(item.status)){
											handleOpen();
											setError(item.status);
										}
									}}
									sx={hoverCursorPointer}																		
								>
									<TableCell 
										sx={{
											...tableRowStyles,
											...ellipsis,
											maxWidth: '300px',
										}}
									>
										<Tooltip
											disableInteractive
											placement="top-start"
											title={<div style={headerTooltip}>{item.title}</div>}
										>
											{item.title}
										</Tooltip>										
									</TableCell>
									<TableCell sx={tableRowStyles}>
										{ item.type === FILE_TYPE.CSV && <FileStatusIconForCSV item={item} />}
										{ item.type === FILE_TYPE.PDF && <FileStatusIconForBankStatements status={item.status} />}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<CustomDialog
				openDialog={open}
				closeDialog={handleDialogClose}
				title={"Errors"}
				cancelBtnTitle={"Close"}
				content={
					<TableContainer component={Paper} sx={dataGrid.base["& .RaDatagrid-tableWrapper"]}>
						<Table sx={dataGrid.base["& .RaDatagrid-table"]}>
							<TableHead>
								<TableRow>
									<TableCell sx={dataGrid.base["& .RaDatagrid-headerCell"]}>Index</TableCell>
									<TableCell sx={dataGrid.base["& .RaDatagrid-headerCell"]}>Field</TableCell>
									<TableCell sx={dataGrid.base["& .RaDatagrid-headerCell"]}>Message</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{error?.map((item) => (
									<TableRow key={item.id}>
										<TableCell sx={dataGrid.base["& .RaDatagrid-rowCell"]}>{item.index}</TableCell>
										<TableCell sx={dataGrid.base["& .RaDatagrid-rowCell"]}>{item.fieldName}</TableCell>
										<TableCell sx={dataGrid.base["& .RaDatagrid-rowCell"]}>{item.message}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				}
				hideSubmitBtn={true}
			/>
		</>
	);
};

const FileStatusIconForBankStatements = ({ status }) => {
	
	let icon;
	switch(status){
		case BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.IN_PROGRESS:
			icon = <CircularProgress size={24}/>;
			break;
		case BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.COMPLETED_WITH_WARNINGS:
			icon = <ErrorOutlineIcon style={textColor.goldenYellow}/>;
			break;
		case BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.FAILED:
			icon = <CancelIcon style={textColor.error} />;
			break;
		case BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.COMPLETED:			
			icon = <CheckCircleIcon style={textColor.success} />;
			break;
		case BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.TIMEOUT:
			icon = <TimerOffIcon style={textColor.gray}/>;
			break;
		default :			
			icon = <CancelIcon style={textColor.error} />;
			break;
	}
	return (
		<Tooltip
			disableInteractive
			placement="right"
			title={BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS_TITLE[status]}
		>
			{icon}
		</Tooltip>
		);
}

const FileStatusIconForCSV = ({ item }) => {
	return(isUndefined(item.status)
	? (
		<Tooltip
			disableInteractive
			placement="right"
			title="In progress"
		>
			<CircularProgress size={24}/>
		</Tooltip>										
	) : (
		isEmpty(item.status) 
		?
			<Tooltip
				disableInteractive
				placement="right"
				title="Completed"
			>
				<CheckCircleIcon style={textColor.success} />		
			</Tooltip>																							
		: (item?.status === "ERROR")
			?		
				<Tooltip
					disableInteractive
					placement="right"
					title="Something went wrong while uploading file"
				>
					<RemoveCircleIcon style={textColor.error} />
				</Tooltip>																						
			:
			<Tooltip
				disableInteractive
				placement="right"
				title={item?.status.length + " Issues"}
			>
				<CancelIcon style={textColor.error} />
			</Tooltip>																												
));
}