import * as React from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
  ShowButton,
  useNotify,
  useRefresh,
} from 'react-admin'
import {ActionTitles, RESOURCES_BASEPATH} from '../Constants'
import CustomTooltip from "../../utils/CustomTooltip";
import { CustomPagination } from "../common/CustomPaginationComponent";
import { cellContentCentered, dataGrid, iconAction, listRoot } from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import CustomDeleteWithConfirmButton from '../../utils/CustomDeleteWithConfirmButton'
import ListActions from '../common/ListActions'

export const DefaultContent = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableDelete, setDisableDelete] = React.useState(false);

  const onDeleteSuccess = () => {
    notify('Content Deleted', { type: "info", autoHideDuration: 1000 });
    refresh();
  };

  const onDeleteFailure = () => {
    setDisableDelete(false);
    notify("Unable to delete the default content", { type: 'warning', autoHideDuration: 1000 });
    refresh();
  };

  return (
    <List
      sx={listRoot}
      exporter={false}
      sort={{ field: "created", order: "DESC" }}
      actions={
        <ListActions
          title={ActionTitles.DEFAULT_CONTENT}
          renderButtonAndSearch={true}
          showCreateButton={true}
          basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
        />
      }
      pagination={<CustomPagination />}    
    >
      <Datagrid
        sx={defaultsDeep(cloneDeep(dataGrid.base), cloneDeep(dataGrid.last3ColsActions))}
        bulkActionButtons={false}
      >
        <TextField source="contentType" label="TYPE" sortable={false} />
        <FunctionField
          sx={cellContentCentered}
          source="View"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="View"
              placement="top"
            >
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={false}
                basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
                record={record}
              />
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Edit"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="Edit"
              placement="top"
            >
              <EditButton
                icon={<EditOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={false}
                basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
                record={record}
              />
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Delete"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="Delete"
              placement="top"
            >
              <CustomDeleteWithConfirmButton
                icon={<DeleteOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={false}
                record={record}
                recordName="Default content"
                onSuccess={onDeleteSuccess}
                mutationMode="pessimistic"
                onFailure={onDeleteFailure}
                disableDelete={disableDelete}
                setDisableDelete={setDisableDelete}
              />
            </CustomTooltip>
          )}
        />
      </Datagrid>
    </List>
  );
}
