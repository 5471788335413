import { SimpleForm } from "react-admin";
import InputLabel from '@mui/material/InputLabel';
import CustomCheckbox from "../../utils/CustomCheckbox";
import {Grid} from '@mui/material'
import {ellipsis} from '../Styles'
import Tooltip from '@mui/material/Tooltip'

const ReportAndFileSelector = (props) => {
  const {
    reportFormat,
    reportFilename,
    supplementaryFiles,
    onReportChange,
    onFileChange,
    riskInsightReportFilename,
    onRiskInsightReportChange,
  } = props;

  return (
    <SimpleForm toolbar={null}>
      <Grid container alignItems="center" flexWrap="nowrap">
        <Grid item>
          <CustomCheckbox
            defaultChecked
            onChange={onReportChange}
            id="report"
            value={reportFormat}
          />
        </Grid>
        <Grid item sx={ellipsis}>
          <Tooltip title={reportFilename} placement="top">
            <InputLabel>{reportFilename}</InputLabel>
          </Tooltip>
        </Grid>
      </Grid>
      {
        (riskInsightReportFilename !== "") &&
          <Grid container alignItems="center" flexWrap="nowrap">
            <Grid item>
              <CustomCheckbox
                defaultChecked
                onChange={onRiskInsightReportChange}
                id="riskInsightReport"
                value={riskInsightReportFilename}
              />
            </Grid>
            <Grid item sx={ellipsis}>
              <Tooltip title={riskInsightReportFilename} placement="top">
                <InputLabel>{riskInsightReportFilename}</InputLabel>
              </Tooltip>
            </Grid>
          </Grid>
      }
      {
        supplementaryFiles.map(file =>
        <Grid container alignItems="center" flexWrap="nowrap">
          <Grid item>
            <CustomCheckbox
              defaultChecked
              onChange={(event) => onFileChange(event, file.fileName)}
              id={file.fileName}
              value={file.fileDownloadUri}
            />
          </Grid>
          <Grid item sx={ellipsis}>
            <Tooltip title={file.fileName} placement="top">
              <InputLabel>{file.fileName}</InputLabel>
            </Tooltip>
          </Grid>
        </Grid>)
      }
    </SimpleForm>
  );
}

export default ReportAndFileSelector;
