import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import keycloak from "./keycloak";
import { isAdminUser } from "./components/DataProvider";
import { getClientDetails, getClientDefaults, getReportTemplates} from "./utils/LoginUtils";

keycloak
  .init({ onLoad: "login-required", promiseType: "native" })
  .then(async (authenticated) => {
    localStorage.setItem("auth", "Bearer ".concat(keycloak.token));
    localStorage.setItem(
      "userRoles",
      JSON.parse(atob(keycloak.token.split(".")[1])).realm_access.roles //finding user_roles from token
    );
    localStorage.setItem("userEmail", keycloak.idTokenParsed.email);
    localStorage.setItem("loggedInUserId", keycloak.subject.substring(keycloak.subject.lastIndexOf(':') + 1)) //storing loggedIn userId
    if (!isAdminUser()) {
      await getClientDetails();
      await getClientDefaults();
      await getReportTemplates();
    } else { // we always want to show scheduling tabs for admins
      localStorage.setItem('reportScheduling', 'true');
    }    
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

reportWebVitals();
