import * as React from "react";
import {
  Loading,
  useNotify,
  SimpleForm,
  AutocompleteArrayInput,
  useDataProvider,
  Button,
} from 'react-admin'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CustomDialog from "../../utils/CustomDialog";
import { useFormContext } from 'react-hook-form';
import { parseTemplateListToObject, parseTemplateObjectToList } from '../../mappers/Template'
import isEqual from "lodash/isEqual"
import isEmpty from "lodash/isEmpty"
import "../../styles/auto-complete.css";
import {EVENT_REASON} from '../Constants'
import {
  accordion,
  accordionActionButton,
  autoCompleteField,
  iconAction,
  padding0,
  padding2,
  textColor,
} from '../Styles'
import CustomAccordian from '../../utils/CustomAccordian'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const TemplateAccordian = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [mappedTemplates, setMappedTemplates] = React.useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    fetchMappedTemplates(props.clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.clientId]);

  const fetchMappedTemplates = (clientId) => {
    if(!isEmpty(clientId)) {
      dataProvider
        .getMappedTemplates({id: clientId})
        .then(({ data }) => {
          setMappedTemplates(data);
          setLoading(false);
        })
        .catch((error) => {
          notify('Something went wrong while fetching mapped templates.', { type: 'warning', autoHideDuration: 1000 });
          setLoading(false);
        });
    }
  }

  const deleteTemplateMapping = (templateId, clientId) => {
    dataProvider
      .unMapTemplate({id: clientId, templateId: templateId})
      .then(({ json }) => {
        const newTemplateList = mappedTemplates.filter(t => t.templateId !== templateId);
        notify('Template was removed successfully for this client', { type: 'info', autoHideDuration: 1000 });
        setMappedTemplates(newTemplateList);
      })
      .catch((error) => {
        notify('Something went wrong while removing the template', { type: 'warning', autoHideDuration: 1000 });
      });
  }

  return(
    <ListItem sx={padding2.y} key="templateContainer">
      <Grid container>
        <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: props.isShow ? "10px" : "18px" } }}>Templates</Grid>
        <Grid item xs={12} md={props.isShow ? 6 : 5} lg={6}>
          <CustomAccordian
            title={"Client Templates"}
            isShow={props.isShow}
          >
            {loading && <Loading />}
            {!loading && (
              <List sx={padding0.y}>
                {mappedTemplates.map((template) => {
                  return(
                    <ListItem sx={accordion.listItem} key={template.templateId}>
                      <ListItemText primary={template.templateName} disableTypography />
                      {!props.isShow &&
                        <Button
                          sx={iconAction}
                          size="medium"
                          onClick={() => deleteTemplateMapping(template.templateId,props.clientId)}
                        >
                          <DeleteOutlinedIcon/>
                        </Button>
                      }
                    </ListItem>
                  )
                })}
              </List>
            )}
          </CustomAccordian>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          {!props.isShow &&
            <TemplateDialog
              preSelectedChoices={mappedTemplates}
              clientId={props.clientId}
              setMappedTemplates={setMappedTemplates}
              isEdit={props.isEdit}
            />
          }
        </Grid>
      </Grid>

    </ListItem>
  );
}

export const TemplateDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [allTemplates, setAllTemplates] = React.useState([]);
  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const notify = useNotify();
  const form = useFormContext();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleClick = () => {
    setOpen(true);
    // eslint-disable-next-line no-lone-blocks
    { props.isEdit && setSelectedTemplates(parseTemplateObjectToList(props.preSelectedChoices)); }                  //When the dialog closes, the input component resets to its default value or empty, so we need to get back to the previous state when Dialog closed.
  }

  const handleDialogClose = (event, reason) => {
    if(reason !== EVENT_REASON.BACKDROP_CLICK){
      setOpen(false)
    }
  };

  const fetchTemplates = () => {
    dataProvider
      .getAllTemplates()
      .then(({ data }) => {
        setAllTemplates(data);
      })
      .catch((error) => {
        notify('Something went wrong while fetching the templates', { type: 'warning', autoHideDuration: 1000 });
      })
  }

  const handleSubmitForEdit = () => {
    const preSelectedValues = parseTemplateObjectToList(props.preSelectedChoices);
    if( !isEqual(preSelectedValues, selectedTemplates) ){
      dataProvider
        .updateAssignmentOfTemplates({
          id: props.clientId,
          preSelectedValues:preSelectedValues,
          postSelectedValues:selectedTemplates
        })
        .then(({ data }) => {
          props.setMappedTemplates(data);
          notify('Templates Mapped.', { type: 'success', autoHideDuration: 1000 });
        })
        .catch((error) => {
          notify('Something went wrong while updating the templates of client', { type: 'warning', autoHideDuration: 1000 });
        })
    }
    setOpen(false);
  }

  const handleSubmitForCreate = () => {
    form.setValue('templates',parseTemplateListToObject(selectedTemplates));
    setOpen(false);
  }

  const getTemplateById = (templateId) => {
    const templateObject = allTemplates.find(template => templateId === template.id)
    return templateObject ? templateObject.name : null
  }

  return(
    <>
      <Grid container >
        <Grid item display={props.isEdit ? "none" : "flex"} xs={12} md={8}>
          <List sx={padding0.y}>
            {form.getValues()?.templates && form.getValues()?.templates.map((template) => {
              return(
                <ListItem sx={{...accordion.listItem, "&:first-of-type": { borderTop: 0 } }} key={template.templateId}>
                  <ListItemText primary={getTemplateById(template.templateId)} disableTypography />
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={props.isEdit ? 12 : 4}>
          <Button
            sx={{...accordionActionButton, marginTop: props.isEdit ? "15px !important" : "8px !important"}}
            label={"Add Templates"}
            size="small"
            onClick={handleClick}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Grid>
      </Grid>
      <CustomDialog
        openDialog={open}
        closeDialog={handleDialogClose}
        title="Map Templates To Client"
        content = {
          <SimpleForm
            toolbar={null}
            sx={autoCompleteField}
          >
            <AutocompleteArrayInput
              label="Templates"
              source="clientTemplates"
              choices={allTemplates}
              variant="outlined"
              fullWidth={true}
              defaultValue={props.isEdit ? parseTemplateObjectToList(props.preSelectedChoices): selectedTemplates}
              onChange={(e) => {setSelectedTemplates(e)}}
            />
          </SimpleForm>
        }
        handleSubmit={(props.isEdit) ? handleSubmitForEdit : handleSubmitForCreate}
      />
    </>
  );
}




export default TemplateAccordian;