import React from "react";
import { ClientDefaultsActions } from "./ClientDefaultsActions";
import {
  Edit,
  SimpleForm,
} from 'react-admin'
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import CustomToolbar from "../common/FormToolbar";
import {contentCenter,simpleForm} from '../Styles'
import { ClientDefaultsCreateEditGrid } from './ClientDefaultsCreateEditGrid'

export const ClientDefaultsEdit = (props) => {
  return (
    <Edit
      sx={contentCenter}
      actions={
        <ClientDefaultsActions
          title={ActionTitles.CLIENT_DEFAULTS_UPDATE}
        />
      }
      mutationMode="pessimistic"
      renderButtonAndSearch={false}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
            buttontitle2={ActionTitles.UPDATE}
            isEdit={true}
            basePath={RESOURCES_BASEPATH.CLIENT_DEFAULTS}
            resource={RESOURCES.CLIENT_DEFAULTS}
          />
        }
        mode="onBlur"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        <ClientDefaultsCreateEditGrid />
      </SimpleForm>
    </Edit>
  );
}