import * as React from "react";
import {
  SimpleShowLayout,
  Show, useRecordContext,
} from 'react-admin'
import {ActionTitles,RESOURCES,RESOURCES_BASEPATH} from '../Constants'
import {contentCenter, padding0, padding2, textColor} from '../Styles'
import {Grid} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ReactHtmlParser from 'react-html-parser'
import ListActions from '../common/ListActions'

export const DefaultContentShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.DEFAULT_CONTENT_DETAILS}
        showEditButton={true}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
        resource={RESOURCES.DEFAULT_CONTENT}
        recordName={ActionTitles.DEFAULT_CONTENT}
      />
    }
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <DefaultContentShowGrid />
    </SimpleShowLayout>
  </Show>
);

const DefaultContentShowGrid = () => {
  const record = useRecordContext();

  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid item xs={12} md={3} lg={2} sx={textColor.gray}>Type</Grid>
              <Grid item xs={12} md={9} lg={10}>
                <strong>{record["contentType"]}</strong>
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid item xs={12} md={3} lg={2} sx={textColor.gray}>Content</Grid>
              <Grid item xs={12} md={9} lg={10}>{ReactHtmlParser(record["content"])}</Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )};