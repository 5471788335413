import * as React from "react";
import {
  Menu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
} from "react-admin";
import DefaultIcon from "@mui/icons-material/ViewList";
import { DISABLE_MATTER_TOOLTIP_MESSAGE, ENABLED_FEATURES_ENUM, RESOURCES } from "../../Constants";
import { menu } from "../../Styles";
import { Grid, Tooltip } from "@mui/material";
import { isAdminUser, isFeatureEnabled } from "../../DataProvider";

const CustomMenu = () => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
  const matterDisabled = !isFeatureEnabled(ENABLED_FEATURES_ENUM.MATTER_ENABLED);
  const isAdmin = isAdminUser();

  return (
    <Menu sx={menu.container}>
      <Grid sx={menu.logoContainer}>
        <img id="menu-logo" src="whiteLogo.png" alt="Armalytix Logo" style={menu.logo} />
      </Grid>
      {resources.map((resource) =>
          resource.name !== RESOURCES.SURVEYS && (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              onClick={(e) => {                
                resource.name === RESOURCES.MATTERS && !isAdmin && matterDisabled && e.preventDefault();
              }}
              primaryText={<PrimaryTextForMenu resource={resource} isAdmin={isAdmin} matterDisabled={matterDisabled} />}
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              sx={menu.link}
              style={resource.name === RESOURCES.MATTERS && !isAdmin && matterDisabled ? { opacity : "0.38" } : {}}
            />
          )        
      )}
    </Menu>
  );
};

export const PrimaryTextForMenu = (props) => {

  const { resource, matterDisabled, isAdmin } = props;
  const [open] = useSidebarState();
  const name = (resource.options && resource.options.label) || resource.name;

  return(
    resource.name === RESOURCES.MATTERS && !isAdmin && matterDisabled ? (
      open ? (
        <Tooltip 
          placement="right" 
          title={DISABLE_MATTER_TOOLTIP_MESSAGE}
        >
          <div>
            {name}
          </div>
        </Tooltip>
      ) : (
        <div>{DISABLE_MATTER_TOOLTIP_MESSAGE}</div>
      )
    ) : (
      <div>{name}</div>
    )
  );

}

export default CustomMenu;
