import { Divider, Grid, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectInput } from "react-admin";
import { useFormContext } from 'react-hook-form';
import { padding2, textColor, width } from "../Styles";
import { createReportChoicesDynamically, requiredValidation } from "../../utils/util";

export const ReportTypeForSecondReportGrid = (props) => {
    const form = useFormContext();
    const field = props.field;
    const [reportTypeChoices, setReportTypeChoices] = useState([]);

    useEffect(() => {
        let choices = createReportChoicesDynamically(field.possibleValues);
        setReportTypeChoices(choices);

        if (field.defaultValue)
            form.setValue("reportType", field.defaultValue);
        else if (choices.length === 1)
            form.setValue("reportType", choices[0].id);

        // eslint-disable-next-line
    }, [props.selectedTemplateId, field?.possibleValues, field?.defaultValue]);

    return (
        <React.Fragment>
            <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={4} sx={textColor.gray}>
                        {field.displayName}
                        {field.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <SelectInput
                            source={field.name}
                            name={field.name}
                            variant="outlined"
                            size="small"
                            label={field.placeholder}
                            choices={reportTypeChoices}
                            defaultValue={reportTypeChoices.length === 1 ? reportTypeChoices[0].id : field.defaultValue}
                            sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                            validate={field.required && requiredValidation}
                        />
                    </Grid>
                </Grid>
            </ListItem>
            <Divider light />
        </React.Fragment>
    );
};