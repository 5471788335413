import { Divider, Grid, ListItem } from "@mui/material";
import { BooleanInput } from "ra-ui-materialui";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import {float, padding2, textColor} from '../Styles'
import * as React from 'react'

export const BooleanInputComponent = (props) => {
  const { source, title } = props;

  return (
    <>
      <ListItem sx={padding2.y} key={`${title}Input`}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>{title}</Grid>
          <Grid item xs={8}>
            <BooleanInput
              sx={{marginBottom: "-15px"}}
              label={false}
              source={source}
              defaultValue={false}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
    </>
  );
};

export const BooleanFieldShowComponent = (props) => {
  const { name, title, record } = props;

  return (
    <>
      <ListItem sx={padding2.y} key={`${title}Field`}>
        <Grid container>
          <Grid item xs={12} md={4} sx={textColor.gray}>{title}</Grid>
          <Grid item xs={8}>
            {record[name] ?
              (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
              (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
            }
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
    </>
  );
};
