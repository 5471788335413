import {Create, SimpleForm} from 'react-admin'
import {ActionTitles, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import CustomToolbar from '../common/FormToolbar'
import * as React from 'react'
import {DefaultContentCreateEditGrid} from './DefaultContentEdit'
import {contentCenter,simpleForm} from '../Styles'
import ListActions from '../common/ListActions'

export const DefaultContentCreate = () => {
  return (
    <Create
      actions={<ListActions title={ActionTitles.CREATE_DEFAULT_CONTENT} />}
      sx={contentCenter}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
            buttontitle2={ActionTitles.CREATE}
            basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
            resource={RESOURCES.DEFAULT_CONTENT}
            record
          />
        }
        sx={simpleForm}
      >
        <DefaultContentCreateEditGrid create={true} />
      </SimpleForm>
    </Create>
  );
};