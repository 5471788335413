import {AutocompleteInput,Error,Filter,Loading,useDataProvider} from 'react-admin'
import React from 'react'
import {ActionTitles} from '../Constants'
import {width} from '../Styles'

export const ClientDefaultsAdminFilter = (props) => {
  const dataProvider = useDataProvider();
  const [partnerChoices, setPartnerChoices] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  React.useEffect(() => {
    dataProvider
      .getClientNameList("admin/client/names")
      .then(({ data }) => {
        setPartnerChoices(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (<>
    <Filter {...props}>
      <AutocompleteInput
        source="clientId"
        name="clientId"
        variant="outlined"
        size="small"
        label={ActionTitles.CORPORATE_PARTNER}
        choices={partnerChoices}
        resettable
        alwaysOn
        sx={width.fixed.w300}
      />
    </Filter>
  </>);
}