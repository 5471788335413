import { ImageField } from "ra-ui-materialui";
import { isEmpty, useRecordContext } from "react-admin";
import {imageField} from '../Styles'

const PreviewImage = ({ source }) => {
  const record = useRecordContext();

  if (typeof record == "string") {    
    return (
      <img
        src={`data:image/png;base64,${record}`}
        alt=""
        style={imageField}
      />
    );
  } else if(!isEmpty(record)) {
    return (
      <img
        src={record?.src}
        alt=""
        style={imageField}
      />
    );
  }

  return <ImageField record={record} source={source} />;
};

export default PreviewImage;
