import Link from "@mui/material/Link";
import {linkButton} from '../components/Styles'

const CustomUrlField = ({ value, source, target, rel }) => (
  <Link
    href={source}
    target={target}
    rel={rel}
    sx={linkButton}
  >
      {value}
  </Link>
);

export default CustomUrlField;