import * as React from "react";
import {
    SimpleForm,
    useNotify,
    useRefresh,
    useDataProvider,
    AutocompleteInput,
} from "react-admin";
import CustomDialog from "../../utils/CustomDialog";
import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import {Button} from 'react-admin'
import {ActionTitles,EVENT_REASON,RESOURCES} from '../Constants'
import {padding0,padding2,padding3,textColor,topActionButtonBaseStyle,width} from '../Styles'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

export const UserSchedulesTransferDialog = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [userChoices,setUserChoices] = React.useState();
  const [selectedUser,setSelectedUser] = React.useState();
  const [open, setOpen] = React.useState();

  React.useEffect(() => {
      if(props.open && props.deleteUserId){
          getUsersList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.open, props.deleteUserId])

  React.useEffect(() => {
      if(props.open){                         //clearing the states.
          setSelectedUser(null);
      }
  }, [props.open, setSelectedUser])

  const handleDialogClose = (event, reason) => {
      if(reason !== EVENT_REASON.BACKDROP_CLICK){
          props.setOpen(false)
      }
  };

  function getUsersList(){
      let choices = [];
      dataProvider
        .getAllMappedUsers()
        .then(({ data }) => {
            choices = data.filter( choice => choice.id !== props.deleteUserId);
            choices.sort( (a, b) => a["name"].localeCompare(b["name"]))
            setUserChoices(choices);
        }).catch((err) => {
          notify('Something went wrong while fetching user', { type: 'error', autoHideDuration: 1000 });
      })
  }

  const handleSubmit = () => {
      setOpen(true);
      handleDialogClose();
  }

  return(
    <>
      <ConfirmUserDeleteDialog
        open={open}
        setOpen={setOpen}
        deleteUserId={props.deleteUserId}
        transferUserId={selectedUser}
      />
      <CustomDialog
        openDialog={props.open}
        closeDialog={handleDialogClose}
        title="Transfer open requests"
        submitBtnTitle="Delete"
        submitBtnIcon={<DeleteOutlinedIcon />}
        handleSubmit={handleSubmit}
        content = {
          <SimpleForm
            toolbar={null}
          >
            <Grid container>
              <Grid item xs={12} sx={textColor.primary}>
                Who should we transfer any live requests to?
              </Grid>
              <Grid item xs={12}>
                <AutocompleteInput
                  source="transferSchedules"
                  name="transferSchedules"
                  variant="outlined"
                  size="small"
                  label="Choose an option"
                  choices={userChoices}
                  fullWidth={true}
                  onChange={(e) => {
                      setSelectedUser(e);
                  }}
                  sx={width.fluid.w100}
                />
              </Grid>
            </Grid>
          </SimpleForm>
        }
        invalid={!selectedUser} //To disable deletion of user without transfer user selected.
      />
    </>
  );
}

const ConfirmUserDeleteDialog = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDialogClose = () => {
    props.setOpen(false);
  }

  const handleDelete = () => {
    transferSchedulesDeleteUser(props.deleteUserId, props.transferUserId);
    handleDialogClose();
  }

  const transferSchedulesDeleteUser = (userId, transferUserId) => {
    dataProvider
      .delete( RESOURCES.USERS, { id : userId, transferUserId : transferUserId})
      .then(({ json }) => {
        notify('User deleted successfully.', { type: 'info', autoHideDuration: 1000 });
        refresh();
      })
      .catch((error) => {
        notify('Something went wrong while deleting user.', { type: 'error', autoHideDuration: 1000 });
      });
  }

  return(
    <React.Fragment>
      <Dialog open={props.open} onClose={handleDialogClose} fullWidth={true}>
        <DialogContent>
          <p>Are you sure you want to delete this User?</p>
          <p style={textColor.primary}>This action cannot be undone.</p>
        </DialogContent>
        <DialogActions sx={{...padding0.top, ...padding2.bottom, ...padding3.x}}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handleDialogClose}
                sx={{...topActionButtonBaseStyle("gray","#fff","#eaeaea","#b2afaf","#9B9999")}}
                size="small"
                variant="outlined"
                label={ActionTitles.CANCEL}
              >
                <CancelIcon/>
              </Button>
              <Button
                variant="contained"
                onClick={handleDelete}
                sx={{...topActionButtonBaseStyle()}}
                size="small"
                label={ActionTitles.DELETE}
              >
                <DeleteOutlinedIcon/>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}