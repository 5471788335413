import { isAdminUser } from "../components/DataProvider";
import { getDateComponent, getParsedDate, parseDateForExport } from "../utils/util";
import isUndefined from "lodash/isUndefined";
import { FILE_TYPE } from "../components/Constants";

export function parseMattersData(data) {
    data.id = isAdminUser() ? 'client/'+ data.clientId + '/matter/' + data.matterId : data.matterId;
    data.lastModified = getParsedDate(data.lastModified);
    data.accountsCount = data.summary?.accountsCount - data.summary?.accountsWithIntegrityIssuesCount;
    data.reportsCount = data.summary?.reportsCount;
    data.accountsWithIntegrityIssuesCount = data.summary?.accountsWithIntegrityIssuesCount;
    delete data.summary;
    return data;
}

export function parseMatterAccountDataForList(data, matterId) {

    const accounts = 
    data.map((element) => {
        element.id = element.accountId;
        element.matterId = matterId;
        element.dateFrom = getDateComponent(element.dateFrom);
        element.dateTo = getDateComponent(element.dateTo);
        element.lastModified = parseDateForExport(element.lastModified);
        return element;
    });

    return accounts.sort((a,b) => new Date(b.lastModified) - new Date(a.lastModified));
}

export function parseMatterDataForShow(data) {
    data.id = isAdminUser() ? 'client/'+ data.clientId + '/matter/' + data.matterId : data.matterId;
    data.lastModified = getParsedDate(data.lastModified);
    return data;
}

export function parseMatterDataForUpdate(data) {
    delete data.id;
    delete data.created;
    delete data.lastModified;
    return JSON.stringify(data);
}

export function parseMatterDataForCreate(data) {
    return JSON.stringify(data);
}

export function parseUserNameChoicesForClients(data) {
    const reqUserNameList = [];

    let id = localStorage.getItem('loggedInUserId');
    reqUserNameList.push({
        id: id,
        name: "My Matters"
    });

    for (const [key, value] of [...data.entries()].sort((e1, e2) => e1[1].localeCompare(e2[1]))) {
        reqUserNameList.push({
            id: key,
            name: value
        });
    }
    return reqUserNameList;
}

export function parseBankData(data) {
    let map = new Map();

    data.forEach(bank => {
        map.set(bank.bankId, bank.bankName);
    });

    return map;
}

export function prepareQueryParams(params) {
    const { accountName, accountNumber, bankName, sortCode, currency } = params;
    const query = new URLSearchParams();

    if (!isUndefined(accountName)) {
        query.append("accountName", accountName);
    }
    if (!isUndefined(accountNumber)) {
        query.append("accountNumber", accountNumber);
    }
    if (!isUndefined(bankName)) {
        query.append("bankName", bankName);
    }
    if (!isUndefined(sortCode)) {
        query.append("sortCode", sortCode);
    }
    if (!isUndefined(currency)) {
        query.append("ccy", currency);
    }

    return `${query.toString()}`;
}

export function parseDatatoShowAccounts(data) {
    data.id = data['accountId'];
    data.dateFrom = data.dateFrom ? getParsedDate(data.dateFrom) : "--";
    data.dateTo = data.dateTo ? getParsedDate(data.dateTo) : "--";
    data.lastModified = data.lastModified ? getParsedDate(data.lastModified) : "--";

    return data;
}

export function parseMatterTransactions(data) {
    data.map((element) => {
        element.id = element.transactionId;
        element.bookingDateTime = element.bookingDateTime ? getParsedDate(element.bookingDateTime) : "";
        return element;
    });

    return data;
}

export function parseCsvUploadResults(id, json) {
    const data = {};
    data['id'] = id;
    data['status'] = json?.data?.errors;

    return data;
}

export function parsePdfUploadResults(data) {    
    return {
        title : data?.files.map(file => file.fileName).join(", "),
        jobId : data.jobId,
        status : data.bankStatementJobImportWorkflowStatus,
        type : FILE_TYPE.PDF,
        created: getParsedDate(data.created),
        retryCount: 0,                         //this field will be later used for polling
    }
}

export function parseBankStatementJobImportStatus(data) {
    return data?.map(job => ({
        title : job?.files.map(file => file.fileName).join(", "),
        jobId : job.jobId,
        status : job.bankStatementJobImportWorkflowStatus,
        type : FILE_TYPE.PDF,
        created: getParsedDate(job.created),
    }));
}