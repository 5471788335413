const armalytixFont = "'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
  "  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
  "  sans-serif"

// LAYOUT
export const layout = {
  width: "auto",
  minWidth: "auto",
  [`& .RaLayout-content`]: {
    padding: {
      xs: "12px 20px 50px",
      md: "12px 20px 30px",
    },
    overflow: "hidden",

    "& .create-page, & .edit-page, & .show-page": {
      padding: "0px 1px 3px 1px"
    }
  }
}

export const sidebar = {
  "& .RaSidebar-fixed": {
    background: "linear-gradient(to right, #383848 0%, #211F30 100%)",
    position: "unset",
  },
  "&.RaSidebar-docked": {
    height: "auto",
  },
  "& .RaSidebar-paperAnchorLeft": {
    background: "linear-gradient(to right, #383848 0%, #211F30 100%)"
  }
}

export const menu = {
  container : {
    paddingLeft: "15px",
    paddingRight: "15px",
    position: "fixed",
    top: 0,
    "&.RaMenu-open": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "&.RaMenu-closed": {
      paddingLeft: "0px",
      paddingRight: "0px",
      "& .MuiMenuItem-root": {
        paddingLeft: "12px"
      },
      "& #menu-logo": {
        marginLeft: "13px"
      }
    }
  },
  logoContainer: {
    height: "44px",
    textAlign: "center"
  },
  logo: {
    maxWidth: "164px",
  },
  link: {
    color: "white",
    minHeight: "auto",
    opacity: 0.7,
    margin: "5px",
    "&:hover": {
      opacity: 0.85,
    },
    "&.RaMenuItemLink-active": {
      color: "white",
      opacity: 1,
      backgroundColor: "rgba(255,255,255,0.15)"
    }
  },
}

export const appbar = {
  bg: {
    backgroundColor: "white",
  },
  logo: {
    link: {
      lineHeight: 0,
    },
    img: {
      maxWidth: "200px",
      maxHeight: "44px",
      padding: "4px",
      marginRight: "25px"
    }
  }
};

export const baseButtonStyle = (color = 'white', backgroundColor = '#93002c', backgroundColorHover = '#730023', borderColor, borderColorHover) => {
  borderColor = borderColor ? borderColor : backgroundColor
  borderColorHover = borderColorHover ? borderColorHover : backgroundColorHover
  return {
    width: "100%",
    fontWeight: "bold",
    color,
    backgroundColor,
    margin: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "4px",
    border: "2px solid " + borderColor,
    "&:hover": {
      backgroundColor: backgroundColorHover,
      border: "2px solid " + borderColorHover,
    },
    "&.Mui-disabled, &:disabled": {
      color,
      backgroundColor,
      opacity: 0.6
    }
  }
}

export const baseDarkButtonStyle = (color = 'white', backgroundColor = '#383848', backgroundColorHover = '#181822', borderColor, borderColorHover) => {
  return baseButtonStyle(color, backgroundColor, backgroundColorHover, borderColor, borderColorHover)
}

export const topActionButtonBaseStyle = (color = 'white', backgroundColor = '#93002c', backgroundColorHover = '#730023', borderColor, borderColorHover) => {
  borderColor = borderColor ? borderColor : backgroundColor
  borderColorHover = borderColorHover ? borderColorHover : backgroundColorHover
  return {
    position: "relative !important",
    right: "auto !important",
    bottom: "auto !important",
    zIndex: "auto !important",
    fontFamily : armalytixFont,
    fontSize: {
      xs: 0,
    },
    fontWeight: "bold",
    lineHeight: "1.5",
    color,
    backgroundColor,
    margin: "0 5px !important",
    padding : "4px 10px",
    border: "2px solid " + borderColor,
    borderRadius: "6px",
    textTransform: "uppercase",
    boxShadow: "none",
    width: "auto !important",
    height: "auto !important",
    minWidth: "auto !important",
    minHeight: "auto !important",
    "&:hover": {
      backgroundColor: backgroundColorHover,
      border: "2px solid " + borderColorHover,
    },
    "& svg": {
      fontSize: "20px !important",
      marginRight: {
        xs: "0 !important",
      }
    },
    "& .MuiButton-startIcon": {
      marginLeft: {
        xs: 0,
        sm: 0,
        md: "-2px"
      },
      marginRight: {
        xs: 0,
        sm: 0,
        md: "8px"
      }
    },
    "& .MuiButton-endIcon": {
      marginLeft: {
        xs: 0,
        sm: 0,
        md: "8px"
      },
      marginRight: {
        xs: 0,
        sm: 0,
        md: "-2px"
      }
    },
    "&.MuiButton-sizeSmall": {
      fontSize: {
        md: "0.8125rem",
      },
    },
    "&.MuiButton-sizeMedium": {
      padding : "6px 10px",
      lineHeight: "1.75",
      "& svg": {
        fontSize: "26px !important",
      }
    },
    "&.Mui-disabled, &:disabled": {
      color,
      backgroundColor,
      opacity: 0.6
    }

  }
}

export const filterButton = {
  "& .add-filter": {
    ...topActionButtonBaseStyle()
  }
};

export const topActionButtonsRow = {
  marginLeft: "-5px",
  marginRight: "-5px",
}

export const bottomActionButtonsRow = {
  position: "relative !important",
  padding: "0 16px 16px !important",
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexDirection: "row",
  "& a,button": {
    "&:first-of-type": {
      marginLeft: 0
    },
    "&:last-of-type": {
      marginRight: 0
    },
  },
}

export const linkButton = {
  color: "#383848",
  width: "fit-content",
  justifyContent: "flex-start",
  textDecoration: "none",
  textTransform: "none",
  paddingLeft: "inherit",
  "&:hover": {
    color: "#181822",
    textDecoration: "underline",
    backgroundColor: "transparent",
  },
}

export const pageTitle = {
  fontSize: "25px",
  marginBottom: "12px",
}

export const textColor = {
  white: {
    color: "white",
  },
  black: {
    color: "black",
  },
  gray: {
    color: "gray",
  },
  green: {
    color: "green",
  },
  red: {
    color: "red",
  },
  primary: {
    color: "#93002c",
  },
  secondary: {
    color: "#383848",
  },
  success: {
    color: "#4CAF50",
  },
  error: {
    color: "#F44336",
  },
  goldenYellow: {
    color: "#DAA520",
  }
}

export const textDecoration = {
  none: {
    textDecoration: "none"
  }
}

export const listRoot = {
  "& .RaList-main": {
    overflow: "hidden",
    padding: "0px 1px 3px 1px"
  },
  "& .RaList-content": {
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important"
  },
  "& .RaList-actions": {
    backgroundColor: "transparent"
  },
  "& .RaFilter-form": {
    alignItems: "center"
  },
}

const nthLastChild = {
  width : "5%",
  padding: {
    xs: "0",
    md: "12px 0",
  }
}

export const dataGrid = {
  savingSpinner: {
    position: "absolute",
    zIndex: 1000,

    inner: {
      backgroundColor: "white",
      borderRadius: "3px",
      border: "1px solid grey",
      padding: "5px"
    }
  },
  base: {
    "& .RaDatagrid-tableWrapper" : {
      overflowX: "scroll"
    },
    "& .RaDatagrid-table":{
      borderCollapse: "separate",
    },
    "& .RaDatagrid-headerCell": {
      backgroundColor: "#e2e2e2",
      color: "black",
      fontSize: {
        xs: "11px",
        md: ".75rem",
      },
      lineHeight: {
        xs: "1.2rem",
        md: "1.5rem",
      },
      padding: {
        xs: "2px 10px",
        md: "6px 16px",
      },
      fontWeight: "700",
      letterSpacing: "0.05em",
      fontFamily: "Gilroy, sans-serif",
      boxShadow: "0px 3px 2px 0px rgba(0,0,0,0.2)",
      "& svg": {
        fontSize: {
          xs: "1rem",
          md: "1.5rem",
        },
      }
    },
    "& .RaDatagrid-rowCell": {
      whiteSpace: "nowrap",
      fontWeight: "300",
      height: {
        xs: "50px",
        sm: "58px",
        md: "62px",
      },
      padding: "0 10px",
      color: "#3c4b5f",
      backgroundColor: "white",
      "& span": {
        fontWeight: "300",
        fontFamily : armalytixFont,
      },
      "& span, & button, & a": {
        fontSize: {
          xs: "11px",
          md: "0.875rem",
        },
      },
      "& :not(a, a span, button, button span) > svg": {
        fontSize: {
          xs: "1rem",
          md: "1.5rem",
        },
      },
      "&.column-id": {
        maxWidth: "12vw",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        cursor: "pointer"
      },
    },
  },
  largeHeight: {
    "& .RaDatagrid-rowCell": {
      height: "85px",
    }
  },
  firstColSticky: {
    "& .RaDatagrid-headerCell": {
      "&:first-of-type": {
        position: {
          md: "sticky"
        },
        left: {
          md: 0
        },
        zIndex: {
          md: 3
        },
        boxShadow: {
          md: "0px 3px 2px 0px rgba(0,0,0,0.2), inset -5px 0px 4px -3px rgba(0,0,0,0.15)"
        },
      },
    },
    "& .RaDatagrid-rowCell": {
      "&:first-of-type": {
        position: {
          md: "sticky"
        },
        left: {
          md: 0
        },
        zIndex: {
          md: 2
        },
        boxShadow: {
          md: "inset -5px 0px 4px -3px rgba(0,0,0,0.15)"
        },
      },
    },
  },
  lastColActions: {
    "& .RaDatagrid-headerCell": {
      "&:nth-last-of-type(-n + 1)": nthLastChild,
    },
    "& .RaDatagrid-rowCell": {
      "&:nth-last-of-type(-n + 1)": nthLastChild,
    },
  },
  last2ColsActions: {
    "& .RaDatagrid-headerCell": {
      "&:nth-last-of-type(-n + 2)": nthLastChild,
    },
    "& .RaDatagrid-rowCell": {
      "&:nth-last-of-type(-n + 2)": nthLastChild,
    },
  },
  last3ColsActions: {
    "& .RaDatagrid-headerCell": {
      "&:nth-last-of-type(-n + 3)": nthLastChild,
    },
    "& .RaDatagrid-rowCell": {
      "&:nth-last-of-type(-n + 3)": nthLastChild,
    },
  },
  last4ColsActions: {
    "& .RaDatagrid-headerCell": {
      "&:nth-last-of-type(-n + 4)": nthLastChild,
    },
    "& .RaDatagrid-rowCell": {
      "&:nth-last-of-type(-n + 4)": nthLastChild,
    },
  },
  pagination: {
    backgroundColor: "white",
    borderRadius : "0px 0px 6px 6px",
    zIndex: 3,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  marginTop: {
    marginTop : "25px",
  },
  baseHeader: {
    "& .RaDatagrid-tableWrapper" : {
      overflowX: "scroll"
    },
    "& .RaDatagrid-table":{
      borderCollapse: "separate",
    },
    "& .RaDatagrid-headerCell": {
      backgroundColor: "#e2e2e2",
      color: "black",
      fontSize: {
        xs: "11px",
        md: ".75rem",
      },
      lineHeight: {
        xs: "1.2rem",
        md: "1.5rem",
      },
      padding: {
        xs: "2px 10px",
        md: "6px 16px",
      },
      fontWeight: "700",
      letterSpacing: "0.05em",
      fontFamily: "Gilroy, sans-serif",
      boxShadow: "0px 3px 2px 0px rgba(0,0,0,0.2)",
      "& svg": {
        fontSize: {
          xs: "1rem",
          md: "1.5rem",
        },
      }
    },
  },
  bulkActionsToolbar: {
    "& .RaBulkActionsToolbar-toolbar":{
      backgroundColor: "#e2e2e2 !important",
      color : "black !important",
    }
  },
}

export const simpleFormIteratorHalf = {
  "& .RaSimpleFormIterator-line": {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    display: "flex !important",
    alignItems: "center",
  },
}

export const simpleFormIteratorAdd = {
  "& .RaSimpleFormIterator-add": {
    width: "100%",
  },
}

export const hideHelper = {
  "& .MuiFormHelperText-root": {
    display: "none"
  }
}

export const simpleFormIterator = {
  "& .RaSimpleFormIterator-line": {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex !important",
    alignItems: "center",
  },
  "& .RaSimpleFormIterator-form": {
    flexGrow: 1,
  },
  "& .RaSimpleFormIterator-action": {
    visibility: "visible",
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row"
    },
    "& .button-reorder": {
      display: "flex",
      "& button:not(.Mui-disabled)": {
        color: "#383848",
        "&:hover": {
          color: "#181822"
        }
      }
    }
  },
}

export const actionsDatagridHeader = {
  cursor: "pointer",
  "&:hover": {
    color: "#555",
  },
}

export const contentCenter = {
  "& .RaShow-main, .RaCreate-main, .RaEdit-main": {
    justifyContent: "center"
  },
  "& .RaShow-card, .RaCreate-card, .RaEdit-card": {
    maxWidth: "1200px",
  }
}

export const removeBoxShadow = {
  "& .RaCreate-main > .MuiPaper-root": {
    boxShadow: "none",
  },
}

export const simpleForm = {
  paddingBottom: "1rem !important"
}

export const display = {
  none: { display: "none" },
  flex: { display: "flex" },
  inlineFlex: { display: "inline-flex" },
  block: { display: "block" },
  inlineBlock: { display: "inline-block" },
}

export const margin0 = {
  default: { margin: 0 },
  x: { marginLeft: 0, marginRight: 0 },
  y: { marginTop: 0, marginBottom: 0 },
  top: { marginTop: 0 },
  bottom: { marginBottom: 0 },
  left: { marginLeft: 0 },
  right: { marginRight: 0 },
}

export const margin1 = {
  default: { margin: "0.5rem" },
  x: { marginLeft: "0.5rem", marginRight: "0.5rem" },
  y: { marginTop: "0.5rem", marginBottom: "0.5rem" },
  top: { marginTop: "0.5rem" },
  bottom: { marginBottom: "0.5rem" },
  left: { marginLeft: "0.5rem" },
  right: { marginRight: "0.5rem" },
}

export const margin2 = {
  default: { margin: "1rem" },
  x: { marginLeft: "1rem", marginRight: "1rem" },
  y: { marginTop: "1rem", marginBottom: "1rem" },
  top: { marginTop: "1rem" },
  bottom: { marginBottom: "1rem" },
  left: { marginLeft: "1rem" },
  right: { marginRight: "1rem" },
}

export const padding0 = {
  padding: 0,
  x: { paddingLeft: 0, paddingRight: 0 },
  y: { paddingTop: 0, paddingBottom: 0 },
  top: { paddingTop: 0 },
  bottom: { paddingBottom: 0 },
  left: { paddingLeft: 0 },
  right: { paddingRight: 0 },
}

export const padding1 = {
  padding: "0.5rem",
  x: { paddingLeft: "0.5rem", paddingRight: "0.5rem" },
  y: { paddingTop: "0.5rem", paddingBottom: "0.5rem" },
  top: { paddingTop: "0.5rem" },
  bottom: { paddingBottom: "0.5rem" },
  left: { paddingLeft: "0.5rem" },
  right: { paddingRight: "0.5rem" },
}

export const padding2 = {
  padding: "1rem",
  x: { paddingLeft: "1rem", paddingRight: "1rem" },
  y: { paddingTop: "1rem", paddingBottom: "1rem" },
  top: { paddingTop: "1rem" },
  bottom: { paddingBottom: "1rem" },
  left: { paddingLeft: "1rem" },
  right: { paddingRight: "1rem" },
}

export const padding3 = {
  padding: "1.5rem",
  x: { paddingLeft: "1.5rem", paddingRight: "1.5rem" },
  y: { paddingTop: "1.5rem", paddingBottom: "1.5rem" },
  top: { paddingTop: "1.5rem" },
  bottom: { paddingBottom: "1.5rem" },
  left: { paddingLeft: "1.5rem" },
  right: { paddingRight: "1.5rem" },
}

export const float = {
  left: { float: "left" },
  right: { float: "right" },
}

export const whiteSpace = {
  noWrap: {
    whiteSpace: "nowrap"
  }
}

export const iconAction = {
  cursor: "pointer",
  color: "#383848",
  "&:hover": {
    color: "#181822",
  },
  "& .MuiButton-icon": {
    marginLeft: 0,
    marginRight: 0,
  },
};

export const cellContentCentered = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  "& span": {
    display: "flex",
  },
}

export const avatar = {
  small: {
    maxWidth: "2rem",
    maxHeight: "2rem",
    borderRadius: "50%",
  },
  medium: {
    maxWidth: "200px",
    maxHeight: "200px",
    borderRadius: "50%",
  }
}

export const imageField = {
  width: "320px",
  height: "auto",
  borderRadius: "8px",
  border: "1px lightgray solid",
}

export const logoField = {
  width: "70px",
  height: "auto",
  padding: "10px",
  float: "left",
}

export const removeNumberArrowsStyle = {
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
  }
};

export const width = {
  fluid: {
    w0: { width: 0 },
    w25: { width: "25%" },
    w50: { width: "50%" },
    w75: { width: "75%" },
    w100: { width: "100%" },
  },
  fixed: {
    w100: { width: "100px" },
    w200: { width: "200px" },
    w250: { width: "250px" },
    w300: { width: "300px" },
  },
}

export const colorField = {
  displayStyle: {
    pad: {
      height: "20px",
      width: "20px",
      borderRadius: "5px",
      marginRight: "3px",
    }
  },
  inputStyle: {
    pad: {
      height: "40px",
      width: "40px",
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      border: "1px solid #c4c4c4",
      borderRight: "none",
      marginTop: "8px",
    },
    input: {
      "& fieldset": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
        borderLeft: "none",
      }
    }
  },
};

export const accordion = {
  container: {
    marginTop: {
      md: "10px !important"
    },
    marginBottom: {
      md: "10px !important"
    },
    border: "1px solid lightgrey",
    boxShadow: "none",
    "& .MuiAccordionSummary-content": {
      margin: "8px 0 !important",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto !important",
    },
    "& .MuiAccordionDetails-root": {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  listItem: {
    borderTop: "1px solid lightgray",
  }
};

export const autoCompleteField = {
  maxHeight: "250px",
  overflowY: "scroll",
}

export const accordionActionButton = {
  ...topActionButtonBaseStyle("white", "#383848", "#181822"),
  width: {
    xs: "100%",
    md: "auto"
  },
  marginLeft:{
    xs: "0 !important",
    md: "10px !important"
  },
  marginRight: "0 !important",
}

export const dialog = {
  "& .MuiPaper-root": {
    width: {
      xs: "100% !important",
      md: "100% !important"
    }
  }
}

export const dialogContent = {
  paddingBottom: "0 !important",

  "& .MuiCardContent-root": {
    padding: "0 !important"
  },
};

export const richTextContent = {
  "& .RaRichTextInput-editorContent > div": {
    minHeight: "200px"
  }
};

export const cursor = {
  pointer: {
    cursor: "pointer"
  }
}

export const largeItem = {
  "& label": { height: { md: "44px"} },
  marginTop: { md: "8px" },
  marginBottom: { md: "4px" }
}

export const headerTooltip = {
    padding: "6px",
    fontSize: "14px",
}

export const tooltipLabelStyle = {
  fontSize: "14px",
  padding: "6px",
  cursor: "pointer"
};

export const ellipsis = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

export const datePickerError = {
  color: "#d32f2f",
  fontSize: "0.75rem",
  lineHeight: "1.66",
  margin: "4px 14px 0",
}

export const overflow = {
  scroll: {
    xy: {
      overflow: "scroll"
    },
    x: {
      overflowX: "scroll"
    },
    y: {
      overflowY: "scroll"
    }

  },
  hidden: {
    overflow: "hidden"
  }
}

export const pageCentered = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  flexDirection: "column",
};

export const pageBox = {
  border: "1px solid #ddd",
  background: "white",
  padding: "25px 45px",
  maxWidth: "600px",
  textAlign: "center",
};

export const warningMessage = { color: "#93002c", marginTop: "0px" };
export const textAlign = {
  center: {
    textAlign: "center"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  }
}

export const alertMessage = { 
  width: '100%', 
  marginBottom : "5px",
}

export const alert = {
  "& .MuiAlert-message": {
    "p, h1, h2, h3, h4, h5, h6": {
      margin: 0
    }
  },

  "& .MuiAlert-icon" : {
    alignItems : "",
  }
}

export const fileUploadLabel = {
  marginTop: {
    xs: "",
    md: "2rem",
  }
}

export const hoverCursorPointer = {
  "& :hover" : {
    cursor : "pointer"
  }
}

export const dataGridErrorActions = {
  "& .error-legend": {
    "& .legend-color": {
      width: "24px",
      height: "20px",
      "&.legend-color-highlight1": {
        backgroundColor: "#ffc9c9"
      },
      "&.legend-color-highlight2": {
        backgroundColor: "#f0c9ff"
      }
    },
    "& .legend-label": {
      fontSize: "12px",
      fontStyle: "italic",
      color: "#4d4d4d",
    }
  }
}
export const dataGridHeaderCellFilter = {
  base: {
    fontFamily: "monospace",
    fontSize: "14px",
    padding: "4px",
    border: "1px solid lightGrey",
    borderRadius: "3px",
    outlineWidth: 0,
    "&:hover": {
      borderColor: 'green'
    },
    "& :focus-visible": {
      borderColor: 'red'
    }
  },
  full: {
    width: "100%"
  },
  half: {
    width: "48%",

    left: {
      marginRight: "2%"
    },
    right: {
      marginLeft: "2%"
    }
  },
  total: {
    lineHeight: "26px"
  }
}

export const mandatoryInputTitleWithTooltipIcon = {
   ...textColor.red, 
   marginLeft: '3px', 
   marginRight : '3px' 
}

export const smallHelperText = {
   marginTop : "-2px", 
   fontStyle : 'italic'
}