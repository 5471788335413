import { Divider, Grid, ListItem, Typography } from "@mui/material";
import { DateInput, Loading, RadioButtonGroupInput, SelectInput } from "react-admin";
import React, { useEffect, useState } from "react";
import { useWatch, useFormContext } from 'react-hook-form';
import { padding2, textColor, width } from "../Styles";
import { areDependentValidationsSatisfied, canFieldBeHidden } from "../../utils/CreateReportUtils";
import { calculateEndDateUsingMidDate, calculateStartDateUsingMidDate, createFollowUpTimeRangeChoicesDynamically, createTimeRangeOptionsDynamically, requiredValidation, secondReportEndDateValidation, secondReportLengthValidation, secondReportMidDateValidation, secondReportStartDateValidation } from "../../utils/util";

export const TimeRangeForSecondReportGrid = (props) => {
  const form = useFormContext();
  const field = props.field;
  const reportLengthField = props.reportLengthField;
  const startDateField = props.startDateField;
  const endDateField = props.endDateField;
  const midDateField = props.midDateField;
  const dateRangeField = props.dateRangeField;
  const [isLoading, setIsLoading] = useState(true);
  const [timeRangeOptions, setTimeRangeOptions] = useState([]);

  useEffect(() => {
    let options = createTimeRangeOptionsDynamically(field.possibleValues);
    setTimeRangeOptions(options);

    if (field.defaultValue)
      form.setValue("timeRangeOptions", field.defaultValue);
    else if (options.length === 1)
      form.setValue("timeRangeOptions", options[0].id);
    
    setIsLoading(false);
    // eslint-disable-next-line
  }, [props.selectedTemplateId, field?.possibleValues, field?.defaultValue]);

  if (isLoading)
    return <Loading />;

  return (field &&
    <React.Fragment>
      <Grid sx={canFieldBeHidden(field.hidden, field.required, field.defaultValue) ? { display: "none" } : {}}>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {field.displayName}
              {field.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <RadioButtonGroupInput
                source={field.name}
                name={field.name}
                label={false}
                row={true}
                choices={timeRangeOptions}
                defaultValue={timeRangeOptions.length === 1 ? timeRangeOptions[0].id : field.defaultValue}
                sx={{ ...width.fluid.w100, marginBottom: "-8px" }}
                validate={field.required && requiredValidation}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </Grid>
      {reportLengthField &&
        <ReportLengthInput
          reportLengthField={reportLengthField}
          selectedTemplateId={props.selectedTemplateId}
        />
      }
      {startDateField && endDateField &&
        <DateRangeInput
          startDateField={startDateField}
          endDateField={endDateField}
          selectedTemplateId={props.selectedTemplateId}
        />
      }
      {midDateField && dateRangeField &&
        <MidDateRangeInput
          midDateField={midDateField}
          dateRangeField={dateRangeField}
          selectedTemplateId={props.selectedTemplateId}
        />
      }
    </React.Fragment>
  );
};

const ReportLengthInput = (props) => {
  const form = useFormContext();
  const reportLengthField = props.reportLengthField;
  const values = useWatch();
  const [isLoading, setIsLoading] = useState(true);
  const [reportLengthChoices, setReportLengthChoices] = useState([]);

  useEffect(() => {
    let choices = createFollowUpTimeRangeChoicesDynamically(reportLengthField.possibleValues);
    setReportLengthChoices(choices);

    if (reportLengthField.defaultValue)
      form.setValue("reportLength", reportLengthField.defaultValue);
    else if (choices.length === 1)
      form.setValue("reportLength", choices[0].id);

    setIsLoading(false);
    // eslint-disable-next-line
  }, [props.selectedTemplateId, reportLengthField.possibleValues, reportLengthField.defaultValue]);

  if (isLoading)
    return <Loading />;

  return (areDependentValidationsSatisfied(reportLengthField.dependentOn, values) &&
    <React.Fragment>
      <Grid sx={canFieldBeHidden(reportLengthField.hidden, reportLengthField.required, reportLengthField.defaultValue) ? { display: "none" } : {}}>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {reportLengthField.displayName}
              {reportLengthField.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <SelectInput
                source={reportLengthField.name}
                name={reportLengthField.name}
                variant="outlined"
                size="small"
                label={reportLengthField.placeholder ?? "Select an option"}
                choices={reportLengthChoices}
                defaultValue={reportLengthChoices.length === 1 ? reportLengthChoices[0].id : reportLengthField.defaultValue}
                sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                validate={[reportLengthField.required && requiredValidation, secondReportLengthValidation]}
                isRequired
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </Grid>
    </React.Fragment>
  );
};

const DateRangeInput = (props) => {
  const startDateField = props.startDateField;
  const endDateField = props.endDateField;
  const values = useWatch();

  return (
    <React.Fragment>
      {areDependentValidationsSatisfied(startDateField.dependentOn, values) &&
        <React.Fragment>
          <Grid sx={canFieldBeHidden(startDateField.hidden, startDateField.required, startDateField.defaultValue) ? { display: "none" } : {}}>
            <ListItem sx={padding2.y}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={4} sx={textColor.gray}>
                  {startDateField.displayName}
                  {startDateField.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <DateInput
                    source={startDateField.name}
                    name={startDateField.name}
                    variant="outlined"
                    size="small"
                    label={startDateField.placeholder ?? "Select a start date"}
                    sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                    inputProps={{
                      min: values.reportStartDate,
                      max: values.reportEndDate
                    }}
                    validate={[startDateField.required && requiredValidation, secondReportStartDateValidation]}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <Divider light />
          </Grid>
        </React.Fragment>
      }
      {areDependentValidationsSatisfied(endDateField.dependentOn, values) &&
        <React.Fragment>
          <Grid sx={canFieldBeHidden(endDateField.hidden, endDateField.required, endDateField.defaultValue) ? { display: "none" } : {}}>
            <ListItem sx={padding2.y}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={4} sx={textColor.gray}>
                  {endDateField.displayName}
                  {endDateField.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <DateInput
                    source={endDateField.name}
                    name={endDateField.name}
                    variant="outlined"
                    size="small"
                    label={endDateField.placeholder ?? "Select an end date"}
                    sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                    inputProps={{
                      min: values.reportStartDate,
                      max: values.reportEndDate
                    }}
                    validate={[endDateField.required && requiredValidation, secondReportEndDateValidation]}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <Divider light />
          </Grid>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

const MidDateRangeInput = (props) => {
  const form = useFormContext();
  const midDateField = props.midDateField;
  const dateRangeField = props.dateRangeField;
  const values = useWatch();
  const [isLoading, setIsLoading] = useState(true);
  const [dateRangeChoices, setDateRangeChoices] = useState([]);

  useEffect(() => {
    let choices = createFollowUpTimeRangeChoicesDynamically(dateRangeField.possibleValues);
    setDateRangeChoices(choices);

    if (dateRangeField.defaultValue)
      form.setValue("dateRange", dateRangeField.defaultValue);
    else if (choices.length === 1)
      form.setValue("dateRange", choices[0].id);

    setIsLoading(false);
    // eslint-disable-next-line
  }, [props.selectedTemplateId, dateRangeField.possibleValues, dateRangeField.defaultValue]);

  if (isLoading)
    return <Loading />;

  return (areDependentValidationsSatisfied(midDateField.dependentOn, values) &&
    areDependentValidationsSatisfied(dateRangeField.dependentOn, values) &&
    <React.Fragment>
      <ListItem sx={padding2.y}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            {midDateField.displayName}
            {midDateField.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <DateInput
              source={midDateField.name}
              name={midDateField.name}
              variant="outlined"
              size="small"
              label={midDateField.placeholder ?? "Select a mid date"}
              sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
              inputProps={{
                min: values.reportStartDate,
                max: values.reportEndDate
              }}
              validate={[midDateField.required && requiredValidation, secondReportMidDateValidation]}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
      <Grid sx={canFieldBeHidden(dateRangeField.hidden, dateRangeField.required, dateRangeField.defaultValue) ? { display: "none" } : {}}>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {dateRangeField.displayName}
              {dateRangeField.required && <span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <SelectInput
                source={dateRangeField.name}
                name={dateRangeField.name}
                variant="outlined"
                size="small"
                label={dateRangeField.placeholder ?? false}
                choices={dateRangeChoices}
                defaultValue={dateRangeChoices.length === 1 ? dateRangeChoices[0].id : dateRangeField.defaultValue}
                sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                validate={dateRangeField.required && requiredValidation}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
      </Grid>
      <ListItem sx={padding2.y}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>Dates Of Report</Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Typography>
              {values.midDate && values.dateRange ?
                <>
                  {
                    calculateStartDateUsingMidDate(values.dateRange, values.midDate, values.reportStartDate) + " To " +
                    calculateEndDateUsingMidDate(values.dateRange, values.midDate, values.reportEndDate)
                  }
                </>
                :
                "--"
              }
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
    </React.Fragment>
  );
};