import Checkbox from '@mui/material/Checkbox';
import { withStyles } from '@mui/styles';

const CustomCheckbox = withStyles({
  root: {
    color: '#93002c',
    '&$checked': {
      color: '#730023',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;