import React from "react";
import {
  List,
  useListController,
  useDataProvider,
  useNotify,
  useListFilterContext,
  useListContext,
  Datagrid,
  SelectField,
  TextField,
  CreateButton,
} from 'react-admin'
import {
  ActionTitles,
  RESOURCES_BASEPATH,
  RISK_INSIGHTS_COMPARISON_OPTIONS,
} from '../Constants'
import { RiskInsightsActions } from "./RiskInsightsActions";
import { isAdminUser } from "../DataProvider";
import { dataGrid, listRoot, topActionButtonBaseStyle, width } from '../Styles'
import { Box, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { TooltipDescription } from '../common/TooltipDescription'
import {RiskInsightsAdminFilter} from './RiskInsightsFilters'

export const RiskInsights = () => {
  
  const isAdmin = isAdminUser();
  const { hideFilter } = useListController();

  React.useEffect(() => {//The empty component is not getting dispalyed because of present filter value    
    if(!isAdmin){      
      hideFilter('clientId')
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isAdmin]);  

  return (
    <List
      sx={listRoot}
      empty={!isAdmin ? <RiskInsightsEmpty /> : false}
      actions={
        <RiskInsightsActions
          title={ActionTitles.CLIENT_RISK_INSIGHTS}
          basePath={RESOURCES_BASEPATH.RISK_INSIGHTS}
          showCreateEditButton={true}
          filters={isAdmin ? <RiskInsightsAdminFilter /> : null}
          mutationMode="pessimistic"
        />
      }      
      exporter={false}
      pagination={false}
    >
      <RiskInsightsGrid />
    </List>
  );
}

export const RiskInsightsGrid = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const isAdmin = isAdminUser();
  const filterContext = useListFilterContext();
  const { error } = useListContext();

  const [riskInsightParameters, setRiskInsightParameters] = React.useState([]);
  const isRecordNotPresent =  error?.status === 404;
  const isClientSelected = filterContext?.filterValues?.clientId;

  React.useEffect(() => {
    fetchRiskinsightsParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRiskinsightsParameters = () => {
    dataProvider
      .getAllRiskInsightParameters()
      .then(({data}) => {
        setRiskInsightParameters(data);
      }).catch((error) => {
      notify('Something went wrong while fetching risk insights parameters.', { type: 'warning', autoHideDuration: 1000 });
    })
  }

  return isAdmin && isClientSelected && isRecordNotPresent ? (
    <Box textAlign="center" m={1}>
      <Typography variant="h5" paragraph>
        No risk insights present for the client
      </Typography>
    </Box>
  ) : (
    <Datagrid
      sx={dataGrid.base}
      bulkActionButtons={false}
    >
      <SelectField
        source="parameter"
        label="PARAMETER"
        choices={riskInsightParameters}
        sortable={false}
        sx={{cursor: "pointer"}}
        optionText={<TooltipDescription />}
      />
      <SelectField
        source="comparison"
        label="COMPARISON"
        choices={RISK_INSIGHTS_COMPARISON_OPTIONS}
        sortable={false}
      />
      <TextField source="value" label="VALUE" sortable={false} />
    </Datagrid>
  );
}

// Need to create resuable component as the following is also used in clientDefaults
const RiskInsightsEmpty = () => {
  const { basePath } = useListContext();

  return (
    <Box textAlign="center" sx={{ ...width.fluid.w100, paddingTop: "50px" }}>
      <Typography variant="h5" paragraph>
        No Client risk insight present for Client
      </Typography>
      <CreateButton
        sx={{...topActionButtonBaseStyle()}}
        basePath={basePath}
        icon={<AddCircleOutlineIcon />}
        size="small"
        label={ActionTitles.CREATE_CLIENT_RISK_INSIGHTS}
      />
    </Box>
  );
};