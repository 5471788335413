import * as React from "react";
import { Grid } from "@mui/material";
import { useWatch, useFormState } from "react-hook-form";
import CustomDialog from "../../utils/CustomDialog";
import { useNotify, useDataProvider, NumberInput, useRefresh, TextInput, SelectInput } from "react-admin";
import { createChoicesForDropdown } from './SurveyForCreateReportGrid'
import { requiredValidation } from "../../utils/util";
import {
  RESOURCES,
} from '../Constants';
import {removeNumberArrowsStyle,textColor,width} from '../Styles'

export const SurveyParamsEditDialogue = (props) => {
  const surveyParametersFiltered = props.requestSurveyParams.filter((survey) => survey.surveyId === props.surveyDetails.surveyId);
  const surveyParameters = surveyParametersFiltered[0].inputParams;

  const dataProvider = useDataProvider();
  const values = useWatch();
  const { isDirty, errors } = useFormState();
  const refresh = useRefresh();  
  const notify = useNotify();
  
  const onSuccess = () => {
    notify(`Updated Survey Input Parameters`, { type: 'info', autoHideDuration: 1000 });
    props.handleDialogClose();
    refresh();
  };

  const onFailure = (error) => {
    notify(error.message, { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const handleSubmit = () => {   
    const fields = Object.keys(values).filter(field => field.includes('surveyfield'));
    
    const inputParams = {}
    fields.forEach(field => {
      const fieldName  = field.substring(field.indexOf('-') + 1)
      inputParams[fieldName] = values[field]
    })
   
    const surveyParam = [{
      surveyId: props.surveyDetails.surveyId,
      inputParams : inputParams
    }]
    dataProvider
        .atpAmendment(RESOURCES.REPORTS, {surveyParam: surveyParam, id: values.id, isForSurvey: true})
        .then(onSuccess)
        .catch(onFailure)    
  }

  return(    
    <CustomDialog       
      openDialog = {props.open}
      closeDialog = {props.handleDialogClose}
      handleSubmit={handleSubmit}
      title = "Update Survey Input Parameters"                          
      content = {
        <SurveyEditFields surveyFields={props.surveyDetails} surveyValues={surveyParameters}/>              
      }
      invalid={!isDirty || (errors && Object.keys(errors).length > 0)}
    />
  )
}

export const SurveyEditFields = (props) => {
  let components = [];
  const fields = props.surveyFields
  const values = props.surveyValues

  if(!fields)
  return components
    fields.inputParams.forEach((param) => {
    let choices = [];
    if (param.type === 'Dropdown') choices = createChoicesForDropdown(param.possibleValues);
    const item = (
      <React.Fragment>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3}>
            <div>
              {param.displayName}
              {param.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
            </div>
          </Grid>
          <Grid xs={12} md={9}>
            {param.type === 'Textfield' && (
              <TextInput
                source={param.name}
                name={`surveyfield-${param.name}`}
                label={param.placeholder ? param.placeholder : ''}
                validate={param.required && requiredValidation}
                defaultValue={values[`${param.name}`]}
                variant="outlined"
                size="small"
                sx={{...width.fluid.w100, marginBottom: "-15px" }}
              />
            )}
            {param.type === 'NumberInput' && (
              <NumberInput
                onWheel={(e) => {
                  e.target.blur()
                }}
                source={param.name}
                name={`surveyfield-${param.name}`}
                label={param.placeholder ? param.placeholder : ''}
                validate={param.required && requiredValidation}
                defaultValue={values[`${param.name}`]}
                variant="outlined"
                size="small"
                sx={{...removeNumberArrowsStyle, ...width.fluid.w100, marginBottom: "-15px" }}
              />
            )}
            {param.type === 'Dropdown' && (
              <SelectInput
                source={param.name}
                name={`surveyfield-${param.name}`}
                label={param.placeholder ? param.placeholder : ''}
                validate={param.required && requiredValidation}
                choices={choices}
                defaultValue={values[`${param.name}`]}
                variant="outlined"
                size="small"
                sx={{...width.fluid.w100, marginBottom: "-15px" }}
                isRequired
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
        components.push(item);
    });
    return components;
  }