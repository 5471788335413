import React from "react";
import { httpClient } from '../DataProvider';
import {baseButtonStyle} from '../Styles'
import {Button} from '@mui/material'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function OpenEmailClient(props) {
  const { clientRequestId } = props;
  const openEmailClient = async () => {
    const emailContentApi = `${apiUrl}/api/report-request/email-content?clientRequestId=${clientRequestId}`;
    httpClient(emailContentApi)
    .then(({ json }) => {
        const { to, body, subject } = json.data;
        const mailToLink = `mailto:${to}?subject=${subject}&body=${body
          .replace(new RegExp("'|‘|’", "g"), "%27")
          .replace(new RegExp("&", "g"), "%26")
          // eslint-disable-next-line no-control-regex
          .replace(new RegExp("\n", "g"), "%0A")}`;
        window.open(mailToLink, "_blank", "noopener");
    })
  }
          
  return (
    <Button
      size="medium"
      onClick={openEmailClient}
      disabled={props.disabled}
      sx={{...baseButtonStyle()}}
    >
      Open Email Client
    </Button>
  );
};


  
export default OpenEmailClient;