import * as React from 'react'
import { EVENT_REASON } from '../../Constants'
import ListItem from '@mui/material/ListItem'
import {Grid} from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, FunctionField, SimpleForm } from 'react-admin'
import { SurveyParamsEditDialogue } from '../../reports/EditSurveyParams'
import SimpleFormContainerComponent from '../SimpleFormContainerComponent'
import { iconAction, padding2, textColor} from '../../Styles'
import CustomTooltip from '../../../utils/CustomTooltip'

export const ReportListItem = (props) => {
  const [open, setOpen] = React.useState(false);

  let inputs = []
  if(props.requestSurveyParams){
    const requestParams = props.requestSurveyParams.filter((survey) => survey.surveyId === props.survey.surveyId)
    if(requestParams.length > 0 && requestParams[0].inputParams){
      for (let [key, value] of Object.entries(requestParams[0].inputParams)) {
        const surveyParam = props.survey.inputParams.filter((item) => item.name === key)
        if(surveyParam.length > 0){
          key = surveyParam[0].displayName // for default params, show display name
        }
        inputs.push({ key, value })
      }
    }
  }

  const handleClick = () => {
    setOpen(true);
  }

  const handleDialogClose = (event, reason) => {
    if(reason !== EVENT_REASON.BACKDROP_CLICK){
      setOpen(false)
    }
  };

  return (
    <ListItem sx={padding2.y}>
      <Grid container alignItems={props.requestSurveyParams && inputs.length > 0 ? 'auto' : 'center'}>
        <Grid item xs={12} md={4} sx={textColor.gray}>{props.title}</Grid>
        <Grid item xs={12} md={8}>
          {props.value}
          {props.requestSurveyParams && inputs.length > 0 && (
            <React.Fragment>
              <Grid container alignItems={"center"}>
                <Grid item>
                  <p>
                    <strong>Survey Input Parameters:</strong>
                  </p>
                </Grid>
                <Grid item sx={padding2.left}>
                  <p>
                    <FunctionField
                      source="Edit"
                      label={false}
                      sortable={false}
                      render={(record) => (
                        <Grid>
                          <CustomTooltip title="Edit" placement="top">
                            <Button
                              size="medium"
                              sx={iconAction}
                              onClick={handleClick}
                            >
                              <EditOutlinedIcon />
                            </Button>
                          </CustomTooltip>
                        </Grid>
                      )}
                    />
                    <SimpleForm
                      toolbar={null}
                      component={SimpleFormContainerComponent}
                    >
                      <SurveyParamsEditDialogue
                        open={open}
                        handleDialogClose={handleDialogClose}
                        requestSurveyParams={props.requestSurveyParams}
                        surveyDetails={props.survey}
                      />
                    </SimpleForm>
                  </p>
                </Grid>
              </Grid>
              <ol style={{...padding2.left}}>
                {inputs.map((item) => {
                  return (
                    <li key={item.key}>{item.key} - {item.value}</li>
                  );
                })}
              </ol>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};