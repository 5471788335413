import * as React from "react";
import { Show, SimpleShowLayout, useRecordContext } from 'react-admin'
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { isAdminUser } from "../DataProvider";
import { SchedulesActions } from "./SchedulesActions";
import { ActionTitles, SCHEDULE_ACTIONS, SCHEDULE_STATUS } from '../Constants'
import {  ReminderIcon, ReminderOffIcon } from "../common/StyledIcons";
import ListActions from '../common/ListActions'
import { contentCenter, padding0, padding1 } from '../Styles'
import { ReportListItem } from '../common/listItems/ReportListItem'

export const ScheduleShow = () => (
  <Show
    actions={<ListActions title={ActionTitles.SCHEDULE_DETAILS} />}
    renderButtonAndSearch={false}
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <ScheduleShowGrid />
    </SimpleShowLayout>
  </Show>
);

const ScheduleShowGrid = () => {
  const record = useRecordContext();
  const isAdmin = isAdminUser();
  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ReportListItem
            title="Contact Name"
            value={record["contactName"]}
          />
          <Divider light />
          <ReportListItem
            title="Email Address"
            value={record["emailAddress"]}
          />
          <Divider light />
          <ReportListItem
            title="Business Name"
            value={record["businessName"]}
          />
          <Divider light />
          <ReportListItem
            title="Description Of Matter"
            value={record["descriptionOfMatter"]}
          />
          <Divider light />
          <ReportListItem
            title="Client Reference"
            value={record["clientReference"]}
          />
          <Divider light />
          <ReportListItem
            title="Report Type"
            value={record["reportType"]}
          />
          <Divider light />
          <ReportListItem
            title="Frequency"
            value={record["frequency"]}
          />
          <Divider light />
          <ReportListItem
            title="Start Date Of First Report"
            value={record["startDateOfFirstReport"]}
          />
          <Divider light />
          <ReportListItem
            title="Send Email Reminders"
            value={record["sendEmailReminders"] ? <ReminderIcon /> : <ReminderOffIcon />}
          />
          <Divider light />
          <ReportListItem
            title="Date Range Of Last Report"
            value={record["dateRangeOfLastReport"]}
          />
          <Divider light />
          <ReportListItem
            title="Date Range Of Next Report"
            value={record["dateRangeOfNextReport"]}
          />
          <Divider light />
          <ReportListItem
            title="Status"
            value={record["status"]}
          />
          <Divider light />
          <ListItem sx={padding1.y}>
            <Grid container justifyContent="flex-end">
              <Grid container item xs={12} lg={10} justifyContent="flex-end">
                <Grid container item xs={12} md={3}>
                  <SchedulesActions
                    reportRequestScheduleId={record["id"]}
                    scheduleAction = {SCHEDULE_ACTIONS.EXIT}
                  />
                </Grid>
                <Grid container item xs={12} md={3}>
                  <SchedulesActions
                    reportRequestScheduleId={record["id"]}
                    scheduleAction = {SCHEDULE_ACTIONS.STOP_SCHEDULE}
                    isActive = {isAdmin ? SCHEDULE_STATUS.INACTIVE : record["status"]}
                  />
                </Grid>
                <Grid container item xs={12} md={3}>
                  <SchedulesActions
                    reportRequestScheduleId={record["id"]}
                    scheduleAction = {SCHEDULE_ACTIONS.TOGGLE_REMINDERS}
                    isActive = {isAdmin ? SCHEDULE_STATUS.INACTIVE : record["status"]}
                    reminderStatus = {record["sendEmailReminders"]}
                  />
                </Grid>
                <Grid container item xs={12} md={3}>
                  <SchedulesActions
                    reportRequestScheduleId={record["id"]}
                    scheduleAction = {SCHEDULE_ACTIONS.VIEW_REPORTS}
                    clientId={record["clientId"]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};
