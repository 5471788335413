import React from "react";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  TextField,
  useDataProvider,
  useListContext,
  useListController,
  useListFilterContext,
  useNotify
} from 'react-admin'
import {Box,Grid,Typography} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { isAdminUser } from "../DataProvider";
import { ClientDefaultsActions} from './ClientDefaultsActions'
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import {dataGrid,listRoot,padding2,topActionButtonBaseStyle,width} from '../Styles'
import {ClientDefaultsAdminFilter} from './ClientDefaultsFilters'

export const ClientDefaults = (props) => {
  const isAdmin = isAdminUser();
  const { hideFilter } = useListController();

  React.useEffect(() => {//The empty component is not getting dispalyed because of present filter value    
    if (!isAdmin) {
      hideFilter('clientId')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  return (
    <List
      sx={listRoot}
      empty={!isAdmin ? <ClientDefaultsEmpty basePath={RESOURCES.CLIENT_DEFAULTS} {...props} /> : false}
      actions={
        <ClientDefaultsActions
          title={ActionTitles.CLIENT_DEFAULTS}
          basePath={RESOURCES_BASEPATH.CLIENT_DEFAULTS}
          showCreateEditButton={true}
          filters={isAdmin ? <ClientDefaultsAdminFilter /> : null}
          mutationMode="pessimistic"
          {...props}
        />
      }
      pagination={false}
      exporter={false}
      {...props}
    >
      <ClientDefaultsGrid />
    </List>
  );
}

const ClientDefaultsGrid = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const isAdmin = isAdminUser();
  const filterContext = useListFilterContext();
  const { total } = useListContext();

  const [clientDefaultParametersMap, setClientDefaultParametersMap] = React.useState(new Map());
  const isRecordNotPresent = total === 0;
  const isClientSelected = filterContext?.filterValues?.clientId;

  React.useEffect(() => {
    fetchClientDefaultParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientDefaultParams = () => {
    dataProvider
      .getAllClientDefaultParameters()
      .then(params => {
        let paramsMap = new Map();
        params.data.forEach(param => paramsMap.set(param.parameterKey, param));
        setClientDefaultParametersMap(paramsMap);
      })
      .catch((error) => {
        notify('Something went wrong while fetching client default parameters.', "warning", { autoHideDuration: 1000 });
      });
  };

  return isAdmin && isClientSelected && isRecordNotPresent ?
    (
      <Box textAlign="center">
        <Grid sx={padding2.top}>
          <Typography paragraph>
            No Client defaults present for Client
          </Typography>
        </Grid>
      </Box>
    ) :
    (
      <Datagrid
        sx={dataGrid.base}
        bulkActionButtons={false}
      >
        <FunctionField
          source="parameterKey"
          label="PARAMETER"
          render={(record) =>
            clientDefaultParametersMap.get(record['parameterKey'])?.parameterDisplayName
          }
          sortable={false}
        />
        <TextField
          source="value"
          label="VALUE"
          sortable={false}
        />
      </Datagrid>
    );
}

const ClientDefaultsEmpty = () => {
  const { basePath } = useListContext();

  return (
    <Box textAlign="center" sx={{ ...width.fluid.w100, paddingTop: "50px" }}>
      <Typography variant="h5" paragraph>
        No Client defaults present for Client
      </Typography>
      <CreateButton
        sx={{...topActionButtonBaseStyle()}}
        basePath={basePath}
        icon={<AddCircleOutlineIcon />}
        size="small"
        label={ActionTitles.CREATE_CLIENT_DEFAULTS}
      />
    </Box>
  );
};