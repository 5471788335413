import React from "react";
import { Divider, Grid, ListItem } from "@mui/material";
import { BooleanInput, FormDataConsumer, NumberInput } from "react-admin";
import {
  canFieldBeHidden,
  filterFieldBasedOnName,
  showLargeItemInput,
} from "../../utils/CreateReportUtils";
import { useFormContext, useWatch } from 'react-hook-form';
import { isFeatureEnabled } from "../DataProvider";
import { CLIENT_DEFAULT_PARAMS, ENABLED_FEATURES_ENUM } from "../Constants";
import {
  positiveValue,
  validate,
} from "../../utils/util";
import isEmpty from "lodash/isEmpty";
import { largeItem, padding2, removeNumberArrowsStyle, textColor, width } from '../Styles'

export const LargeItemDynamicInput = (props) => {
  const values = useWatch();
  const form = useFormContext();

  const { largeItemField } = props;

  React.useEffect(() => {
    showLargeItemInput(values.reportType) &&
      isFeatureEnabled(ENABLED_FEATURES_ENUM.GROUPING_LARGE_ITEM) // cleaning form on case of template and report change
      ? form.setValue("largeItem", largeItemField?.defaultValue)
      : form.setValue("largeItem", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reportType, props.selectedTemplateId, largeItemField]);

  return (
    showLargeItemInput(values.reportType) &&
    isFeatureEnabled(ENABLED_FEATURES_ENUM.GROUPING_LARGE_ITEM) && (
      <>
        <div style={largeItemField?.isHidden ? { display: "none" } : {}}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid xs={12} md={4} sx={textColor.gray}>
                {largeItemField?.displayName
                  ? largeItemField.displayName
                  : "Large Item"}
              </Grid>
              <Grid xs={12} md={8}>
                <BooleanInput
                  defaultValue={largeItemField?.defaultValue}
                  source="largeItem"
                  label={false}
                  sx={{ marginBottom: "-15px" }}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
        </div>
        <LargeItemCategory selectedTemplateId={props.selectedTemplateId} reportType={values.reportType} categoryFields={largeItemField?.possibleValues} />
      </>
    )
  );
};

const LargeItemCategory = (props) => {
  const form = useFormContext();

  const [clientDefaultsMap, setClientDefaultsMap] = React.useState(new Map());

  React.useEffect(() => {
    let data = localStorage.getItem("clientDefaults");
    let defaultsMap = new Map();
    JSON.parse(data).forEach(param => {
      defaultsMap.set(CLIENT_DEFAULT_PARAMS[param.parameterKey], param.value)
    });
    setClientDefaultsMap(defaultsMap);
  }, []);

  const incomeField = !isEmpty(props.categoryFields) ? filterFieldBasedOnName("largeItemIncome", props.categoryFields) : undefined;
  const expenseField = !isEmpty(props.categoryFields) ? filterFieldBasedOnName("largeItemExpense", props.categoryFields) : undefined;
  let incomeThresholdField = !isEmpty(incomeField?.possibleValues) ? filterFieldBasedOnName("largeItemIncomeThreshold", incomeField?.possibleValues) : undefined;
  let expenseThresholdField = !isEmpty(expenseField?.possibleValues) ? filterFieldBasedOnName("largeItemExpenseThreshold", expenseField?.possibleValues) : undefined;

  //Hide the child input(Threshold) if parent input(Category) is hidden with defaultValue false.
  if (incomeField) {
    if (incomeField.isHidden && incomeField.defaultValue !== true) {
      if (incomeThresholdField) {
        incomeThresholdField.isHidden = true;
      } else {
        incomeThresholdField = { isHidden: true }
      }
    }
  }

  if (expenseField) {
    if (expenseField.isHidden && expenseField.defaultValue !== true) {
      if (expenseThresholdField) {
        expenseThresholdField.isHidden = true;
      } else {
        expenseThresholdField = { isHidden: true }
      }
    }
  }

  React.useEffect(() => {
    if (
      showLargeItemInput(props.reportType) &&
      isFeatureEnabled(ENABLED_FEATURES_ENUM.GROUPING_LARGE_ITEM)
    ) {
      // cleaning form on case of template and report change
      form.setValue("largeItemIncome", incomeField?.defaultValue);
      form.setValue("largeItemExpense", expenseField?.defaultValue);
      form.setValue("largeItemIncomeThreshold", incomeThresholdField?.defaultValue);
      form.setValue("largeItemExpenseThreshold", expenseThresholdField?.defaultValue);
    } else {
      form.setValue("largeItemIncome", false);
      form.setValue("largeItemExpense", false);
      form.setValue("largeItemIncomeThreshold", undefined);
      form.setValue("largeItemExpenseThreshold", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportType, props.selectedTemplateId, incomeField, expenseField, incomeThresholdField, expenseThresholdField]);

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.largeItem && (
          <>
            { //hiding the category block when all items are hidden
              (<div style={(incomeField?.isHidden && expenseField?.isHidden && incomeThresholdField?.isHidden && expenseThresholdField?.isHidden) ? { display: "none" } : {}}>
                <ListItem sx={padding2.y}>
                  <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={6} lg={5}>
                      <Grid container>
                        <Grid item xs={12} md={5}>
                          <Grid sx={incomeField?.isHidden ? { display: "none" } : {}}>
                            <BooleanInput
                              sx={largeItem}
                              source="largeItemIncome"
                              label={
                                incomeField?.displayName
                                  ? incomeField.displayName
                                  : "Income"
                              }
                              defaultValue={incomeField?.defaultValue}
                            />
                          </Grid>
                        </Grid>
                        {formData?.largeItemIncome && (
                          <Grid item xs={12} md={7} >
                            <NumberInput
                              source="largeItemIncomeThreshold"
                              label={
                                incomeThresholdField?.placeholder
                                  ? incomeThresholdField.placeholder
                                  : "Threshold"
                              }
                              variant="outlined"
                              validate={[validate, positiveValue]}
                              defaultValue={
                                incomeThresholdField?.defaultValue ??
                                clientDefaultsMap.get(CLIENT_DEFAULT_PARAMS.LARGE_ITEM_GROUP_INCOME)
                              }
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              sx={{
                                ...removeNumberArrowsStyle,
                                ...width.fluid.w100,
                                ...(incomeThresholdField && canFieldBeHidden(
                                  incomeThresholdField?.isHidden,
                                  true,
                                  incomeThresholdField?.defaultValue ??
                                  clientDefaultsMap.get(CLIENT_DEFAULT_PARAMS.LARGE_ITEM_GROUP_INCOME)
                                ) ? { display: "none" } : {})
                              }}
                              disabled={
                                incomeThresholdField &&
                                canFieldBeHidden(
                                  incomeThresholdField?.disabled,
                                  true,
                                  incomeThresholdField?.defaultValue ??
                                  clientDefaultsMap.get(CLIENT_DEFAULT_PARAMS.LARGE_ITEM_GROUP_INCOME)
                                )
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={5}>
                          <Grid sx={expenseField?.isHidden ? { display: "none" } : {}}>
                            <BooleanInput
                              sx={largeItem}
                              source="largeItemExpense"
                              label={
                                expenseField?.displayName
                                  ? expenseField.displayName
                                  : "Expense"
                              }
                              defaultValue={expenseField?.defaultValue}

                            />
                          </Grid>
                        </Grid>
                        {formData?.largeItemExpense && (
                          <Grid item xs={12} md={7}>
                            <NumberInput
                              source="largeItemExpenseThreshold"
                              label={
                                expenseThresholdField?.placeholder
                                  ? expenseThresholdField.placeholder
                                  : "Threshold"
                              }
                              variant="outlined"
                              validate={[validate, positiveValue]}
                              defaultValue={
                                expenseThresholdField?.defaultValue ??
                                clientDefaultsMap.get(CLIENT_DEFAULT_PARAMS.LARGE_ITEM_GROUP_EXPENSE)
                              }
                              onWheel={(e) => {
                                e.target.blur();
                              }}
                              sx={{
                                ...removeNumberArrowsStyle,
                                ...width.fluid.w100,
                                ...(expenseThresholdField && canFieldBeHidden(
                                  expenseThresholdField?.isHidden,
                                  true,
                                  expenseThresholdField?.defaultValue ??
                                  clientDefaultsMap.get(CLIENT_DEFAULT_PARAMS.LARGE_ITEM_GROUP_EXPENSE)
                                ) ? { display: "none" } : {})
                              }}
                              disabled={
                                expenseThresholdField &&
                                canFieldBeHidden(
                                  expenseThresholdField?.disabled,
                                  true,
                                  expenseThresholdField?.defaultValue ??
                                  clientDefaultsMap.get(CLIENT_DEFAULT_PARAMS.LARGE_ITEM_GROUP_EXPENSE)
                                )
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider light />
              </div>)}
          </>
        )
      }
    </FormDataConsumer>
  );
};