import {
	Create,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin';
import CustomToolbar from '../common/FormToolbar';
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from '../Constants';
import { contentCenter, simpleForm } from '../Styles';
import { MatterCreateEditGrid } from './MatterCreateEditGrid';
import ListActions from '../common/ListActions';

export const MatterCreate = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (data) => {
		notify("Matter Created");
		redirect('show', RESOURCES.MATTERS, data.id);
		refresh();
    };

	return (
		<Create
			sx={contentCenter}
			actions={<ListActions title={ActionTitles.CREATE_MATTER} renderButtonAndSearch={false} />}
			mutationMode="pessimistic"
			mutationOptions={{onSuccess}}
		>
			<SimpleForm
				warnWhenUnsavedChanges
				toolbar={
					<CustomToolbar
						hideButton1={true}
						buttontitle2={ActionTitles.CREATE}
						basePath={RESOURCES_BASEPATH.MATTERS}
						resource={RESOURCES.MATTERS}
					/>
				}
				mode="onBlur"
				reValidateMode="onBlur"
				sx={simpleForm}
			>
				<MatterCreateEditGrid />
			</SimpleForm>
		</Create>
	);
}