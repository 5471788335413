import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { Loading } from "ra-ui-materialui";
import {Grid} from '@mui/material'
import {Button} from 'react-admin'
import {textColor,topActionButtonBaseStyle} from '../components/Styles'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {overflow} from '../components/Styles'

function PdfViewer({ file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageExceeds, setPageExceeds] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(1);
    if (numPages > 100) {
      setPageExceeds(true);
      setNumPages(100);
    } else {
      setNumPages(numPages);
    }
  };

  function previousPage() {
    setPageNumber(pageNumber - 5);
  }

  function nextPage() {
    if (pageNumber + 5 <= numPages)   {
      setPageNumber(pageNumber + 5);
    }
  }

  function renderPages() {
    var pages = [];
    for (
      var page = pageNumber;
      page < pageNumber + 5 && page <= numPages;
      page++
    ) {
      if (page <= numPages) {
        pages.push(<Page pageNumber={page} scale="1.5" loading="" />);
      }
    }
    return pages;
  }

  return (
    <>
      <Grid container>
        <Grid item justifyContent="center" textAlign="center">
          {pageExceeds === true && (
            <p style={textColor.primary}>
              Displaying the first 100 pages of the report. Kindly download the
              report to view full content.
            </p>
          )}
          <Document
            file={file}
            loading={<Loading />}
            onLoadSuccess={onDocumentLoadSuccess}
            style={overflow.scroll.xy}
          >
            {renderPages()}
          </Document>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            sx={{...topActionButtonBaseStyle()}}
            onClick={previousPage}
            label="Previous"
            disabled={pageNumber === 1}
          >
            <ChevronLeftOutlinedIcon />
          </Button>
          <Button
            alignIcon="right"
            variant="outlined"
            size="small"
            sx={{...topActionButtonBaseStyle()}}
            onClick={nextPage}
            label="Next"
            disabled={(pageNumber + 5) > numPages}
          >
            <ChevronRightOutlinedIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
export default PdfViewer;
