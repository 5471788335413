import * as React from "react";
import { Divider, Grid, ListItem } from "@mui/material";
import { padding2, textColor, width } from "../Styles";
import { TextInput } from "react-admin";
import { requiredValidation } from "../../utils/util";

export const MatterCreateEditGrid = (props) => {
  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <ListItemComponentForMatter
          title="Client Name / Reference"
          required={true}
          input={
            <TextInput
              source="name"
              name="name"
              label="Name"
              variant="outlined"
              size="small"
              sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
              multiline={false}
              defaultValue={props.name}
              validate={requiredValidation}
            />
          }
        />
        <Divider light />
        <ListItemComponentForMatter
          title="Notes"
          required={false}
          input={
            <TextInput
              source="notes"
              name="notes"
              label="Notes"
              variant="outlined"
              size="small"
              sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
              multiline={true}
              defaultValue={props.notes}
              validate={false}
            />
          }
        />
        <Divider light />
      </Grid>
    </Grid>
  );
};


const ListItemComponentForMatter = (props) => {
  return (
    <React.Fragment>
      <Grid>
        <ListItem sx={padding2.y}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} sx={textColor.gray}>
              {props.title}
              {props.required && <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              {props.input}
            </Grid>
          </Grid>
        </ListItem>
      </Grid>
    </React.Fragment>
  );
};