import React from "react";
import Tooltip from '@mui/material/Tooltip';

// Use this custom tooltip when using custom components as it's children, so that the tooltip can get DOM reference of it's child components.
// Refer: https://material-ui.com/components/tooltips/#custom-child-element

const TooltipWrapper = React.forwardRef(({children, ...props}, ref) => (
    <div ref={ref}>{React.cloneElement(children, props)}</div>
));

const CustomTooltip = (({children, ...props}) => (
    <Tooltip 
      disableInteractive
      {...props}
    >
        <TooltipWrapper>{children}</TooltipWrapper>
    </Tooltip>
));

export default CustomTooltip;