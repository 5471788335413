import React, {useState} from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import PdfPreview from "./PdfPreview";
import CircularProgress from '@mui/material/CircularProgress';
import {
  INSIGHTS_REPORT_TYPE,
  REPORT_FORMATS,
  REPORT_STATUSES,
} from "../components/Constants";
import { httpClient, isAdminUser } from "../components/DataProvider";
import {iconAction,linkButton} from '../components/Styles'
import {Button, isEmpty, useNotify} from 'react-admin'
import {default as MuiButton} from "@mui/material/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Grid} from '@mui/material'
import isNull from "lodash/isNull"
import { createErrorObject } from "./util";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function PreviewLink(props) {
  
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [doc, setDoc] = useState({});
    const { clientRequestId, clientId,  status } = props;
    const notify = useNotify();

    let riskInsightsUrl = isAdminUser()       
      ? `${apiUrl}/admin/report-request/${clientRequestId}/client/${clientId}/insights-report/${INSIGHTS_REPORT_TYPE.RISK_INSIGHTS}?format=${REPORT_FORMATS.PDF}`     
      : `${apiUrl}/api/report-request/${clientRequestId}/insights-report/${INSIGHTS_REPORT_TYPE.RISK_INSIGHTS}?format=${REPORT_FORMATS.PDF}`;
  
    let reportUrl = isAdminUser()
      ? `${apiUrl}/admin/report-request/client/${clientId}/report-data/${clientRequestId}?format=${REPORT_FORMATS.PDF}`
      : `${apiUrl}/api/report-request/${clientRequestId}?format=${REPORT_FORMATS.PDF}`;    
        
    const handleClick = async () => {
      setLoading(true);
      setShowModal(true);
      await httpClient(reportUrl).then(({ json }) => {
        if(isNull(json.data) || !isEmpty(json.errors)){
          throw createErrorObject(json.errors[0].code, json.errors[0].description);
        }
        const urlWithBase64Data = json.data.reportData.formattedReportData;
        setDoc({ url: `data:application/pdf;base64,${urlWithBase64Data}` });
        setLoading(false);
      });
    };

    const fetchReportData = async () => {
      await httpClient(reportUrl).then(({ json }) => {        
        const urlWithBase64Data = json.data.reportData.formattedReportData;
        setDoc({ url: `data:application/pdf;base64,${urlWithBase64Data}` });
        setLoading(false);
      }).catch((error) => {
        notify('Something went wrong while fetching report.', "warning", { autoHideDuration: 1000 });
        setLoading(false);
        setShowModal(false);
      });
    }

    const closeModal = () => {
      setShowModal(false);
    };
    const isReportAvailable = status === REPORT_STATUSES.AVAILABLE;
    return (isReportAvailable ? (
      <React.Fragment>
        <MuiButton
          sx={linkButton}
          size="medium"
          disabled={loading}
          onClick={handleClick}
        >
          Preview
        </MuiButton>
        <Dialog onClose={closeModal} scroll="paper" maxWidth="xl" aria-labelledby="customized-dialog-title" open={showModal}>
          <DialogTitle display="flex" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={closeModal}
                sx={iconAction}
                size="large"
              >
                <CloseOutlinedIcon />
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            {
              loading ?
                <Grid container justifyContent="center">
                  <CircularProgress color="inherit" />
                </Grid> :
                <PdfPreview file={doc} />
            }
          </DialogContent>
        </Dialog>
      </React.Fragment> ) : "--"
    );
};
export default PreviewLink;