import { Edit, SimpleForm, useNotify, useRedirect, useRefresh } from "react-admin";
import CustomToolbar from "../common/FormToolbar";
import { contentCenter, simpleForm } from "../Styles";
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import { MatterCreateEditGrid } from "./MatterCreateEditGrid";
import ListActions from "../common/ListActions";

export const MatterEdit = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const refresh = useRefresh();

	const onSuccess = (data) => {
		notify("Matter Updated");
		redirect('show', RESOURCES.MATTERS, data.id);
		refresh();
    };

	return (
		<Edit
			actions={
				<ListActions
					title={ActionTitles.MATTER_UPDATE}
					showViewButton={true}
					showDeleteButton={true}
					basePath={RESOURCES_BASEPATH.MATTERS}
					resource={RESOURCES.MATTERS}
					recordName={ActionTitles.MATTER}
				/>
			}
			sx={contentCenter}
			mutationMode="pessimistic"
			mutationOptions={{onSuccess}}
		>
			<SimpleForm
				warnWhenUnsavedChanges
				toolbar={
					<CustomToolbar
						hideButton1={true}
						buttontitle2={ActionTitles.UPDATE}
						basePath={RESOURCES_BASEPATH.MATTERS}
						resource={RESOURCES.MATTERS}
						isEdit={true}
					/>
				}
				mode="onBlur"
				reValidateMode="onBlur"
				sx={simpleForm}
			>
				<MatterCreateEditGrid />
			</SimpleForm>
		</Edit>
	);
}