import * as React from "react";
import { Sidebar } from "react-admin";
import {sidebar} from '../../Styles'

export const CustomSidebar = (props) => {
  return (
    <Sidebar
      closedSize={60}
      sx={sidebar}
      {...props} 
    />
  );
};