export const parseForClientContentDto = (data, content) => {
  var clientContent = {};
  clientContent.clientId = data.clientId;
  clientContent.contentType = 'SPLASH_SCREEN';
  clientContent.content = content;
  return JSON.stringify(clientContent);
};

export const parseDefaultContentData = (data) => {
  const parsedRequest = {
    id: data.contentType,
    ...data,
  };
  return parsedRequest;
};

export const parseContentDataForView = (data) => {
  const parsedRequest = {
    id: data.contentType,
    ...data,
  };
  return parsedRequest;
};

export function parseDataForContentCreate(data) {
  data.version = 0;
  return JSON.stringify(data);
}

export const parseForContentUpdate = (data) => {
  data.contentType = data.id;
  return JSON.stringify(data);
};