import * as React from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {
  BooleanInput,
  Edit,
  SelectInput,
  SimpleForm,
  useRecordContext,
} from 'react-admin'
import ListActions from '../common/ListActions'
import {
  ActionTitles,
  RESOURCES,
  RESOURCES_BASEPATH, STATUS_MESSAGE_COMPONENTS,
  STATUS_MESSAGE_TYPES,
  STATUS_MESSAGE_TYPES_OPTIONS,
} from '../Constants'
import {
  contentCenter,
  padding0,
  padding2,
  richTextContent,
  simpleForm,
  textColor,
  width
} from '../Styles'
import CustomToolbar from "../common/FormToolbar";
import {
  requiredValidation,
} from "../../utils/util";
import { RichTextInput } from "ra-input-rich-text";
import {TextInput} from "ra-ui-materialui";
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from "../common/DateTimePicker";
import moment from "moment-timezone";
import {useFormContext} from "react-hook-form";

export const StatusMessageEdit = () => (
  <Edit
    actions={
      <ListActions
        title={ActionTitles.STATUS_MESSAGE_UPDATE}
        showViewButton={true}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
        resource={RESOURCES.STATUS_MESSAGES}
        recordName={ActionTitles.STATUS_MESSAGES}
      />
    }
    sx={contentCenter}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={
        <CustomToolbar
          buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
          buttontitle2={ActionTitles.UPDATE}
          isEdit={true}
          basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
          resource={RESOURCES.STATUS_MESSAGES}
        />
      }
      mode="onBlur"
      reValidateMode="onBlur"
      noValidate
      sx={simpleForm}
    >
      <StatusMessagesCreateEditGrid />
    </SimpleForm>
  </Edit>
);

export const StatusMessagesCreateEditGrid = () => {
  const record = useRecordContext();
  const form = useFormContext();

  const statusMessageCorporatePortal = record ? record.components.includes(STATUS_MESSAGE_COMPONENTS.CORPORATE_PORTAL) : true
  const statusMessageUserJourney = record ? record.components.includes(STATUS_MESSAGE_COMPONENTS.USER_JOURNEY) : true
  const roundDownClosestHalfHour = moment().minute(Math.floor(moment().minute() / 30) * 30).second(0)
  const defaultEffectiveFromDate = roundDownClosestHalfHour.toISOString()
  const defaultEffectiveToDate = roundDownClosestHalfHour.add(4, 'hours').toISOString()

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Name
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="name"
                  label="Name"
                  variant="outlined"
                  size="small"
                  validate={requiredValidation}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Components
                <span style={{...textColor.red, marginLeft: "3px"}}>*</span>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Grid>
                    <BooleanInput
                      sx={{marginBottom: "-15px"}}
                      source="statusMessageCorporatePortal"
                      label={STATUS_MESSAGE_COMPONENTS.CORPORATE_PORTAL}
                      defaultValue={statusMessageCorporatePortal}
                      readOnly={form.getValues('statusMessageCorporatePortal') && !form.getValues('statusMessageUserJourney')}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid>
                    <BooleanInput
                      sx={{marginBottom: "-15px"}}
                      source="statusMessageUserJourney"
                      label={STATUS_MESSAGE_COMPONENTS.USER_JOURNEY}
                      defaultValue={statusMessageUserJourney}
                      readOnly={form.getValues('statusMessageUserJourney') && !form.getValues('statusMessageCorporatePortal')}
                    />
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Effective From
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <DateTimePicker
                  source="effectiveFrom"
                  isStartDate={true}
                  defaultStartDate={defaultEffectiveFromDate}
                  maxDate={form.getValues('effectiveTo') ? new Date(form.getValues('effectiveTo')) : null}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Effective To
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <DateTimePicker
                  source="effectiveTo"
                  isEndDate={true}
                  defaultEndDate={defaultEffectiveToDate}
                  minDate={form.getValues('effectiveFrom') ? new Date(form.getValues('effectiveFrom')) : null}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Notification Type
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <SelectInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="type"
                  name="type"
                  variant="outlined"
                  size="small"
                  label="Choose an option"
                  choices={STATUS_MESSAGE_TYPES_OPTIONS}
                  defaultValue={STATUS_MESSAGE_TYPES.INFO}
                  isRequired={true}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Content
                <span style={{...textColor.red, marginLeft: "3px"}}>*</span>
              </Grid>
              <Grid item xs={12} md={8}>
                <RichTextInput
                    name="message"
                    source="message"
                    variant="outlined"
                    label={false}
                    validate={requiredValidation}
                    sx={{...richTextContent, marginBottom: "-15px"}}
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <Divider light />
      </Grid>
    </Grid>
  );
};