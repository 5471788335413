import * as React from "react";
import {
  Create,
  SimpleForm,
} from 'react-admin'
import ListActions from '../common/ListActions'
import {
  ActionTitles,
  RESOURCES,
  RESOURCES_BASEPATH,
} from '../Constants'
import {
  contentCenter,
  simpleForm,
} from '../Styles'
import CustomToolbar from "../common/FormToolbar";
import {StatusMessagesCreateEditGrid} from "./StatusMessageEdit";

export const StatusMessageCreate = () => (
  <Create
    actions={<ListActions title={ActionTitles.STATUS_MESSAGE_CREATE} />}
    sx={contentCenter}
  >
    <SimpleForm
      toolbar={
        <CustomToolbar
          buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
          buttontitle2={ActionTitles.CREATE}
          isEdit={true}
          basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
          resource={RESOURCES.STATUS_MESSAGES}
        />
      }
      mode="onBlur"
      reValidateMode="onBlur"
      noValidate
      sx={simpleForm}
    >
      <StatusMessagesCreateEditGrid />
    </SimpleForm>
  </Create>
);