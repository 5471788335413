import { Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import {logoField} from '../Styles'

export const CustomLogoField = () => {
  const record = useRecordContext();

  return (
    <Grid container>
      <Grid xs={12}>
        {record["logo"] !== "" && (
          <img
            src={`data:image/png;base64,${record["logo"]}`}
            style={{...logoField}}
            alt=""
          />
        )}
      </Grid>
    </Grid>
  )
};
