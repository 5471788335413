import { Grid } from "@mui/material";
import { Pagination, useListPaginationContext } from "react-admin";
import {dataGrid,float} from '../Styles'

export const CustomPagination = () => {  
  const { props } = useListPaginationContext();
      
  return(
    <Grid sx={dataGrid.pagination}>
      <Pagination sx={float.left} {...props} />
    </Grid>
  );

};