import { useWatch, useFormContext } from 'react-hook-form';
import React from 'react';
import {SelectInput,Error,TextInput,NumberInput,Button} from 'react-admin'
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import {SURVEY_TYPES} from '../Constants'
import { requiredValidation, createSurveyChoicesDynamically } from '../../utils/util';
import {iconAction,padding2,removeNumberArrowsStyle,textColor,topActionButtonBaseStyle,width} from '../Styles'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export const SurveyDynamicFields = (props) => {
  const values = useWatch();
  const [surveys, setSurveys] = React.useState([]);  
  const form = useFormContext();
  const sourceOfFundsSurveyField = props.sourceOfFundsSurveyField;
  const factFindSurveyField = props.factFindSurveyField;

  React.useEffect(() => {
    const surveysByReportType = props['clientSurveys'].filter((survey) => survey.reportType === values.reportType);
    setSurveys(surveysByReportType);
  }, [values.reportType, props]);

  // updating selected survey
  const updateSurveyValue = (event) => {
    const survey = surveys.find((survey) => survey.id === event.target.value);
    if (survey?.surveyType === SURVEY_TYPES.FACT_FIND || event.target.name === 'factFindSurvey') {
      resetSurveyInputParams(false, true)
      form.setValue('selectedFactFindSurvey', survey || null);
    } else if (survey?.surveyType === SURVEY_TYPES.SOURCE_OF_FUNDS || event.target.name === 'sourceOfFundsSurvey') {
      resetSurveyInputParams(true, false)
      form.setValue('selectedSourceOfFundsSurvey', survey || null);
    }
  };

  let sourceOfFundsSurveys = surveys.filter((survey) => survey.surveyType === SURVEY_TYPES.SOURCE_OF_FUNDS);
  let factFindSurveys = surveys.filter((survey) => survey.surveyType === SURVEY_TYPES.FACT_FIND);
  // filtering based on possible values from template
  if (
    sourceOfFundsSurveyField &&
    sourceOfFundsSurveyField.possibleValues &&
    sourceOfFundsSurveyField.possibleValues.length > 0
  ) {
    sourceOfFundsSurveys = createSurveyChoicesDynamically(
      sourceOfFundsSurveys,
      sourceOfFundsSurveyField.possibleValues
    );
  }

  if (factFindSurveyField && factFindSurveyField.possibleValues && factFindSurveyField.possibleValues.length > 0) {
    factFindSurveys = createSurveyChoicesDynamically(factFindSurveys, factFindSurveyField.possibleValues);
  }

  const resetSurveyInputParams = (resetSof, resetFactFind) => {    
    const sofParams = Object.keys(values).filter((key) => key.includes('sof-field'));
    const factFindParams = Object.keys(values).filter((key) => key.includes('factfind-field'));
    if (resetSof) {
      sofParams.forEach((field) => {
        form.setValue(field, '');
        if (field.includes('default')) form.setValue(`default-sof-value-${field.substring(field.lastIndexOf('-') + 1)}`);
        else form.setValue(`sof-value-${field.substring(field.lastIndexOf('-') + 1)}`);
      });
    }
    if (resetFactFind) {
      factFindParams.forEach((field) => {
        form.setValue(field, '');
        if (field.includes('default'))
          form.setValue(`default-factfind-value-${field.substring(field.lastIndexOf('-') + 1)}`);
        else form.setValue(`factfind-value-${field.substring(field.lastIndexOf('-') + 1)}`);
      });
    }
  };

  // cleaning form on case of template change
  React.useEffect(() => {
    function clearSelectedSurveys() {      
      resetSurveyInputParams(true,true);
      form.setValue('selectedFactFindSurvey', '');
      form.setValue('selectedSourceOfFundsSurvey', '');
      form.setValue('sourceOfFundsSurvey', '');
      form.setValue('factFindSurvey', '');
      // defaulting surveys incase there was only 1 choice available and field was required
      if (sourceOfFundsSurveyField && sourceOfFundsSurveyField.required && sourceOfFundsSurveys.length === 1) {
        form.setValue('selectedSourceOfFundsSurvey', sourceOfFundsSurveys[0]);
        form.setValue('sourceOfFundsSurvey', sourceOfFundsSurveys[0].id);
      }
      if (factFindSurveyField && factFindSurveyField.required && factFindSurveys.length === 1) {
        form.setValue('selectedFactFindSurvey', factFindSurveys[0]);
        form.setValue('factFindSurvey', factFindSurveys[0].id);
      }
    }
    clearSelectedSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTemplateId, sourceOfFundsSurveyField, factFindSurveyField, surveys, values.reportType]);

  if (props.clientSurveysError) return <Error />;
  return (
    <React.Fragment>
      {factFindSurveys.length > 0 && (
        <React.Fragment>
          <Grid // there should be no input params else defaulted survey's can't be hidden
            sx={{
              ...(factFindSurveyField &&
              ((factFindSurveyField.isHidden && !factFindSurveyField.required) ||
                (factFindSurveyField.isHidden && factFindSurveyField.required && factFindSurveys.length === 1 &&
                  values.selectedFactFindSurvey &&
                  !values.selectedFactFindSurvey.inputParams))
              ? { display: "none" } : {} )
            }}
          >
            <ListItem sx={padding2.y}>
              <Grid container>
                <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: "15px" } }}>
                  {factFindSurveyField ? factFindSurveyField.displayName : 'Fact find questionnaire'}
                  {factFindSurveyField && factFindSurveyField.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <React.Fragment>
                    <SelectInput
                      sx={{...width.fluid.w100, marginBottom: "-15px"}}
                      source="factFindSurvey"
                      label={
                        factFindSurveyField && factFindSurveyField.placeholder
                          ? factFindSurveyField.placeholder
                          : 'Select a questionnaire'
                      }
                      variant="outlined"                      
                      size="small"
                      choices={factFindSurveys}
                      defaultValue={
                        factFindSurveyField && factFindSurveyField.required && factFindSurveys.length === 1
                          ? factFindSurveys[0].id
                          : ''
                      }
                      onChange={updateSurveyValue}
                      validate={factFindSurveyField?.required ? requiredValidation : null}
                    />
                    {values.selectedFactFindSurvey && (
                      <SurveyParameters
                        isFactFindSurvey={true}
                        factFindInputParams={values.selectedFactFindSurvey.inputParams}
                      />
                    )}
                  </React.Fragment>
                </Grid>
              </Grid>
            </ListItem>
            <Divider light />
          </Grid>
        </React.Fragment>
      )}
      {sourceOfFundsSurveys.length > 0 && (
        <React.Fragment>
          <Grid
            // there should be no input params else deafulted survey's can't be hidden
            sx={{
              ...(sourceOfFundsSurveyField &&
                ((sourceOfFundsSurveyField.isHidden && !sourceOfFundsSurveyField.required) ||
                  (sourceOfFundsSurveyField.isHidden && sourceOfFundsSurveyField.required && sourceOfFundsSurveys.length === 1 &&
                    values.selectedSourceOfFundsSurvey &&
                    !values.selectedSourceOfFundsSurvey.inputParams))
                ? { display: "none" } : {} )
            }}
          >
            <ListItem sx={padding2.y}>
              <Grid container>
                <Grid item xs={12} md={4} sx={{...textColor.gray, marginTop: { md: "15px" } }}>
                  {sourceOfFundsSurveyField ? sourceOfFundsSurveyField.displayName : 'Source of funds questionnaire'}
                  {sourceOfFundsSurveyField && sourceOfFundsSurveyField.required &&
                    <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
                  }
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                  <React.Fragment>
                    <SelectInput
                      sx={{...width.fluid.w100, marginBottom: "-15px"}}
                      source="sourceOfFundsSurvey"
                      label={
                        sourceOfFundsSurveyField && sourceOfFundsSurveyField.placeholder
                          ? sourceOfFundsSurveyField.plcaeholder
                          : 'Select a questionnaire'
                      }
                      variant="outlined"
                      size="small"
                      choices={sourceOfFundsSurveys}
                      defaultValue={
                        sourceOfFundsSurveyField &&
                        sourceOfFundsSurveyField.required &&
                        sourceOfFundsSurveys.length === 1
                          ? sourceOfFundsSurveys[0].id
                          : ''
                      }
                      onChange={updateSurveyValue}
                      validate={sourceOfFundsSurveyField?.required ? requiredValidation : null}
                    />
                    {values.selectedSourceOfFundsSurvey && (
                      <SurveyParameters
                        isFactFindSurvey={false}
                        factFindSurveys={factFindSurveys}
                        sourceOfFundsInputParams={values.selectedSourceOfFundsSurvey.inputParams}
                      />
                    )}
                  </React.Fragment>
                </Grid>
              </Grid>
            </ListItem>
            <Divider light />
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const SurveyParameters = (props) => {
  const values = useWatch();
  const form = useFormContext();
  const [factFindParams, setFactFindParams] = React.useState([]);
  const [sofParams, setSofParams] = React.useState([]);
  const [factFindKeyCounter, setFactFindKeyCounter] = React.useState({ value: 0 });
  const [sofKeyCounter, setSofKeyCounter] = React.useState({ value: 0 });
  const factFindInputParams = props.factFindInputParams;
  const sourceOfFundsInputParams = props.sourceOfFundsInputParams;
  const isFactFindSurvey = props.isFactFindSurvey;
  const showAddButton = process.env.REACT_APP_SHOW_MANUAL_PARAMS_BUTTON

  const addFactFindParams = () => {
    setFactFindParams([
      ...factFindParams,
      {
        name: `factfind-field-${factFindKeyCounter.value}`,
        value: '',
      },
    ]);
    setFactFindKeyCounter({ value: factFindKeyCounter.value + 1 });
  };

  const removeFactFindParam = (id) => {
    form.setValue(`factfind-field-${id}`, undefined);
    const filteredParams = factFindParams.filter((item) => item.name !== 'factfind-field-' + id);
    setFactFindParams(filteredParams);
  };

  const addSofParams = () => {
    setSofParams([
      ...sofParams,
      {
        name: `sof-field-${sofKeyCounter.value}`,
        value: '',
      },
    ]);
    setSofKeyCounter({ value: sofKeyCounter.value + 1 });
  };

  const removeSofParams = (id) => {
    form.setValue(`sof-field-${id}`, undefined);
    const filteredParams = sofParams.filter((item) => item.name !== 'sof-field-' + id);
    setSofParams(filteredParams);
  };
  
  return (
    <React.Fragment>
      {showAddButton === 'true' && (
        <Button
          variant="contained"
          onClick={isFactFindSurvey ? addFactFindParams : addSofParams}
          sx={{...topActionButtonBaseStyle("white", "#383848", "#181822")}}
          size="small"
          label="Add"
        >
          <AddCircleOutlineIcon />
        </Button>
      )}
      {((isFactFindSurvey && values.selectedFactFindSurvey) ||
        (!isFactFindSurvey && values.selectedSourceOfFundsSurvey)) && (
        <CreateDefaultParamsForSurveys
          inputParams={isFactFindSurvey ? factFindInputParams : sourceOfFundsInputParams}
          isFactFindSurvey={isFactFindSurvey}
        />
      )}
      {(isFactFindSurvey ? factFindParams : sofParams).map((item) => (
        <>
          <Grid container sx={{ alignItems: { xs: "center", md: "normal" } }}>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source={item.name}
                    name={item.name}
                    label="field"
                    variant="outlined"
                    size="small"
                    sx={{ ...width.fluid.w100, paddingRight: "10px", marginBottom: "-15px" }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    source={
                      (isFactFindSurvey ? `factfind-value` : `sof-value`) + item.name.substring(item.name.lastIndexOf('-'))
                    }
                    name={(isFactFindSurvey ? `factfind-value` : `sof-value`) + item.name.substring(item.name.lastIndexOf('-'))}
                    label="value"
                    variant="outlined"
                    size="small"
                    sx={{ ...width.fluid.w100, paddingRight: "10px", marginBottom: "-15px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{...iconAction, marginTop: { xs: 0, md: "12px"} }}
                size="medium"
                onClick={() =>
                  isFactFindSurvey
                    ? removeFactFindParam(item.name.substring(item.name.lastIndexOf('-') + 1))
                    : removeSofParams(item.name.substring(item.name.lastIndexOf('-') + 1))
                }
              >
                <DeleteOutlinedIcon/>
              </Button>
            </Grid>
          </Grid>
          <Divider light />
        </>
      ))}
    </React.Fragment>
  );
};

const CreateDefaultParamsForSurveys = (props) => {
  let components = [];
  if (!props.inputParams) return components;
  const sortedParams = props.inputParams.sort((a, b) => a.position - b.position);
  const isFactFindSurvey = props.isFactFindSurvey;
  sortedParams.forEach((param) => {
    let choices = [];
    if (param.type === 'Dropdown') choices = createChoicesForDropdown(param.possibleValues);
    //if (isFactFindSurvey) form.setValue(`default-factfind-field-${param.name}`, '');  //Intentionally commenting and solving it through parsing operation
    //else form.setValue(`default-sof-field-${param.name}`, '');
    const item = (
      <React.Fragment>
        <Grid container sx={{ marginTop: "5px" }}>
          <Grid xs={5} sx={{ marginTop: "15px" }}>
            {param.displayName}
            {param.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
          </Grid>
          <Grid xs={7}>
            {param.type === 'Textfield' && (
              <TextInput
                source={isFactFindSurvey ? `default-factfind-value-${param.name}` : `default-sof-value-${param.name}`}
                name={isFactFindSurvey ? `default-factfind-value-${param.name}` : `default-sof-value-${param.name}`}
                label={param.placeholder ? param.placeholder : ''}
                validate={param.required && requiredValidation}
                defaultValue={param.defaultValue}
                variant="outlined"
                size="small"
                sx={{ ...width.fluid.w100, marginBottom: "-10px" }}
              />
            )}
            {param.type === 'NumberInput' && (
              <NumberInput
                onWheel={(e) => {
                  e.target.blur()
                }}
                source={isFactFindSurvey ? `default-factfind-value-${param.name}` : `default-sof-value-${param.name}`}
                name={isFactFindSurvey ? `default-factfind-value-${param.name}` : `default-sof-value-${param.name}`}
                label={param.placeholder ? param.placeholder : ''}
                defaultValue={param.defaultValue}
                validate={param.required && requiredValidation}
                variant="outlined"
                size="small"
                onKeyPress={(e) => {
                  if (e.key === 'e') {
                    e.preventDefault();
                  }
                }}
                sx={{ ...removeNumberArrowsStyle, ...width.fluid.w100, marginBottom: "-10px" }}
              />
            )}
            {param.type === 'Dropdown' && (
              <SelectInput
                source={isFactFindSurvey ? `default-factfind-value-${param.name}` : `default-sof-value-${param.name}`}
                name={isFactFindSurvey ? `default-factfind-value-${param.name}` : `default-sof-value-${param.name}`}
                label={param.placeholder ? param.placeholder : ''}
                defaultValue={param.defaultValue}
                validate={param.required && requiredValidation}
                variant="outlined"
                choices={choices}                
                size="small"
                sx={{ ...width.fluid.w100, marginBottom: "-10px" }}
              />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
    components.push(item);
  });
  return components;
};

export const createChoicesForDropdown = (possibleValues) => {
  let choices = [];
  possibleValues.forEach((choice) => {
    choices.push({
      id: choice,
      name: choice,
    });
  });
  return choices;
};

export default SurveyDynamicFields;
