import { SimpleForm, SelectInput } from "react-admin";

const CSVSelector = (props) => {
  const {
    choices,
    defaultValue,
    onChange,
  } = props;

  return (
    <SimpleForm toolbar={null}>
      <SelectInput
        source="reportFormat"
        label={false}
        variant="standard"
        onChange={onChange}
        choices={choices}
        defaultValue={defaultValue}        
        size="small"
        alwaysOn
        style={{maxWidth : "100%"}}
      />
    </SimpleForm>
  );
}

export default CSVSelector;