import * as React from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import { Show, SimpleShowLayout, useRecordContext } from 'react-admin'
import ListActions from '../common/ListActions'
import { ActionTitles, ENABLED_FEATURES_ENUM, RESOURCES, RESOURCES_BASEPATH } from '../Constants'
import { avatar, contentCenter, float, padding0, padding2, textColor } from '../Styles'
import { isAdminUser, isFeatureEnabled } from "../DataProvider";

export const UserShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.USER_DETAILS}
        showEditButton={true}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.USERS}
        resource={RESOURCES.USERS}
        recordName={ActionTitles.USER}
      />
    }
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <UserShowGrid />
    </SimpleShowLayout>
  </Show>
);

const UserShowGrid = () => {
  const record = useRecordContext();
  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListComponentForUser title="ID" value={record["id"]} />
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>Avatar</Grid>
              <Grid xs={12} md={8}>
                <img
                  src="Avatar.jpg"
                  alt="Avatar Icon"
                  style={{ ...avatar.medium }}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListComponentForUser title="First Name" value={record["firstName"]} />
          <Divider light />
          <ListComponentForUser title="Last Name" value={record["lastName"]} />
          <Divider light />
          <ListComponentForUser title="Email" value={record["email"]} />
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={10} md={4} sx={textColor.gray}>Email Report Notification</Grid>
              <Grid xs={2} md={8}>
                {
                  record["emailReportNotifications"] ?
                    (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                    (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          {(isAdminUser() || isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT)) &&
            <>
              <ListItem sx={padding2.y}>
                <Grid container>
                  <Grid xs={10} md={4} sx={textColor.gray}>Email Statement Conversion Notification</Grid>
                  <Grid xs={2} md={8}>
                    {
                      record["emailStatementConversionNotifications"] ?
                        (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                        (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                    }
                  </Grid>
                </Grid>
              </ListItem>
              <Divider light />
            </>
          }
          <ListComponentForUser title="Secondary Email" value={record["secondaryEmail"]} />
          <Divider light />
          <ListComponentForUser title="Created" value={record["created"]} />
          <Divider light />
          <ListComponentForUser
            title="Last Modified"
            value={record["lastModified"]}
          />
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={10} md={4} sx={textColor.gray}>2FA</Grid>
              <Grid xs={2} md={8}>
                {
                  record["multiFAConfigured"] ?
                    (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                    (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={10} md={4} sx={textColor.gray}>Enforce 2FA</Grid>
              <Grid xs={2} md={8}>
                {
                  record["multiFAConfigured"] ?
                    "--" :
                    record["enforceForMultiFAConfiguration"] ?
                      (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
                      (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
                }
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
};

const ListComponentForUser = (props) => {
  return (
    <ListItem sx={padding2.y}>
      <Grid container>
        <Grid item xs={12} md={4} sx={textColor.gray}>{props.title}</Grid>
        <Grid item xs={12} md={8}>{props.value}</Grid>
      </Grid>
    </ListItem>
  );
};