import {useState} from "react";
import { httpClient } from '../DataProvider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CustomCheckbox from '../../utils/CustomCheckbox';
import InputLabel from '@mui/material/InputLabel';
import React from "react";
import {Button} from 'react-admin'
import {padding0,padding2,padding3,topActionButtonBaseStyle} from '../Styles'
import {ActionTitles} from '../Constants'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {CircularProgress,Grid} from '@mui/material'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const SendEmailDialog = (props) => {
  const [scheduleReminders, setScheduleReminders] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const sendAutomatedEmail = () => {
    setDisabled(true); //disable the send button to avoid double click
    const notificationApi = `${apiUrl}/api/report-request/send-notif`;
    const requestBody = {
      "clientRequestId" : props.clientRequestId,
      scheduleReminders
    };
    httpClient(notificationApi, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    }).then(() => {
      props.handleSuccess()
    }).finally(() => setDisabled(false))
  };

  const toggleScheduleReminders = () => setScheduleReminders(!scheduleReminders);

  return (
    <Dialog open onClose={props.handleDialogClose} fullWidth={true}>
      <DialogTitle id="send-automated-email">Send Email</DialogTitle>
      <DialogContent>
        Are you sure you want to send the automated email?
      </DialogContent>
      <DialogActions sx={{...padding0.top, ...padding2.bottom, ...padding3.x}}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center" flexGrow={0}>
              <CustomCheckbox
                checked={scheduleReminders}
                onChange={toggleScheduleReminders}
                name="scheduleReminders"
              />
              <InputLabel>Send Reminder Emails</InputLabel>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              onClick={props.handleDialogClose}
              sx={{...topActionButtonBaseStyle("gray","#fff","#eaeaea","#b2afaf","#9B9999")}}
              size="small"
              variant="outlined"
              label={ActionTitles.CANCEL}
            >
              <CancelIcon/>
            </Button>
            <Button
              variant="contained"
              onClick={sendAutomatedEmail}
              sx={{...topActionButtonBaseStyle()}}
              size="small"
              label="Send"
              disabled={disabled}
            >
              {
                disabled ?
                  <CircularProgress
                    size={14}
                    thickness={3}
                    color="inherit"
                  /> :
                  <SendOutlinedIcon/>
              }
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SendEmailDialog;
