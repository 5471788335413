import { 
  REPORT_TYPES,
  SURVEYS_ASSIGNMENT, 
  SURVEY_TYPES
} from "../components/Constants";
import isEmpty from 'lodash/isEmpty'

const reportTypes = Object.values(REPORT_TYPES);

export function parseSurveyChoices(data) {
  if(!isEmpty(data)){
    
    data.forEach(element => {      
      element["id"] = element["surveyId"];
      element["name"] = element["surveyName"];
      
      delete element["createdBy"];
      delete element["createdDate"];
      delete element["inputParams"];
      delete element["lastModified"];
      delete element["outputParams"];
      delete element["surveyData"];
      delete element["surveyDisplayName"];
      delete element["surveyId"];
      delete element["surveyName"];
    });
    
  }
  return data;
}
export function parseMappedSurvey(data) {                
    data.forEach(element => {                    
      element["name"] = element["surveyName"];
            
      delete element["beforeAccounts"];
      delete element["clientId"];                                                
      delete element["inputParams"];  
      delete element["outputParams"];                  
      delete element["surveyName"];                        
      delete element["surveyType"]; 
      delete element["surveyDisplayName"]; 
      delete element["weight"];                            
    });
    return data;
}

export function parseMappedSurveysbyReportType(data) {

    const parsedData = parseMappedSurvey(data);
    const reportTypes = Object.values(REPORT_TYPES);
    const result = {};
    
    reportTypes.forEach((report)=>{
        result[report] = parsedData.filter(data => data.reportType === report);
    });

    return result;
}

export function parseSurveyListToObject(data, allSurvey) {
  return data.map((element) =>{
    const obj = {};
    obj.surveyId = element;
    if(isFactFindSurvey(element, allSurvey)){
      obj.beforeAccounts = true;
    }
    return obj;
  });
}

//The beforeAccounts value should be set to true for every mapped fact find survey.
export function isFactFindSurvey(surveyId, allSurvey) {
  const selectedSurvey = allSurvey.filter(survey => survey.id === surveyId);
  return selectedSurvey[0].surveyType === SURVEY_TYPES.FACT_FIND;
}

export function extractDefaultValues(data) {
    if(!isEmpty(data)){
        return data.map(element => element.surveyId);
    }
    return;
}

export function parseSurveysForAssignment(reportType, mappedSurveys, postSelectedSurveys, allSurvey) {
  const mappedSurveysList = mappedSurveys.map(element => element.surveyId);
  const surveysToMap = postSelectedSurveys.filter(surveyId => !mappedSurveysList.includes(surveyId));
  const clientSurveysToUnmap = mappedSurveys.filter(element => !postSelectedSurveys.includes(element.surveyId));

  const data = {};
  data[SURVEYS_ASSIGNMENT.REPORT_TYPE] = reportType;
  data[SURVEYS_ASSIGNMENT.MAP] = parseSurveyListToObject(surveysToMap, allSurvey);
  data[SURVEYS_ASSIGNMENT.UNMAP] = clientSurveysToUnmap;

  return JSON.stringify(data);
}

export function parseSurveysForCreateAssignment(reportType, surveysToMap, allSurvey) {
  
  const data = {};
  data[SURVEYS_ASSIGNMENT.REPORT_TYPE] = reportType;
  data[SURVEYS_ASSIGNMENT.MAP] = parseSurveyListToObject(surveysToMap, allSurvey);
  data[SURVEYS_ASSIGNMENT.UNMAP] = [];

  return JSON.stringify(data);
  
}

export function generateUnarchivedMappedSurveys(mappedSurveys, allSurvey) {
  
  const surveyIds = allSurvey.map(element => element.id);
  var result = {};

  reportTypes.forEach((report)=>{
    result[report] = mappedSurveys[report].filter(element => surveyIds.includes(element.surveyId));
  });

  return result;
}

export function isMappedSurveyEmpty(mappedSurveys) {
  
  var result = true;

  reportTypes.forEach((report)=>{
    result = result && isEmpty(mappedSurveys[reportTypes])
  });
  
  return result;
}