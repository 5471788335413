import { Grid } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import { TextInput } from "ra-ui-materialui";
import { required, useRecordContext } from "react-admin";
import { DEFAULT_COLOURS } from "../Constants";
import { validateColor } from "../../utils/util";
import {colorField, padding2, textColor} from '../Styles'

const validate = required("This is a mandatory field.");
const colorFieldValidations = [validate, validateColor]
const ColorFields = () => {
  const record = useRecordContext();
  const recordPresent =
    record != null &&
    Object.keys(record).length !== 0 &&
    record.constructor === Object
      ? true
      : false;

  const [primaryColour, setPrimaryColour] = useState(
    recordPresent ? record["primaryColour"] : DEFAULT_COLOURS.PRIMARY_COLOUR
  );

  const [secondaryColour, setSecondaryColour] = useState(
    recordPresent ? record["secondaryColour"] : DEFAULT_COLOURS.SECONDARY_COLOUR
  );

  const [tertiaryColour, setTertiaryColour] = useState(
    recordPresent ? record["tertiaryColour"] : DEFAULT_COLOURS.TERTIARY_COLOUR
  );

  const handlePrimaryColourChange = (val) => {
    setPrimaryColour(String(val.target.value));
  };

  const handleSecondaryColourChange = (val) => {
    setSecondaryColour(String(val.target.value));
  };

  const handleTertiaryColourChange = (val) => {
    setTertiaryColour(String(val.target.value));
  };

  return (
    <React.Fragment>
      <ListItem sx={padding2.y} key="primaryColour">
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            Primary Colour
            <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Grid container>
              <Grid sx={{...colorField.inputStyle.pad, backgroundColor: primaryColour}} />
              <Grid>
                <TextInput
                  name="primaryColour"
                  source="primaryColour"
                  defaultValue={primaryColour}
                  variant="outlined"
                  label="Primary Colour"
                  size="small"
                  validate={colorFieldValidations}
                  onChange={handlePrimaryColourChange}
                  sx={{...colorField.inputStyle.input, marginBottom: "-15px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
      <ListItem sx={padding2.y} key="secondaryColour">
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            Secondary Colour
            <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Grid container>
              <Grid sx={{...colorField.inputStyle.pad, backgroundColor: secondaryColour}} />
              <Grid>
                <TextInput
                  name="secondaryColour"
                  source="secondaryColour"
                  defaultValue={secondaryColour}
                  label="Secondary Colour"
                  variant="outlined"
                  size="small"
                  validate={colorFieldValidations}
                  onChange={handleSecondaryColourChange}
                  sx={{...colorField.inputStyle.input, marginBottom: "-15px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
      <ListItem sx={padding2.y} key="tertiaryColour">
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            Tertiary Colour
            <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Grid container>
              <Grid sx={{...colorField.inputStyle.pad, backgroundColor: tertiaryColour}} />
              <Grid>
                <TextInput
                  name="tertiaryColour"
                  source="tertiaryColour"
                  defaultValue={tertiaryColour}
                  label="Tertiary Colour"
                  variant="outlined"
                  size="small"
                  validate={colorFieldValidations}
                  onChange={handleTertiaryColourChange}
                  sx={{...colorField.inputStyle.input, marginBottom: "-15px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </React.Fragment>
  );
};

export default ColorFields;
