import { margin0, pageBox, pageCentered, warningMessage } from "../Styles";

export const ClientSuspendedComponent = () => {
  return (
    <div style={pageCentered}>
      <div style={pageBox}>
        <h1 style={warningMessage}>Access Denied!</h1>
        <h2 style={margin0.default}>
          Your account has been suspended.<br></br> Please contact your Armalytix
          representative for assistance.
        </h2>
      </div>
    </div>
  );
};
