import { ChipField, useRecordContext } from "react-admin";
import { parseLabelColor } from "../../mappers/StatusMessages";

const LabelField = (props) => {
  const record = useRecordContext();

  return (
    <ChipField source={props.source} label="TYPE" color={parseLabelColor(record[props.source])} />
  )
};

export default LabelField;
