import * as React from "react";
import {
  Filter,
  TextInput,
  SelectInput,
  required,
} from "react-admin";
import { SEARCH_BY_FIELDS_USERS } from "../Constants";

export const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      source="searchValue"
      size="small"
      variant="outlined"
      alwaysOn
      resettable
    />
    <SelectInput
      label="Search By"
      source="searchKey"
      choices={SEARCH_BY_FIELDS_USERS}
      size="small"
      variant="outlined"
      validate={required()} //to disable empty value.
      alwaysOn
    />
  </Filter>
);