import * as React from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {
  Show,
  SimpleShowLayout,
  useRecordContext
} from 'react-admin'
import ListActions from '../common/ListActions'
import {
  ActionTitles,
  RESOURCES,RESOURCES_BASEPATH
} from '../Constants'
import {
  contentCenter,
  padding0,
  padding2,
  textColor
} from '../Styles'
import LabelField from "../common/LabelField";
import ReactHtmlParser from "react-html-parser";
import {parseDateToDateAndTimeString} from "../../utils/util";

export const StatusMessageShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.STATUS_MESSAGE_DETAILS}
        showEditButton={true}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
        resource={RESOURCES.STATUS_MESSAGES}
        recordName={ActionTitles.STATUS_MESSAGES}
      />
    }
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <StatusMessagesShowGrid />
    </SimpleShowLayout>
  </Show>
);

const StatusMessagesShowGrid = (props) => {
  const record = useRecordContext();
  const effectiveFromDate = parseDateToDateAndTimeString(new Date(record['effectiveFrom']))
  const effectiveToDate = parseDateToDateAndTimeString(new Date(record['effectiveTo']))

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <ListComponentForStatusMessage title="ID" value={record["id"]} />
          <Divider light />
          <ListComponentForStatusMessage title="Name" value={record["name"]} />
          <Divider light />
          <ListComponentForStatusMessage title="Components" value={record["components"]} />
          <Divider light />
          <ListComponentForStatusMessage title="Effective From" value={effectiveFromDate} />
          <Divider light />
          <ListComponentForStatusMessage title="Effective To" value={effectiveToDate} />
          <Divider light />
          <ListComponentForStatusMessage title="Effective To" value={
            <LabelField source="type" label="TYPE" sortable={false} />
          } />
          <Divider light />
          <ListComponentForStatusMessage title="Effective To" value={ReactHtmlParser(record["message"])} />
        </List>
      </Grid>
    </Grid>
  )
};

const ListComponentForStatusMessage = (props) => {
  return (
    <ListItem sx={padding2.y}>
      <Grid container>
        <Grid item xs={12} md={4} sx={textColor.gray}>{props.title}</Grid>
        <Grid item xs={12} md={8}>{props.value}</Grid>
      </Grid>
    </ListItem>
  );
};