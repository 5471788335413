import * as React from "react";
import {
  Edit,
  SimpleForm,
  required,
  useRecordContext,
  SelectInput,
} from 'react-admin'
import {ActionTitles, DefaultContentTypes, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import CustomToolbar from "../common/FormToolbar";
import {width,contentCenter,padding0,padding2,textColor,richTextContent,simpleForm} from '../Styles'
import {Grid} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {TextInput} from 'ra-ui-materialui'
import Divider from '@mui/material/Divider'
import ListActions from '../common/ListActions'

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
    default: module.RichTextInput,
  }))
);

export const DefaultContentEdit = () => {
  return (
    <Edit
      actions={
        <ListActions
          title={ActionTitles.DEFAULT_CONTENT_UPDATE}
          showViewButton={true}
          showDeleteButton={true}
          basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
          resource={RESOURCES.DEFAULT_CONTENT}
          recordName={ActionTitles.DEFAULT_CONTENT}
        />
      }
      sx={contentCenter}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
            buttontitle2={ActionTitles.UPDATE_DEFAULT_CONTENT}
            isEdit={true}
            basePath={RESOURCES_BASEPATH.DEFAULT_CONTENT}
            resource={RESOURCES.DEFAULT_CONTENT}
          />
        }
        sx={simpleForm}
      >
        <DefaultContentCreateEditGrid create={false} />
      </SimpleForm>
    </Edit>
  );
};

export const DefaultContentCreateEditGrid = (props) => {
  const record = useRecordContext();
  const validate = required("This is a mandatory field.");

  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid item xs={12} md={3} lg={2} sx={textColor.gray}>
                Type
                <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
              </Grid>
              <Grid item xs={12} md={9} lg={10}>
                {props.create ? (
                  <SelectInput
                    source="contentType" //check this while integration
                    variant="outlined"
                    fullWidth="true"
                    label="Choose a Type"
                    choices={DefaultContentTypes}
                  />
                ) : (
                  <TextInput
                    source="contentType"
                    disabled
                    defaultValue={record["contentType"]}
                    label={false}
                    variant="outlined"
                    sx={width.fluid.w100}
                    validate={validate}
                  />
                )}
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid item xs={12} md={3} lg={2} sx={{...textColor.gray, marginTop: { md: "52px"} }}>Content</Grid>
              <Grid item xs={12} md={9} lg={10}>
                <RichTextInput
                  sx={richTextContent}
                  source="content"
                  variant="outlined"
                  fullWidth={true}
                  minHeight={"300px"}
                  label={false}
                  validate={validate}
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <Divider light />
      </Grid>
    </Grid>
  )
};