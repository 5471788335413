import { Button } from "@mui/material";
import { linkButton } from "../Styles";
import { useState } from "react";
import { useDataProvider, useNotify } from "ra-core";
import Papa from "papaparse";

export const CsvTemplateDownloadButton = () => {
    const [csvData, setCsvData] = useState(undefined);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchBanks = () => {
        return dataProvider.getAllBanks()
            .then((bankData) => {
                return Array.from(bankData.data.values());
            })
            .catch((error) => {
                notify('Something went wrong while fetching Banks.', "warning", { autoHideDuration: 1000 });
                return [];
            });
    }

    const prepareCsvData = async () => {
        const banks = await fetchBanks();
        if (banks.length === 0)
            return;

        return fetch('./armalytix_csv_upload_template.csv')
            .then(response => response.text())
            .then(csvText => {
                let csvData = Papa.parse(csvText, {
                    header: true,
                    skipEmptyLines: true
                });

                const bankData = banks.filter(bank => bank !== "MANUAL BANK")
                    .map(bankName => { 
                        return { bank: bankName } 
                    });

                let csv = Papa.unparse({ ...csvData, data: [...csvData.data, ...bankData] });

                setCsvData(csv);
                return csv;
            });
    }

    const downloadCSV = async () => {
        let data = csvData;
        if (data === undefined)
            data = await prepareCsvData();

        const blob = new Blob([data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "Bank Statement CSV Template";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
                sx={{ ...linkButton, fontWeight: "400", padding: 0 }}
                size="small"
                onClick={downloadCSV}
            >
                Download Sample CSV Template
            </Button>
        </div>
    );
}