import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/date-picker.css";
import { useFormContext, useWatch } from 'react-hook-form';
import {Grid} from '@mui/material'

const DateTimePicker = (props) => {
  const form = useFormContext();
  const values = useWatch();

  const [dateValue, setDateValue] = React.useState(values && values[props.source] ?
    new Date(values[props.source]) :
    props.isStartDate ?
      props.defaultStartDate ?
        new Date(props.defaultStartDate) :
        new Date() :
    props.isEndDate ?
      props.defaultEndDate ?
        new Date(props.defaultEndDate) :
        new Date() :
    new Date()
  );

  if (!values[props.source]) {
    form.setValue(props.source, dateValue.toISOString());
  }

  const handleDateChange = (val) => {
    setDateValue(val);
    form.setValue(props.source, new Date(val).toISOString(), { shouldValidate: true });
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={9}>
          <DatePicker
            showTimeSelect
            dateFormat="dd/MM/yyyy hh:mm"
            popperPlacement="top"
            wrapperClassName="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall"
            onChangeRaw={handleDateChangeRaw}
            onFocus={(ev) => {
              ev.preventDefault()
              ev.target.setAttribute('inputmode', 'none')
            }}
            popperModifiers={{
              flip: {
                behavior: ["top"], // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
              },
            }}
            selected={dateValue}
            onChange={handleDateChange}
            dropdownMode="select"
            minDate={props.minDate ? props.minDate : null}
            maxDate={props.maxDate ? props.maxDate : null}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DateTimePicker;
