import { BooleanInput, SelectInput, useRedirect } from "react-admin";
import { useWatch, useFormContext } from 'react-hook-form';
import React from "react";
import { requiredValidation, followUpDataRangeValidation } from "../../utils/util";
import { CommonInputForCreateReportGrid } from "../common/TemplateInputsForCreateReportGrid";
import { createFollowUpDataRangeChoices, getDefaultValueForFollowUpDataRange, showFollowUpDataRangeInput, showPayslipVerificationInput } from "../../utils/CreateReportUtils";
import { allowCreationOfFollowUpReport } from "../../mappers/Report";
import Button from '@mui/material/Button';
import isEmpty from "lodash/isEmpty"
import { linkButton, width } from '../Styles'

const followUpDataRangeRequiredValidations = [requiredValidation, followUpDataRangeValidation]

const IncomeDynamicFields = (props) => {
  const values = useWatch();
  const { payslipVerificationField, followUpDataRangeField } = props;
  const form = useFormContext();

  if (followUpDataRangeField) {  //Hide this input only when it's not required and don't have defaultValue
    if (!(followUpDataRangeField.isHidden && (!followUpDataRangeField.required && isEmpty(followUpDataRangeField.defaultValue)))) {
      followUpDataRangeField.isHidden = false;
    }
  }

  const followUpDataRangeChoices = createFollowUpDataRangeChoices(followUpDataRangeField?.possibleValues);
  const followUpDataRangeDefaultValue = getDefaultValueForFollowUpDataRange(followUpDataRangeField, followUpDataRangeChoices);

  React.useEffect(() => {
    if (showFollowUpDataRangeInput(values.reportType)) {
      form.setValue("followUpDataRange", followUpDataRangeDefaultValue);
    } else {
      form.setValue("followUpDataRange", undefined);
    }

    if (showPayslipVerificationInput(values.reportType)) {
      form.setValue("payslipVerification", payslipVerificationField?.defaultValue);
    } else {
      form.setValue("payslipVerification", false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reportType, props.selectedTemplateId, followUpDataRangeField?.defaultValue, payslipVerificationField?.defaultValue]);

  return (
    <>
      {showFollowUpDataRangeInput(values.reportType) &&
        <CommonInputForCreateReportGrid
          field={followUpDataRangeField}
          defaultDisplayName="Follow Up Data Range"
          input={
            <SelectInput
              source="followUpDataRange"
              name="followUpDataRange"
              variant="outlined"
              size="small"
              label={followUpDataRangeField ? followUpDataRangeField?.placeholder : "Up to 2 years can be selected, data range as an option"}
              choices={followUpDataRangeChoices}
              defaultValue={followUpDataRangeDefaultValue}
              validate={followUpDataRangeField?.required ? followUpDataRangeRequiredValidations : null}
              allowEmpty={!followUpDataRangeField?.required}
              sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
            />
          }
        />
      }
      {
        showPayslipVerificationInput(values.reportType) &&
        <CommonInputForCreateReportGrid
          field={payslipVerificationField}
          defaultDisplayName="Payslip Verification"
          input={
            <BooleanInput
              defaultValue={payslipVerificationField?.defaultValue}
              source="payslipVerification"
              label=""
              sx={{ marginBottom: "-15px" }}
            />}
        />
      }
    </>
  );
};

export const ExpandReportButton = (props) => {
  const redirect = useRedirect();
  const { record, basePath } = props;

  return (
    allowCreationOfFollowUpReport(record) && (
      <Button
        sx={linkButton}
        onClick={() =>
          redirect(
            basePath +
              "/create/" + record["clientRequestId"] +
              "?secondCreateReport=true"              
          )
        }
      >
        Expand
      </Button>
    )
  );
}

export default IncomeDynamicFields;
