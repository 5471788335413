import {Grid, TableCell, TableHead, TableRow} from '@mui/material'
import React from 'react'
import {actionsDatagridHeader, margin1, float} from '../Styles'
import Tooltip from '@mui/material/Tooltip'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import cloneDeep from 'lodash/cloneDeep';

export const CustomDataGridHeader = ({ children, sort, setSort, onToggle, fixedColumn }) => {
  const sorting = ({ child, sort, setSort }) => {
    const newSort = {}
    if (child.props.source === sort?.field) {
      newSort.field = sort.field
      newSort.order = sort.order === 'ASC' ? 'DESC' : 'ASC'
    } else {
      newSort.field = child.props.source
      newSort.order = 'DESC'
    }
    setSort(newSort)
  }
  return (
    <TableHead>
      <TableRow>
        {React.Children.map(children, (child, index) =>
          React.isValidElement(child) ? (
            <TableCell
              className={"RaDatagrid-headerCell"}
              key={child.props.source}
            >

              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                flexWrap="nowrap"
              >
                <Tooltip title={(!child.props.hasOwnProperty('sortable') || child.props.sortable) ? `Sort by ${child.props.label} ${(child.props.source === sort?.field) ?
                  sort?.order === 'ASC' ?
                    'descending' :
                    'ascending' :
                  'descending'}` : ''}>
                  <Grid
                    sx={(child.props.sortable) ? actionsDatagridHeader : null}
                    onClick={() => (!child.props.hasOwnProperty('sortable') || child.props.sortable) ? sorting({ child, sort, setSort }) : null }
                  >
                    {child.props.label}
                  </Grid>
                </Tooltip>
                {
                  child.props.source === sort?.field ?
                    sort?.order === 'ASC' ?
                      <Grid><ArrowUpwardOutlinedIcon sx={{...margin1.left, ...float.left}} /></Grid> :
                      <Grid><ArrowDownwardOutlinedIcon sx={{...margin1.left, ...float.left}} /></Grid> :
                    null
                }
                {
                  index === 0 ?
                    <Tooltip title={(index === 0) ? `Click to ${fixedColumn ? 'unlock' : 'lock'} the column ${fixedColumn ? 'from' : 'to'} the left` : ''}>
                      <Grid
                        sx={(index === 0) ? actionsDatagridHeader : null}
                        onClick={(index === 0) ? onToggle : null}
                      >
                        {
                          fixedColumn ?
                            <LockOutlinedIcon sx={{...margin1.left, display: { xs: 'none', md: 'block' } }} /> :
                            <LockOpenOutlinedIcon sx={{...margin1.left, display: { xs: 'none', md: 'block' } }} />
                        }
                      </Grid>
                    </Tooltip> :
                    null
                }
              </Grid>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
};

export const onToggleDataGridHeader = (page, dataGrids, setDataGrids) => {
  const clonedDataGrids = cloneDeep(dataGrids)
  clonedDataGrids[page] = !clonedDataGrids[page]
  setDataGrids(clonedDataGrids)
  localStorage.setItem("dataGrids", JSON.stringify(clonedDataGrids))
}