import { Grid, InputAdornment } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { TextInput } from "react-admin";
import { useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty'
import { canFieldBeHidden } from "../../utils/CreateReportUtils";
import {padding2,textColor,width} from '../Styles'
import * as React from 'react'

const TextInputWithCharCount = (props) => {
  const charCountFormValue = useWatch({ name: props.name });
  return (
    <Grid display={canFieldBeHidden(props.hidden, props.required, props.defaultValue) ? "none" : "block"}>
      <ListItem sx={padding2.y}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            {props.title}
            {props.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <TextInput
              source={props.source}
              name={props.name}
              label={props.label}
              variant="outlined"
              size="small"
              sx={{...width.fluid.w100, marginBottom: "-15px"}}
              multiline={props.multiline}
              defaultValue={props.defaultValue}
              validate={props.validate}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!isEmpty(charCountFormValue)
                      ? charCountFormValue.length
                      : props.defaultValue
                      ? props.defaultValue.length
                      : 0}
                    /{props.maxLength}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </ListItem>
    </Grid>
  );
};

export default TextInputWithCharCount;