import { Divider, Grid, ListItem, Button } from "@mui/material";
import React from "react";
import { 
  ArrayInput,  
  FormDataConsumer, 
  NumberInput,  
  SimpleFormIterator, 
  required
} from "react-admin";
import { Button as RaButton } from 'react-admin'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import CustomTooltip from '../../utils/CustomTooltip'
import { positiveValue } from "../../utils/util";
import {
  accordionActionButton,hideHelper,
  iconAction,
  padding2,
  removeNumberArrowsStyle,
  simpleFormIterator,
  simpleFormIteratorAdd,
  simpleFormIteratorHalf,
  textColor,
} from '../Styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export const CustomEmailReminder = (props) => {
  return (
    <>
      <ListItem sx={padding2.y} key="email">
        <Grid container>
          <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: props.isEdit ? "18px" : "10px" } }}>Reminder Intervals</Grid>
          <Grid item xs={12} md={8} lg={5}>
            <ArrayInput sx={hideHelper} source="customReminderIntervals" label={false}>
              <SimpleFormIterator
                disableClear
                sx={{...simpleFormIterator, ...simpleFormIteratorHalf, ...simpleFormIteratorAdd}}
                removeButton={
                  <CustomTooltip title="Remove" placement="top">
                    <Button sx={iconAction} size="medium">
                      <DeleteOutlinedIcon />
                    </Button>
                  </CustomTooltip>
                }
                addButton={
                  <RaButton
                    variant="contained"
                    sx={{...accordionActionButton, marginLeft: "0 !important", marginTop: props.isEdit ? "15px !important" : "8px !important",  }}
                    label="Add"
                    size="small"
                  >
                    <AddCircleOutlineIcon />
                  </RaButton>
                }
              >
                <FormDataConsumer>
                  {({ getSource }) => {
                    return (
                      <Grid container>
                        <Grid item xs={12} md={5}>
                          <NumberInput
                            source={getSource("interval")}
                            label="Interval in day(s)"
                            helperText={false}
                            variant="outlined"
                            size="small"
                            sx={removeNumberArrowsStyle}
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            validate={[required("Please enter interval"), positiveValue]}
                          />
                        </Grid>
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
    </>
  );
};
