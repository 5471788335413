import React, { useEffect, useState } from "react";
import {
  Show,
  SimpleShowLayout,
  useDataProvider,
  useNotify,
  useRecordContext
} from "react-admin";
import { useLocation, useParams } from "react-router-dom";
import { ActionTitles, RESOURCES, SUB_RESOURCES } from "../Constants";
import { Divider, Grid, List, ListItem } from "@mui/material";
import { contentCenter, padding0, padding2, textColor } from "../Styles";
import ListActions from "../common/ListActions";
import { Transactions } from "./Transactions";
import { extractQueryParamsFromURL } from "../../mappers/Report";
import { isAdminUser } from "../DataProvider";

export const ViewAccount = () => {
  const isAdmin = isAdminUser();
  const { matterId, accountId } = useParams();
  const dataProvider = useDataProvider();
  const [bankDataMap, setBankDataMap] = useState(new Map());
  const notify = useNotify();
  const location = useLocation();
  const clientId = extractQueryParamsFromURL(location?.search, 'clientId');

  useEffect(() => {
    fetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanks = () => {
    dataProvider.getAllBanks()
      .then((bankData) => {
        setBankDataMap(bankData.data);
      })
      .catch((error) => {
        notify('Something went wrong while fetching Banks.', "warning", { autoHideDuration: 1000 });
      });
  }

  return (
    <>
      <Show
        actions={
          <ListActions
            title={ActionTitles.MATTER_ACCOUNT_DETAILS}
            showViewButton={true}
            showDeleteButton={false}
            resource={RESOURCES.MATTERS}
            record={{ id: (isAdmin ? 'client/' + clientId + '/matter/' + matterId : matterId) }}
          />
        }
        renderButtonAndSearch={false}
        sx={contentCenter}
        resource={SUB_RESOURCES.ACCOUNTS}
        id={accountId}
        queryOptions={{ meta: { matterId: matterId, clientId: clientId } }}
      >
        <SimpleShowLayout>
          <AccountsShowGrid banks={bankDataMap} />
        </SimpleShowLayout>
      </Show>
      <Transactions matterId={matterId} accountId={accountId} clientId={clientId} bankDataMap={bankDataMap} />
    </>
  );
};

const AccountsShowGrid = (props) => {
  const record = useRecordContext();

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ListItemComponentForShow title="Account Number" value={record["accountNumber"]} />
              <ListItemComponentForShow title="Bank Name" value={props.banks.get(record.bankId)} />
              <ListItemComponentForShow title="Sort Code" value={record["sortCode"]} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItemComponentForShow title="Date From" value={record["dateFrom"]} />
              <ListItemComponentForShow title="Date To" value={record["dateTo"]} />
              <ListItemComponentForShow title="Last Modified" value={record["lastModified"]} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ListItemComponentForShow title="Txn Count" value={record["txnCount"]} noDivider={true} />
            </Grid>
          </Grid>
        </List>
      </Grid>
    </Grid>
  );
};

const ListItemComponentForShow = (props) => {
  return (
    <>
      <ListItem sx={padding2.y}>
        <Grid container>
          <Grid item xs={12} md={5} sx={textColor.gray}>{props.title}</Grid>
          <Grid item xs={12} md={7}>{props.value}</Grid>
        </Grid>
      </ListItem>
      {!props.noDivider && <Divider light />}
    </>
  );
};
