import * as React from 'react';
import Alert from '@mui/material/Alert';
import { Grid } from '@mui/material';
import { isEmpty } from 'ra-core';
import ReactHtmlParser from 'react-html-parser'
import statusMessageContext from '../../Context';
import { alert, alertMessage } from '../Styles';

export const StatusMessageAlerts = (props) => {  

  const { statusMessage } = React.useContext(statusMessageContext);    

  return (    
    !isEmpty(statusMessage) && statusMessage.map((statusMessage) => {
      return (
      <Grid sx={alertMessage}>      
        <Alert 
          sx={alert}
          severity={statusMessage.type}>
          {ReactHtmlParser(statusMessage.message)}
        </Alert>
      </Grid>
      );
    })        
  );
}
