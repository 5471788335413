import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import {
  Button,
  SelectInput,
  useNotify,
  useDataProvider,
  Loading,
  AutocompleteArrayInput,
  SimpleForm
} from "react-admin";
import CustomAccordian from "../../utils/CustomAccordian";
import CustomDialog from "../../utils/CustomDialog";
import "../../styles/auto-complete.css";
import {
  ReportTypeChoices,
  REPORT_TYPES,
  REPORT_TYPES_DISPLAY_NAMES,
  EVENT_REASON
} from "../Constants";
import isEmpty from "lodash/isEmpty"
import isEqual from "lodash/isEqual"
import { extractDefaultValues, generateUnarchivedMappedSurveys, isMappedSurveyEmpty } from "../../mappers/Survey";
import { useFormContext } from 'react-hook-form';
import SimpleFormContainerComponent from "../common/SimpleFormContainerComponent";
import {
  width,
  padding0,
  padding2,
  textColor,
  accordion,
  iconAction,
  accordionActionButton,
  autoCompleteField,
} from '../Styles'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

const SurveyAccordion = (props) => {
  const initialMappedSurvey = {};
  Object.values(REPORT_TYPES).forEach((report)=>{
    initialMappedSurvey[report] = [];
  });

  const [loading, setLoading] = React.useState(true);
  const [mappedSurveys, setMappedSurveys] = React.useState(initialMappedSurvey);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    fetchMappedSurveys(props.clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.clientId]);

  const fetchMappedSurveys = (clientId) => {
    if(!isEmpty(clientId)) {
      dataProvider
        .getMappedSurveys({ id: clientId })
        .then(({data}) => {
          setMappedSurveys(data);
          setLoading(false);
        }).catch((error) => {
          notify('Something went wrong while fetching mapped surveys.', { type: "warning", autoHideDuration: 1000 });
          setLoading(false);
      })
    }
  }

  return(
    <>
      <ListItem sx={padding2.y} key="surveyList">
        <Grid container>
          <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: props.isShow ? "10px" : "18px" } }}>Surveys</Grid>
          <Grid item xs={12} md={props.isShow ? 6 : 5} lg={6}>
            <CustomAccordian
              title={"Client Surveys"}
              isShow={props.isShow}
            >
              <SurveyList
                loading={loading}
                mappedSurveys={mappedSurveys}
                setMappedSurveys={setMappedSurveys}
                isShow={props.isShow}
              />
            </CustomAccordian>
          </Grid>
        </Grid>
      </ListItem>
      <ListItem sx={{...padding0.bottom, ...padding2.bottom}} key="surveyDialog">
        {!props.isShow &&
          <SurveyDialog
            clientId={props.clientId}
            mappedSurveys={mappedSurveys}
            setMappedSurveys={setMappedSurveys}
            isEdit={props.isEdit}
          />
        }
      </ListItem>
    </>
  );
}

const SurveyList = (props) => {
  const [reportType, setReportType] = React.useState(REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const deleteSurveyMapping = (id) => {
    dataProvider
      .unMapSurvey({ id : id })
      .then(({ data }) => {
        const newMappedSurveysList = props.mappedSurveys[reportType].filter(s => s.id !== id);
        const updatedSurvey = {};
        updatedSurvey[reportType] = newMappedSurveysList;
        notify('Survey was removed successfully for this client', { type: 'info', autoHideDuration: 1000 });
        props.setMappedSurveys( mappedSurveys => ({
          ...mappedSurveys,
          ...updatedSurvey
        }));
      })
      .catch((error) => {
        notify('Something went wrong while removing the survey', { type: 'warning', autoHideDuration: 1000 });
      });
  }

  return(
    <>
      {props.loading && <Loading />}
      {!props.loading && (
        <List>
          <ListItem key="surveyContainer">
            <Grid xs={12}>
              <SimpleForm
                toolbar={null}
                component={SimpleFormContainerComponent}
              >
                <SelectInput
                  source="reportType"
                  choices={ReportTypeChoices}
                  defaultValue={REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS}
                  variant="outlined"
                  size="small"
                  sx={width.fluid.w100}
                  label="Choose Report Type"
                  onChange={(e) => {setReportType(e.target.value)}}
                  isRequired
                />
              </SimpleForm>
            </Grid>
          </ListItem>

          {props.mappedSurveys[reportType].map((survey) => {
            return(
              <ListItem sx={accordion.listItem} key={survey.id}>
                <ListItemText primary={survey.name} disableTypography />
                {!props.isShow &&
                  <Button
                    sx={iconAction}
                    size="medium"
                    onClick = {() => { deleteSurveyMapping(survey.id); }}
                  >
                    <DeleteOutlinedIcon/>
                  </Button>
                }
              </ListItem>
            )
          })}
        </List>
      )}
    </>
  );
}

export const SurveyDialog = (props) => {
  const initialSelectedSurveys = {};
  Object.values(REPORT_TYPES).forEach((report)=>{
      initialSelectedSurveys[report] = [];
  });

  const [open, setOpen] = React.useState(false);
  const [allSurvey, setAllSurveys] = React.useState([]);
  const [reportType, setReportType] = React.useState(REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS);
  const [selectedSurveys, setSelectedSurveys] = React.useState(initialSelectedSurveys);
  const [unarchiveMappedSurveys, setUnarchiveMappedSurveys] = React.useState(initialSelectedSurveys);
  const form = useFormContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
      fetchSurveys();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  React.useEffect(() => {
      if(props.isEdit && !isEmpty(allSurvey) && isMappedSurveyEmpty(props.mappedSurveys)){
          setUnarchiveMappedSurveys(generateUnarchivedMappedSurveys(props.mappedSurveys, allSurvey));
      }
  },[allSurvey, props.mappedSurveys, props.isEdit]);

  const handleOpen = () => {
      setOpen(true);
      // eslint-disable-next-line no-lone-blocks
      { props.isEdit &&
      setSelectedSurveys((prev) => ({
          ...prev,
          ...unarchiveMappedSurveys
      }));
      }                   //When the dialog closes, the input component resets to its default value or empty, so we need to get back to the previous state when Dialog closed.
  }

  const handleDialogClose = (event, reason) => {
      if(reason !== EVENT_REASON.BACKDROP_CLICK){
          setOpen(false)
      }
  };
  const fetchSurveys = () => {
      dataProvider
        .getAllSurveys()
        .then(({ data }) => {
            setAllSurveys(data.filter(element => element.surveyArchiveStatus === false));
        })
        .catch((error) => {
            notify('Something went wrong while fetching the surveys', { type: 'warning', autoHideDuration: 1000 });
        });
  }

  const handleSubmitForEdit = () => {
      if( !isEqual(unarchiveMappedSurveys[reportType], selectedSurveys[reportType]) ){
          dataProvider
            .updateAssignmentOfSurveys({
                clientId: props.clientId,
                reportType: reportType,
                mappedSurveys: unarchiveMappedSurveys[reportType],
                postSelectedSurveys: selectedSurveys[reportType],
                allSurvey : allSurvey,
            })
            .then(({ data }) => {
                props.setMappedSurveys(data);
                notify('Surveys mapped.', { type: 'success', autoHideDuration: 1000 });
            })
            .catch((error) => {
                notify('Something went wrong while updating the surveys of client', { type: 'warning', autoHideDuration: 1000 });
            })
      }
      setOpen(false);
  }

  const handleSubmitForCreate = () => {
      form.setValue('surveys', selectedSurveys[reportType]);
      form.setValue('allSurvey', allSurvey);
      setOpen(false);
  }

  const getSurveyById = (surveyId) => {
    const surveyObject = allSurvey.find(survey => surveyId === survey.id)
    return surveyObject ? surveyObject.name : null
  }

  return(
    <>
      <Grid container>
        <Grid item xs={0} md={props.isEdit ? 4 : 0}></Grid>
        <Grid item xs={12} md={props.isEdit ? 5 : 8} lg={props.isEdit ? 6 : 8}>
          <SelectInput
            source="surveyReportType"
            choices={ReportTypeChoices}
            defaultValue={REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS}
            label="Choose an option"
            helperText="Select report type before selecting surveys"
            variant="outlined"
            size="small"
            sx={width.fluid.w100}
            onChange={(e) => {
              setReportType(e.target.value);
            }}
            isRequired
          />
          <List sx={padding0.y} display={props.isEdit ? "none" : "block"}>
            {form.getValues()?.surveys && form.getValues()?.surveys.map((survey) => {
              return(
                <ListItem sx={{...accordion.listItem, "&:first-of-type": { borderTop: 0 } }} key={survey}>
                  <ListItemText primary={getSurveyById(survey)} disableTypography />
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={props.isEdit ? 3 : 4} lg={props.isEdit ? 2 : 4}>
          <Button
            sx={{...accordionActionButton, marginTop: "12px !important" }}
            label={"Add Surveys"}
            size="small"
            onClick={handleOpen}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Grid>
      </Grid>
      <CustomDialog
        openDialog={open}
        closeDialog={handleDialogClose}
        title={"Map Surveys for " + REPORT_TYPES_DISPLAY_NAMES[reportType]}
        content = {
          <SimpleForm
            sx={autoCompleteField}
            toolbar={null}
          >
            <AutocompleteArrayInput
              label="Surveys"
              source="clientSurveys"
              choices={allSurvey}
              defaultValue={props.isEdit ? extractDefaultValues(unarchiveMappedSurveys[reportType]) : selectedSurveys[reportType]}
              variant="outlined"
              fullWidth={true}
              onChange={(e) => {
                const updatedSurvey = {};
                updatedSurvey[reportType] = e;
                setSelectedSurveys((prev) => ({
                  ...prev,
                  ...updatedSurvey
                }))
              }}
            />
          </SimpleForm>
        }
        handleSubmit={(props.isEdit) ? handleSubmitForEdit : handleSubmitForCreate}
      />
    </>
  );
}

export default SurveyAccordion;