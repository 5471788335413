import { Button, Divider, Grid, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
	Show,
	SimpleShowLayout,
	useDataProvider,
	Loading,
	Error,
	useRedirect,
	useGetRecordId,
	useRecordContext,
	useNotify,
	useRefresh
} from 'react-admin';
import { baseDarkButtonStyle, contentCenter, margin2, padding0, padding2, textColor } from "../Styles";
import ListActions from "../common/ListActions";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH, SUB_RESOURCES } from "../Constants";
import { MatterAccountsGrid } from "./MatterAccountsGrid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isAdminUser, showFileRoute } from "../DataProvider";

export const MatterShow = () => {
	const isAdmin = isAdminUser();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const { id } = useParams();
	const [accountsList, setAccountList] = useState([]);
	const [accountRefresh, setAccountRefresh] = useState(false);

	const refreshPage = () => {
		setAccountRefresh(!accountRefresh);
		refresh();
	};

	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataProvider]);

	useEffect(() => {
		fetchAccounts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataProvider, accountRefresh]);

	const fetchUsers = () => {
		if (isAdmin) {
			dataProvider
				.getMappedUsersbyClientId({ clientId: id.split('/')[1] })
				.then(({ data }) => {
					setData(data);
				})
				.catch((error) => {
					setError(error);
				});
		}
		else {
			dataProvider
				.getMappedUsersSummary(`client/mapped-users`)
				.then(({ data }) => {
					setData(data);
				})
				.catch((error) => {
					setError(error);
				});
		}
	};

	const fetchAccounts = () => {
		dataProvider.getManyReference(SUB_RESOURCES.ACCOUNTS, {id : id})
		.then((data) => {
			setAccountList(data);
			setLoading(false);
		}).catch((error) => {
			notify('Something went wrong while fetching matter accounts.', { type: "warning", autoHideDuration: 1000 });
			setError(error);
		});
	};

	if (error) return <Error />;

	return (
		<>
		<Show
			actions={
				<ListActions
					title={ActionTitles.MATTER_DETAILS}
					showEditButton={true}
					showDeleteButton={true}
					basePath={RESOURCES_BASEPATH.MATTERS}
					resource={RESOURCES.MATTERS}
					recordName={ActionTitles.MATTER}
				/>
			}
			renderButtonAndSearch={false}
			sx={contentCenter}
		>
			<SimpleShowLayout>
				<MatterShowGrid usersMap={data} />
			</SimpleShowLayout>
		</Show>
		{ loading 
			? <Loading /> 
			: accountsList && accountsList.total > 0 && (
				<>
					<Typography variant="h6" sx={margin2.top}>
						Bank Accounts
					</Typography>
					<Show
						actions={null}
						renderButtonAndSearch={false}
						toolbar={null}
					>
						<MatterAccountsGrid matterId={id} usersMap={data} accountsList={accountsList} refresh={refreshPage} />
					</Show>
				</>
			)
		}
		</>
	);
};

export const MatterShowGrid = (props) => {
	const redirect = useRedirect();
	const recordId = useGetRecordId();
	const record = useRecordContext();
	const enableFileRoute = showFileRoute();

	return (
		<Grid container flexGrow={1}>
			<Grid item xs={12}>
				<List sx={padding0.y}>
					<MatterListItem title="Name" value={record["name"]} />
					<MatterListItem title="Notes" value={record["notes"]} />
					<MatterListItem title="Last Modified" value={record["lastModified"]} />
					<MatterListItem title="Last Modified By" value={
						props.usersMap && props.usersMap.get(record.lastModifiedBy) !== null ?
							props.usersMap.get(record.lastModifiedBy) : ' '
					}
						noDivider={!enableFileRoute} />
					{enableFileRoute &&
						<ListItem sx={padding2.y}>
							<Grid container alignItems={"center"}>
								<Grid item xs={12} md={4} sx={textColor.gray}>Add Bank Accounts</Grid>
								<Grid item xs={12} md={4}>
									<Button
										sx={{ ...baseDarkButtonStyle(), margin: "0 !important" }}
										size="small"
										startIcon={<AddCircleOutlineIcon />}
										onClick={() => redirect(RESOURCES_BASEPATH.MATTERS + "/" + recordId + "/file")}
									>
										Upload Statements / Files
									</Button>
								</Grid>
							</Grid>
						</ListItem>}
				</List>
			</Grid>
		</Grid>
	);
};

const MatterListItem = (props) => {
	return (
		<>
			<ListItem sx={padding2.y}>
				<Grid container alignItems={"center"}>
					<Grid item xs={12} md={4} sx={textColor.gray}>{props.title}</Grid>
					<Grid item xs={12} md={8}>{props.value}</Grid>
				</Grid>
			</ListItem>
			{!props.noDivider && <Divider light />}
		</>
	);
};