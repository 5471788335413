import React from "react";
import {
  SCHEDULE_ACTIONS,
  SCHEDULE_ACTIONS_DISPLAY,
  SCHEDULE_STATUS,
} from "../Constants";
import { useRefresh } from "react-admin";
import Button from "@mui/material/Button";
import { httpClient, isAdminUser } from "../DataProvider";
import { baseButtonStyle } from '../Styles'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const SchedulesActions = (props) => {
  const { scheduleAction } = props;
  const refresh = useRefresh();
  const performActions = async () => {
    if (scheduleAction === SCHEDULE_ACTIONS.STOP_SCHEDULE) {
      const stopScheduleResponse = `${apiUrl}/api/report-request-schedule/${props.reportRequestScheduleId}`;
      await httpClient(stopScheduleResponse, { method: "DELETE" }).then(
        ({ json }) => {
          const { active } = json.data;
          if (active === false) {
            refresh();
          }
        }
      );
    } else if (scheduleAction === SCHEDULE_ACTIONS.TOGGLE_REMINDERS) {
      if (props.reminderStatus != null && props.reportRequestScheduleId) {
        let toggleEndPoint = `${apiUrl}/api/report-request-schedule/${props.reportRequestScheduleId}/scheduled-reminder`;
        toggleEndPoint = props.reminderStatus
          ? toggleEndPoint + "/disable"
          : toggleEndPoint + "/enable";
        await httpClient(toggleEndPoint, { method: "PATCH" }).then(
          ({ json }) => {
            const { scheduleReminders } = json.data;
            if (scheduleReminders !== props.reminderStatus) {
              refresh();
            }
          }
        );
      }
    } else if (scheduleAction === SCHEDULE_ACTIONS.VIEW_REPORTS) {
      let filterValue = `{"searchValue":"${props.reportRequestScheduleId}","reportRequestScheduleId":"${props.reportRequestScheduleId}"}`;
      if(isAdminUser() && props.clientId){
          filterValue = `{"searchValue":"${props.reportRequestScheduleId}","clientId":"${props.clientId}","reportRequestScheduleId":"${props.reportRequestScheduleId}"}`;
      }
      window.location =
        window.location.protocol +
        "//" +
        window.location.host +
        `/#/reports?filter=${filterValue}&order=DESC&page=1&perPage=10&sort=lastModified`;
    } else if (scheduleAction === SCHEDULE_ACTIONS.EXIT) {
      window.history.back();
    }
  };

  return (
    <ScheduleActionButtons
      buttontitle={props.scheduleAction}
      onClick={performActions}
      disabled={props.isActive === SCHEDULE_STATUS.INACTIVE ? true : false}
    />
  );
}

const ScheduleActionButtons = (props) => {
  const exitStyle = {
    ...baseButtonStyle("grey", "white", "#eaeaea"),

    border: "2px solid #b2afaf",
    "&:hover": {
      border: " 2px solid #9B9999",
    },
  };

  return (
    <>
      {
        props.buttontitle === SCHEDULE_ACTIONS.EXIT ?
          <Button
            variant="outlined"
            size="medium"
            onClick={props.onClick}
            sx={exitStyle}
            disabled={props.disabled}
          >
            {SCHEDULE_ACTIONS_DISPLAY[props.buttontitle]}
          </Button> :
          <Button
            variant="contained"
            size="medium"
            onClick={props.onClick}
            sx={
              props.buttontitle === SCHEDULE_ACTIONS.STOP_SCHEDULE
                ? baseButtonStyle("white", "#2a2f3b", "#181b21")
                : baseButtonStyle()
            }
            disabled={props.disabled}
          >
            {SCHEDULE_ACTIONS_DISPLAY[props.buttontitle]}
          </Button>
      }

    </>
  );
};
