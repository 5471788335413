import {
  generateRandomPassword,
  getParsedDate,
} from '../utils/util';
import isEmpty from 'lodash/isEmpty'

export const parseUserData = (data) => {
  const parsedRequest = {
    id: data.userId,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    emailReportNotifications:
      data.userNotificationDto != null && data.userNotificationDto.emailReportNotifications
        ? data.userNotificationDto.emailReportNotifications
        : false,
    emailStatementConversionNotifications:
    data.userNotificationDto != null && data.userNotificationDto.emailStatementConversionNotifications
      ? data.userNotificationDto.emailStatementConversionNotifications
      : false,
    multiFAConfigured: data.multiFAConfigured,
    enforceForMultiFAConfiguration: data?.enforceForMultiFAConfiguration,
    created: getParsedDate(data.created),
    lastModified: getParsedDate(data.lastModified),
  };
  return parsedRequest;
};

export const parseDataForUserCreate = (data) => {
  data.fullName = data.firstName + ' ' + data.lastName;
  data.email = data.email ? data.email.toLowerCase().trim() : data.email;
  data.username = data.email;
  data.password = generateRandomPassword();
  data.userNotificationDto = {
    emailReportNotifications: data['emailReportNotifications'],
    emailStatementConversionNotifications: data['emailStatementConversionNotifications'],
    secondaryEmail: data['emailReportNotifications']
      ? isEmpty(data['secondaryEmail']) ? null : data['secondaryEmail'].toLowerCase().trim()
      : null,
  };
  delete data['emailReportNotifications'];
  delete data['emailStatementConversionNotifications'];
  delete data['secondaryEmail'];
  return JSON.stringify(data);
};

export const parseUserPasswordForUpdate = (data) => {
  const requestData = {
    currentPassword: data.currentPassword,
    newPassword: data.newPassword,
  };
  return JSON.stringify(requestData);
};

export function parseMappedUsersChoices(data) {
  if(!isEmpty(data)){
    
    data.forEach(element => {      
      element["id"] = element["userId"];
      element["name"] = element["firstName"] + " " + element["lastName"];      
    });

  }
  return data;
}
