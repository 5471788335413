import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import React from 'react';
import { canFieldBeHidden, showInsights } from '../../utils/CreateReportUtils';
import { useFormContext, useWatch } from 'react-hook-form';
import { BooleanInput, FormDataConsumer } from 'react-admin';
import {padding2,textColor} from '../Styles'

const InsightsDynamicField = (props) => {
  const values = useWatch();
  const insightsField = props.insightsField;
  const form = useFormContext();

  React.useEffect(() => {
    showInsights(values.reportType)   // cleaning form on case of template and report change
      ? form.setValue('insights', insightsField?.defaultValue) 
      : form.setValue('insights', false);          
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values.reportType, props.selectedTemplateId, insightsField?.defaultValue]);

  return(
    showInsights(values.reportType) && (
      <React.Fragment>
        <Grid sx={{
          ...(insightsField &&
          canFieldBeHidden(
            insightsField?.isHidden,
            insightsField?.required,
            insightsField?.defaultValue
          ) ? { display: 'none' } : {})
        }}>
          <ListItem sx={padding2.y}>
            <Grid container alignItems="center">
              <Grid xs={12} md={4} sx={textColor.gray}>
                {insightsField ? insightsField.displayName : 'Income Insights'}
              </Grid>
              <Grid xs={12} md={8}>
                <BooleanInput
                  defaultValue={insightsField?.defaultValue}
                  source="insights"
                  label={false}
                  sx={{marginBottom: "-15px"}}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
        </Grid>
        <InsightsMandatory incomeMandatoryField={props.incomeMandatoryField}/>
      </React.Fragment>
    )
  );
};

const InsightsMandatory = (props) => {
  const values = useWatch();
  const form = useFormContext();

  const incomeMandatoryField = {
    defaultValue: false,
    isHidden: true,
    displayName: "Income Mandatory",
  };

  React.useEffect(() => {
    showInsights(values.reportType)   // cleaning form on case of template and report change
      ? form.setValue('incomeMandatory', incomeMandatoryField?.defaultValue) 
      : form.setValue('incomeMandatory', false);          
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values.reportType, props.selectedTemplateId, incomeMandatoryField?.defaultValue]);

  return(
    <FormDataConsumer>
      {({formData, ...rest}) => formData.insights && 
        <>
          <Grid sx={{
            ...(incomeMandatoryField &&
            canFieldBeHidden(
              incomeMandatoryField?.isHidden,
              incomeMandatoryField?.required,
              incomeMandatoryField?.defaultValue
            ) ? { display: 'none' } : {})
          }}>
            <ListItem sx={padding2.y}>
              <Grid container alignItems="center">
                <Grid xs={12} md={4} sx={textColor.gray}>
                  { incomeMandatoryField ? incomeMandatoryField.displayName : 'Income Mandatory' }
                </Grid>
                <Grid xs={12} md={8}>
                  <BooleanInput
                    defaultValue={ incomeMandatoryField ? incomeMandatoryField.defaultValue : false }
                    source="incomeMandatory"
                    label={false}
                    sx={{marginBottom: "-15px"}}
                  />
                </Grid>
              </Grid>
            </ListItem>
            <Divider light />
          </Grid>
        </>
      }
    </FormDataConsumer>
  );
}

export default InsightsDynamicField;