import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {
  TextInput,
  Create,
  SimpleForm,
  maxLength,
  required,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import { ActionTitles, ENABLED_FEATURES_ENUM, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import ListActions from "../common/ListActions";
import CustomToolbar from "../common/FormToolbar";
import React from "react";
import { emailWithSpacesValidation, sameEmailDomainCheck, secondaryEmailValidation, secondaryEmailWithSpacesValidation } from "../../utils/util";
import { isAdminUser, isClientAdminUser, isFeatureEnabled } from "../DataProvider";
import { contentCenter, padding0, padding2, simpleForm, textColor, width } from '../Styles'

const validate = required("This is a mandatory field.");
const validateEmail = [validate, emailWithSpacesValidation, maxLength(254)];
const validateEmailforClientAdmin = [...validateEmail, sameEmailDomainCheck];
const validateSecondaryEmail = [secondaryEmailValidation, secondaryEmailWithSpacesValidation, maxLength(254)];

export const UserCreate = () => {
  return (
    <Create
      sx={contentCenter}
      actions={<ListActions title={ActionTitles.CREATE_USER} />}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
            buttontitle2={ActionTitles.CREATE}
            basePath={RESOURCES_BASEPATH.USERS}
            resource={RESOURCES.USERS}
          />
        }
        mode="onBlur"
        reValidateMode="onBlur"
        sx={simpleForm}
      >
        <Grid container flexGrow={1}>
          <Grid xs={12}>
            <List sx={padding0.y}>
              <ListItemComponentForUser title="First Name" name="firstName" />
              <Divider light />
              <ListItemComponentForUser title="Last Name" name="lastName" />
              <Divider light />
              <ListItemComponentForUser title="Email" name="email" />
              <Divider light />
              <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                  <Grid xs={12} md={4} sx={textColor.gray}>Email Report Notification</Grid>
                  <Grid xs={12} md={8}>
                    <BooleanInput
                      label={false}
                      source="emailReportNotifications"
                      defaultValue={true}
                      sx={{ marginBottom: "-15px" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              {(isAdminUser() || isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT)) &&
                <>
                  <Divider light />
                  <ListItem sx={padding2.y}>
                    <Grid container alignItems="center">
                      <Grid xs={12} md={4} sx={textColor.gray}>Email Statement Conversion Notification</Grid>
                      <Grid xs={12} md={8}>
                        <BooleanInput
                          label={false}
                          source="emailStatementConversionNotifications"
                          defaultValue={true}
                          sx={{ marginBottom: "-15px" }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              }
              <FormDataConsumer>
                {({ formData, ...rest }) => (formData.emailReportNotifications || formData.emailStatementConversionNotifications) &&
                  <>
                    <Divider light />
                    <ListItem sx={padding2.y}>
                      <Grid container alignItems="center">
                        <Grid item xs={12} md={4} sx={textColor.gray}>Secondary Email</Grid>
                        <Grid item xs={12} md={6} lg={5}>
                          <TextInput
                            source="secondaryEmail"
                            label="Secondary Email"
                            variant="outlined"
                            size="small"
                            validate={validateSecondaryEmail}
                            {...rest}
                            sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </>
                }
              </FormDataConsumer>
              <Divider light />
              <ListItem sx={padding2.y}>
                <Grid container alignItems="center">
                  <Grid xs={12} md={4} sx={textColor.gray}>Enforce 2FA</Grid>
                  <Grid xs={12} md={8}>
                    <BooleanInput
                      label={false}
                      source="enforceForMultiFAConfiguration"
                      sx={{ marginBottom: "-15px" }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const ListItemComponentForUser = (props) => {
  const isClientAdmin = isClientAdminUser();

  return (
    <ListItem sx={padding2.y}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={4} sx={textColor.gray}>
          {props.title}
          <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <TextInput
            source={props.name}
            label={props.title}
            variant="outlined"
            size="small"
            validate={
              props.name === "email" || props.name === "secondaryEmail"
                ? (isClientAdmin ? validateEmailforClientAdmin : validateEmail)
                : validate}
            sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};