import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import { canFieldBeHidden } from "../../utils/CreateReportUtils";
import React from "react";
import {cursor, padding2, textColor} from '../Styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export const CommonInputForCreateReportGrid = (props) => {
  
  const { field } = props;

  return(
    <Grid
      sx={ field && canFieldBeHidden(field?.isHidden, field?.required, field?.defaultValue) ? { display: "none" } : {}}
    >
      <Divider light />
      <ListItem sx={padding2.y}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            <Grid container alignItems="center">
              <Grid item>
                {field?.displayName ? field.displayName : props.defaultDisplayName }
                {field?.required && <span style={{...textColor.red, marginLeft: '3px'}}>*</span>}
              </Grid>
              {field?.info && <Grid item>
                <Tooltip title={field.info} placement="top">
                  <InfoOutlinedIcon sx={{...cursor.pointer, marginLeft: '3px', float: 'inline-end'}} fontSize="small"/>
                </Tooltip>
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            {props.input}
          </Grid>
        </Grid>
      </ListItem>
    </Grid>
  );
}